import React from 'react'

export const setScrollPosition = (
  dotContainerRef: React.RefObject<HTMLDivElement>
) => {
  const containerElem = dotContainerRef.current as HTMLElement | undefined
  const collection = document.getElementsByClassName(
    'carousel-control-active-item'
  )
  const activeElement = collection[0] as HTMLElement | undefined
  if (containerElem && activeElement) {
    containerElem.scrollTo({
      left: activeElement.offsetLeft,
      behavior: 'smooth',
    })
  }
}

export default {}
