import React from 'react'
import { connect, useDispatch } from 'react-redux'
import { Link } from 'react-router-dom'

import { setFloorPlateGallery } from '@store/actionSlices/floorPlateGallery'
import { setStages } from '@store/actionSlices/interactivePlan'
import {
  BucketSource,
  InteractivePlanType,
  ProjectIdentity,
  RootStateTypeExtra,
  SessionMap,
} from '@store/types'

import DataHandler from '@components/data-handler'
import IdleTimeHandler from '@components/idle-time-handler'
import { useIdleTimeHandler } from '@components/idle-time-handler/utils'
import {
  PannellumDataInterface,
  PanoramaType,
} from '@components/showcase-pannellum/types'

import {
  FloorPlateGalleryData,
  selectFromResult as selectFromFloorPlateGalleryResult,
  useGetFloorPlateGalleryQuery,
} from '@api/floor-plate-gallery'
import {
  MappingStageCollection,
  selectFromResult as selectStageFromResult,
  useGetInteractivePlanQuery,
} from '@api/interactive-plan'
import {
  LotInterface,
  PrecinctListItemInterface,
} from '@api/types/house-and-land-type'

import { getTabKeyByIndexBy } from '@utilities/floor-tab'
import VideoPlayerFirebaseService from '@utilities/video-player-firebase-service'

import { CarouselProvider } from '@adUtilities/components/carousel-provider'
import VideoPlayer from '@adUtilities/components/video-player'
import { VideoPlayerRef } from '@adUtilities/components/video-player/video-player'
import { CarouselControlType } from '@adUtilities/constants/carousel'
import { FileType } from '@adUtilities/constants/common'
import {
  BufferState,
  PlayState,
  SeekState,
  SyncState,
} from '@adUtilities/constants/player'

import StageCanvas from './stage-canvas'
import StageGalleryTab from './stage-gallery-tab'
import StageSkeleton from './stage-skeleton'

interface ComponentProps {
  originalLots: Array<LotInterface>
  filteredLots: Array<LotInterface>
  getVideoPlayerState: (arg: boolean) => void
  projectIdentity: ProjectIdentity
  session: SessionMap | undefined
  floorPlateGallery: Array<FloorPlateGalleryData>
  activePrecinctId: string
  activeStageId: string
  precinctList: Array<PrecinctListItemInterface>
  stagePlan: MappingStageCollection
  panoramic: Array<PannellumDataInterface>
  fullScreenToggle: boolean
  setFullScreenToggle: (arg: boolean) => void
  appIdleControls: {
    duration: number
    active: boolean
  }
  effectType: string
  autoPlayIntervalInSeconds: number
}

function StageView({
  originalLots,
  filteredLots,
  getVideoPlayerState,
  projectIdentity,
  session,
  floorPlateGallery,
  activePrecinctId,
  activeStageId,
  precinctList,
  stagePlan,
  panoramic,
  fullScreenToggle,
  setFullScreenToggle,
  appIdleControls,
  effectType,
  autoPlayIntervalInSeconds,
}: ComponentProps) {
  const videoPlayerRef = React.useRef<VideoPlayerRef>(null)
  const connectedRef = React.useRef(false)

  const videoPlayerFirebaseService = VideoPlayerFirebaseService({
    galleryName: 'floorGallery',
    projectIdentity,
  })

  const dispatch = useDispatch()
  const [isConnected, setIsConnected] = React.useState(false)

  const isAppIdle = useIdleTimeHandler({
    isConnected,
    timeoutInSeconds: appIdleControls.duration,
    isIdleActive: appIdleControls.active,
  })

  const [activeTab, setActiveTab] = React.useState('floor')
  const [currentSlideIndex, setCurrentSlideIndex] = React.useState(0)
  const [videoPlayerState, setVideoPlayerState] = React.useState(false)
  const [videoSyncKey, setVideoSyncKey] = React.useState<number | null>(null)

  const stagePlanMapKey = React.useMemo(
    () =>
      precinctList.length > 1
        ? `${activePrecinctId}-${activeStageId}`
        : activeStageId,
    [precinctList, activePrecinctId, activeStageId]
  )

  const hasStageData: boolean = React.useMemo(
    () => stagePlan[stagePlanMapKey] !== undefined,
    [stagePlan, stagePlanMapKey]
  )

  const stagePayload = useGetInteractivePlanQuery(
    {
      projectName: projectIdentity.projectName,
      type: InteractivePlanType.Stages,
      slug: stagePlanMapKey,
    },
    { selectFromResult: selectStageFromResult, skip: hasStageData }
  )

  const floorPlateGalleryPayload = useGetFloorPlateGalleryQuery(
    { projectName: projectIdentity.projectName },
    { selectFromResult: selectFromFloorPlateGalleryResult }
  )

  const hasPanoramicData: boolean = React.useMemo(() => {
    const foundItem = panoramic.find(
      (item: PannellumDataInterface) =>
        item.type === PanoramaType.Stage &&
        (item.panoramaGroup || '')?.toString() === activeStageId?.toString()
    )
    return foundItem !== undefined
  }, [panoramic, activeStageId])

  const slides = React.useMemo(() => {
    if (floorPlateGallery.length < 1) {
      return []
    }
    let foundData = null

    if (activePrecinctId && activeStageId) {
      foundData = floorPlateGallery.find(
        (value) =>
          (value.precinct || '').toString() === activePrecinctId &&
          (value.stage || '').toString() === activeStageId
      )
    } else {
      foundData = floorPlateGallery.find(
        (value) => (value.stage || '').toString() === activeStageId
      )
    }

    if (foundData === null) {
      return []
    }

    const floorAssets = foundData?.assets || []

    if (floorAssets.length === 0) {
      return []
    }

    return floorAssets.map((item) => ({
      id: item.id,
      src: item.imageSrc || '',
      videoSource: item.videoSrc,
      thumbnail: item.imageSrc,
      fileType: item.type as FileType,
      bucketType: BucketSource.Legacy,
      noSpliceUrl: false,
    }))
  }, [activeStageId, activePrecinctId, floorPlateGallery])

  const hasVideoItems: boolean = React.useMemo(
    () => slides.filter((item) => item.fileType === FileType.Video).length > 0,
    [slides]
  )

  const videoSource: string = React.useMemo(() => {
    const item = slides.find(
      (slide, index) =>
        slide.fileType === FileType.Video && index === currentSlideIndex
    )

    return item ? item.videoSource || '' : ''
  }, [slides, currentSlideIndex])

  const handleOnPlay = React.useCallback(
    async (args: PlayState) => {
      if (connectedRef.current) {
        await videoPlayerFirebaseService.updateStateOnPlay(args)
      }
    },
    [videoPlayerFirebaseService]
  )

  const handleOnBuffer = React.useCallback(
    async (args: BufferState) => {
      if (connectedRef.current) {
        await videoPlayerFirebaseService.updateStateOnBuffer(args)
      }
    },
    [videoPlayerFirebaseService]
  )

  const handleOnSeek = React.useCallback(
    async (args: SeekState) => {
      if (connectedRef.current) {
        await videoPlayerFirebaseService.updateStateOnSeek(args)
      }
    },
    [videoPlayerFirebaseService]
  )

  const handleOnSync = React.useCallback(
    async (args: SyncState) => {
      if (connectedRef.current) {
        await videoPlayerFirebaseService.updateStateOnSyncRequest(args)
      }
    },
    [videoPlayerFirebaseService]
  )

  React.useEffect(() => {
    const { floorPlateGalleryData } = floorPlateGalleryPayload
    if (floorPlateGallery.length === 0 && floorPlateGalleryData.length > 0) {
      dispatch(setFloorPlateGallery(floorPlateGalleryData))
    }
  }, [floorPlateGalleryPayload])

  React.useEffect(() => {
    const { maps } = stagePayload
    if (Object.entries(maps?.stages || {}).length > 0 && !hasStageData) {
      dispatch(setStages(maps.stages))
    }
  }, [stagePayload])

  React.useEffect(() => {
    if (!session) {
      return
    }
    const {
      connected,
      floorGallery: {
        galleryControlV2: {
          activeSlideIndex: activeSlideIndexFirebase,
          activeTabIndex: activeTabIndexFirebase,
        },
        playerControlV2: {
          playerState: playerStateFirebase,
          loop: loopFirebase,
          muted: mutedFirebase,
          volume: volumeFirebase,
          seekToValueInSeconds: seekToValueInSecondsFirebase,
          videoSyncKey: videoSyncKeyFirebase,
        },
      },
    } = session
    connectedRef.current = connected
    setIsConnected(connected)
    setCurrentSlideIndex(activeSlideIndexFirebase)
    setActiveTab(getTabKeyByIndexBy(activeTabIndexFirebase || 0))
    videoPlayerRef.current?.updatePlayerState(playerStateFirebase)
    videoPlayerRef.current?.toggleLoop(loopFirebase)
    videoPlayerRef.current?.toggleMute(mutedFirebase)
    videoPlayerRef.current?.updateVolume(volumeFirebase / 10)
    if (seekToValueInSecondsFirebase !== null) {
      videoPlayerRef.current?.seekTo(seekToValueInSecondsFirebase)
    }
    if (videoSyncKeyFirebase !== null) {
      setVideoSyncKey(videoSyncKeyFirebase)
    }
  }, [session?.connected, session?.floorGallery])

  React.useEffect(
    () => setActiveTab('floor'),
    [activePrecinctId, activeStageId]
  )

  return (
    <DataHandler
      payload={{
        ...stagePayload,
        data: Object.entries(stagePlan[stagePlanMapKey] || {}),
      }}
      skeletonFrame={<StageSkeleton />}
    >
      <>
        {activeTab === 'gallery' && slides.length > 0 ? (
          <div className="absolute inset-0">
            <CarouselProvider
              gallerySettings={{ effectType, autoPlayIntervalInSeconds }}
              slides={slides}
              activeSlideIndex={currentSlideIndex}
              showControl={!isConnected}
              controlType={CarouselControlType.Thumbnail}
              getCurrentSlideIndex={setCurrentSlideIndex}
              mousewheel={!isConnected}
              isAppIdle={isAppIdle}
            />

            {hasVideoItems && (
              <div
                className={`absolute inset-0 ${
                  videoSource ? 'visible' : 'invisible'
                }`}
              >
                <div className="flex h-full w-full items-center justify-center">
                  <VideoPlayer
                    ref={videoPlayerRef}
                    sources={[videoSource]}
                    activeIndex={0}
                    getPlayerState={(state: boolean) => {
                      getVideoPlayerState(state)
                      setVideoPlayerState(state)
                    }}
                    showControls={!isConnected}
                    showLoopOption={false}
                    videoSyncKey={videoSyncKey}
                    updateStateOnPlay={handleOnPlay}
                    updateStateOnBuffer={handleOnBuffer}
                    updateStateOnSeek={handleOnSeek}
                    updateStateOnSyncRequest={handleOnSync}
                    isAppIdle={isAppIdle}
                  />
                </div>
              </div>
            )}
          </div>
        ) : (
          <StageCanvas
            stagePlan={stagePlan[stagePlanMapKey]}
            originalLots={originalLots}
            filteredLots={filteredLots}
            session={session}
            isFullWidth={isAppIdle}
            fullScreenToggle={fullScreenToggle}
            setFullScreenToggle={setFullScreenToggle}
          />
        )}

        {(slides.length > 0 || hasPanoramicData) &&
          !videoPlayerState &&
          !isConnected && (
            <IdleTimeHandler>
              <div className="absolute bottom-5 right-5 z-3">
                <div className="flex items-center">
                  {slides.length > 0 && (
                    <StageGalleryTab
                      activeTab={activeTab}
                      handleClick={(arg: string) => setActiveTab(arg)}
                    />
                  )}
                  {hasPanoramicData && (
                    <Link
                      to="panoramic"
                      className="text-md ml-2 rounded-md border border-mainColour bg-white px-8 py-2 font-medium tracking-widest text-mainColour"
                    >
                      Panoramic
                    </Link>
                  )}
                </div>
              </div>
            </IdleTimeHandler>
          )}
      </>
    </DataHandler>
  )
}

const mapStateToProps = ({
  projectIdentity,
  firestore: { session },
  projectConfig: {
    propertyGalleryTab,
    gallerySettings: { type, intervalInSeconds },
  },
  floorPlateGallery,
  houseAndLand: { activePrecinctId, activeStageId, precinctList },
  interactivePlan: { stages },
  panoramic,
}: RootStateTypeExtra) => ({
  projectIdentity,
  session,
  floorPlateGallery,
  panoramic,
  activePrecinctId,
  activeStageId,
  precinctList,
  stagePlan: stages,
  appIdleControls: propertyGalleryTab,
  effectType: type,
  autoPlayIntervalInSeconds: intervalInSeconds,
})

export default connect(mapStateToProps)(StageView)
