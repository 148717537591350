import React from 'react'
import { connect, useDispatch } from 'react-redux'

import { setUnitGallery } from '@store/actionSlices/unitGallery'
import {
  BucketSource,
  ProjectIdentity,
  RootStateTypeExtra,
  SessionMap,
  UnitGallery,
  UnitGalleryData,
} from '@store/types'

import DataHandler from '@components/data-handler'
import IdleTimeHandler, {
  useIdleTimeHandler,
} from '@components/idle-time-handler'
import { PannellumDataInterface } from '@components/showcase-pannellum/types'
import Skeleton from '@components/skeleton'

import {
  selectFromResult as selectFromExternalLinkResult,
  useGetExternalLinksByPropertyQuery,
} from '@api/external-links'
import {
  selectFromResult as selectFromUnitGalleryResult,
  useGetUnitGalleryQuery,
} from '@api/unit-gallery'

import VideoPlayerFirebaseService from '@utilities/video-player-firebase-service'

import { ArrowSvg } from '@svg/react'

import AssetHandler from '@adUtilities/asset-handler/asset-handler'
import { CarouselProvider } from '@adUtilities/components/carousel-provider'
import VideoPlayer from '@adUtilities/components/video-player'
import { VideoPlayerRef } from '@adUtilities/components/video-player/video-player'
import { CarouselControlType } from '@adUtilities/constants/carousel'
import { FileType } from '@adUtilities/constants/common'
import {
  BufferState,
  EndState,
  PlayState,
  SeekState,
  SyncState,
} from '@adUtilities/constants/player'
import { Unit, ViewLineImage } from '@adUtilities/types/apartment'

import TopControlBar from './top-control-bar'
import UnitInfoCard from './unit-info-card'

export interface UnitViewProps {
  unit: Unit
  projectIdentity: ProjectIdentity
  session: SessionMap | undefined
  panoramic: Array<PannellumDataInterface>
  propertyGalleryControlStyle: string
  onlyShowPropertyGalleries: boolean
  unitGallery: Array<UnitGalleryData>
  fullScreenToggle: boolean
  setFullScreenToggle: (arg: boolean) => void
  getVideoPlayerState: (arg: boolean) => void
  appIdleControls: {
    active: boolean
    duration: number
  }
  effectType: string
  autoPlayIntervalInSeconds: number
}

const NO_IMAGE_OBJECT: UnitGallery = {
  id: 'no-image',
  src: '',
  label: 'No Image',
  type: FileType.Image,
  noSpliceUrl: false,
}

function UnitView({
  unit,
  projectIdentity,
  session,
  panoramic,
  propertyGalleryControlStyle,
  onlyShowPropertyGalleries,
  unitGallery,
  fullScreenToggle,
  setFullScreenToggle,
  getVideoPlayerState,
  appIdleControls,
  effectType,
  autoPlayIntervalInSeconds,
}: UnitViewProps) {
  const { projectId, projectName } = projectIdentity
  const dispatch = useDispatch()

  const [unitInfoToggle, setUnitInfoToggle] = React.useState(false)
  const [hasPanoramicData, setPanoramicDataState] = React.useState(false)
  const [isConnected, setIsConnected] = React.useState(false)
  const [gallery, setGallery] = React.useState<Array<UnitGallery>>([])
  const [skipUnitGalleryApi, setSkipUnitGalleryApi] = React.useState(true)
  const [shouldShowSlideshowBtn, setShouldShowSlideShowBtn] =
    React.useState(false)
  const [showExternalLinks, setShowExternalLinks] = React.useState(false)

  const isAppIdle = useIdleTimeHandler({
    isConnected,
    timeoutInSeconds: appIdleControls.duration,
    isIdleActive: appIdleControls.active,
  })

  const unitGalleryPayload = useGetUnitGalleryQuery(
    { projectName, unitId: unit.id },
    { selectFromResult: selectFromUnitGalleryResult, skip: skipUnitGalleryApi }
  )

  const externalLinksPayload = useGetExternalLinksByPropertyQuery(
    { projectName, propertyId: unit?.name || '' },
    {
      skip: !unit?.name,
      selectFromResult: selectFromExternalLinkResult,
    }
  )

  const videoPlayerRef = React.useRef<VideoPlayerRef>(null)

  const videoPlayerFirebaseService = VideoPlayerFirebaseService({
    galleryName: 'propertyGallery',
    projectIdentity,
  })

  const [videoSyncKey, setVideoSyncKey] = React.useState<number | null>(null)
  const [activeSlideIndex, setActiveSlideIndex] = React.useState(0)
  const [autoPlayState, setAutoPlayState] = React.useState(false)
  const [playerState, setPlayerState] = React.useState(false)
  const [pinnedItemIndex, setPinnedItemIndex] = React.useState<number | null>(
    null
  )

  const slides = React.useMemo(
    () =>
      (gallery || []).map((item) => ({
        id: item.id,
        label: item.label,
        src:
          item.type === FileType.Image && item.src
            ? item.src
            : item.thumbnail || '',
        thumbnail: item.thumbnail,
        originalSrc: item.src,
        bucketType: BucketSource.Legacy,
        fileType: item.type as FileType,
        noSpliceUrl: item.noSpliceUrl,
      })),
    [gallery]
  )
  const activeSlide = React.useMemo(
    () => slides[activeSlideIndex],
    [slides, activeSlideIndex]
  )

  const hasVideoItems = React.useMemo(
    () => slides.some((item) => item.fileType === FileType.Video),
    [slides]
  )

  const videoSource = React.useMemo(
    () =>
      (activeSlide?.fileType === FileType.Video &&
        AssetHandler({
          url: activeSlide.originalSrc,
          type: activeSlide.bucketType,
        })) ||
      '',
    [slides, activeSlide]
  )

  const unitGalleryExistsInTheStore = (unitId: string): boolean =>
    unitGallery.find(
      (galleryItem: UnitGalleryData) => galleryItem.unit === unitId
    ) !== undefined

  React.useEffect(() => {
    const foundData = panoramic.find(
      (item: PannellumDataInterface) =>
        item.type === 'unit' &&
        item.panoramaGroup?.toString() === unit.name?.toString()
    )
    setPanoramicDataState(foundData !== undefined)
  }, [panoramic, unit])

  React.useEffect(() => {
    if (!unitGalleryExistsInTheStore(unit.id) && skipUnitGalleryApi) {
      setSkipUnitGalleryApi(false)
    }
  }, [unitGallery])

  React.useEffect(() => {
    const { unitGalleryData, isLoaded, isError } = unitGalleryPayload
    const unitId = unit.id
    if (
      isLoaded &&
      unitGalleryData.length > 0 &&
      !unitGalleryExistsInTheStore(unitId) &&
      !isError
    ) {
      dispatch(setUnitGallery({ unit: unitId, items: unitGalleryData }))
    }
  }, [unitGalleryPayload])

  const buildUnitGallery = () => {
    const galleryData: Array<UnitGallery> = []
    const {
      id: unitId,
      metas: { floorImage, floorLabel, floorPdf, viewLineImages },
    } = unit

    if (
      !onlyShowPropertyGalleries &&
      viewLineImages &&
      viewLineImages.length > 0
    ) {
      viewLineImages.forEach((item: ViewLineImage) => {
        galleryData.push({
          ...item,
          id: item.id,
          src: `/${projectId}/views/${item.src}`,
          type: 'image',
          noSpliceUrl: true,
        })
      })
    }

    const foundUnitGallerys = unitGallery.filter(
      (galleryItem: UnitGalleryData) => galleryItem.unit === unitId
    )
    if (foundUnitGallerys.length > 0) {
      const [foundUnitGallery] = foundUnitGallerys
      foundUnitGallery.items.forEach((galleryItem: UnitGallery) => {
        const gradiant = galleryItem.type === 'video' ? 0.5 : 0
        galleryData.push({ ...galleryItem, gradiant, noSpliceUrl: false })
      })
    }

    if (!onlyShowPropertyGalleries && floorImage) {
      galleryData.unshift({
        id: floorPdf,
        type: 'image',
        src: `/${projectId}/floorplans/preview/${floorImage}`,
        label: floorLabel || floorImage,
        noSpliceUrl: true,
      })
    }

    if (!galleryData.length) {
      galleryData.push(NO_IMAGE_OBJECT)
    }

    setGallery(galleryData)
  }

  const setSlideShowToggle = (status: boolean) => {
    setAutoPlayState((prevVal) => !prevVal)
    if (!status) {
      setActiveSlideIndex(0)
    }
  }

  const syncPinnedItemIndex = (
    pinnedItemIndexFirebase: number | null,
    activeSlideIndexFirebase: number,
    autoPlayStateFirebase: boolean
  ) => {
    if (pinnedItemIndexFirebase !== null) {
      if (pinnedItemIndexFirebase !== pinnedItemIndex) {
        setActiveSlideIndex(activeSlideIndexFirebase)
      } else if (pinnedItemIndexFirebase !== activeSlideIndexFirebase) {
        setActiveSlideIndex(pinnedItemIndexFirebase)
      }
      setPinnedItemIndex(pinnedItemIndexFirebase)
    } else {
      setAutoPlayState(autoPlayStateFirebase)
      setActiveSlideIndex(activeSlideIndexFirebase)
      setPinnedItemIndex(pinnedItemIndexFirebase)
    }
  }

  React.useEffect(() => {
    buildUnitGallery()
  }, [unit, unitGallery])

  React.useEffect(() => {
    if (session) {
      const {
        connected,
        building: { unitInfoPopup, externalLinkPopup },
      } = session
      setIsConnected(connected)
      setUnitInfoToggle(unitInfoPopup)
      setShowExternalLinks(externalLinkPopup)
    }
  }, [session])

  React.useEffect(() => {
    setShouldShowSlideShowBtn(gallery.length > 1)
  }, [gallery])

  React.useEffect(() => {
    if (!session) {
      return
    }

    const {
      connected,
      propertyGallery: {
        galleryControlV2: {
          activeSlideIndex: activeSlideIndexFirebase,
          autoPlayState: autoPlayStateFirebase,
          pinnedItemIndex: pinnedItemIndexFirebase,
        },
        playerControlV2: {
          playerState: playerStateFirebase,
          loop: loopFirebase,
          muted: mutedFirebase,
          volume: volumeFirebase,
          seekToValueInSeconds: seekToValueInSecondsFirebase,
          videoSyncKey: videoSyncKeyFirebase,
        },
      },
    } = session
    setIsConnected(connected)

    syncPinnedItemIndex(
      pinnedItemIndexFirebase,
      activeSlideIndexFirebase,
      autoPlayStateFirebase
    )

    videoPlayerRef.current?.updatePlayerState(playerStateFirebase)
    videoPlayerRef.current?.toggleLoop(loopFirebase)
    videoPlayerRef.current?.toggleMute(mutedFirebase)
    videoPlayerRef.current?.updateVolume(volumeFirebase / 10)
    if (seekToValueInSecondsFirebase !== null) {
      videoPlayerRef.current?.seekTo(seekToValueInSecondsFirebase)
    }
    if (videoSyncKeyFirebase !== null) {
      setVideoSyncKey(videoSyncKeyFirebase)
    }
  }, [session?.connected, session?.propertyGallery])

  const handleOnPlay = async (args: PlayState) => {
    await videoPlayerFirebaseService.updateStateOnPlay(args)
  }

  const handleOnBuffer = async (args: BufferState) => {
    await videoPlayerFirebaseService.updateStateOnBuffer(args)
  }

  const handleOnSeek = async (args: SeekState) => {
    await videoPlayerFirebaseService.updateStateOnSeek(args)
  }

  const handleOnSync = async (args: SyncState) => {
    await videoPlayerFirebaseService.updateStateOnSyncRequest(args)
  }

  const handleOnEnd = async (args: EndState) => {
    await videoPlayerFirebaseService.updateStateOnEnd(args)
  }

  return (
    <DataHandler
      payload={{
        ...unitGalleryPayload,
        data: gallery,
        apiData: unitGalleryPayload.unitGalleryData,
      }}
      skeletonFrame={<Skeleton containerClass="!w-full absolute" />}
    >
      <div className="relative flex h-full w-full">
        <IdleTimeHandler disable={isConnected}>
          {!isConnected && (
            <div className="absolute top-0 z-5 w-full items-start p-4">
              <TopControlBar
                hasPanoramicData={hasPanoramicData}
                fullScreenToggle={fullScreenToggle}
                setFullScreenToggle={setFullScreenToggle}
                unitInfoToggle={unitInfoToggle}
                slideShowToggle={autoPlayState}
                shouldShowSlideshowBtn={shouldShowSlideshowBtn}
                setSlideShowToggle={(arg: boolean) => {
                  setSlideShowToggle(arg)
                  setFullScreenToggle(arg)
                }}
                externalLinkToggle={
                  externalLinksPayload?.externalLinks?.length > 0
                }
                setShowExternalLinks={setShowExternalLinks}
                showExternalLinks={showExternalLinks}
                setUnitInfoToggle={setUnitInfoToggle}
                isVideoPlaying={playerState}
              />
            </div>
          )}
          <div
            className={`pointer-events-none absolute bottom-0 z-20 h-92 w-full bg-shadow-horizontal opacity-75 transition duration-300 ease-in ${
              unitInfoToggle && !autoPlayState && !playerState
                ? 'translate-y-0'
                : 'translate-y-full'
            }`}
          >
            <div
              className={`flex h-full transform items-stretch duration-200 ease-in ${
                fullScreenToggle ? 'pb-2' : 'pb-12'
              } pl-5`}
            >
              <div className="self-end">
                <UnitInfoCard unit={unit} />
              </div>
            </div>
          </div>
        </IdleTimeHandler>
        <div className="absolute inset-0">
          <CarouselProvider
            gallerySettings={{ effectType, autoPlayIntervalInSeconds }}
            slides={slides}
            activeSlideIndex={activeSlideIndex}
            showControl={!isConnected && !autoPlayState}
            controlType={propertyGalleryControlStyle as CarouselControlType}
            mousewheel={!isConnected && !autoPlayState}
            getCurrentSlideIndex={setActiveSlideIndex}
            autoPlay={autoPlayState}
            controlPosition="right"
            isAppIdle={isAppIdle}
          />
        </div>
        {hasVideoItems ? (
          <div
            className={`absolute inset-0 ${
              videoSource ? 'visible' : 'invisible'
            }`}
          >
            <div className="flex h-full w-full items-center justify-center">
              <VideoPlayer
                key={`unit-gallery-video-player-${isConnected.toString()}`}
                ref={videoPlayerRef}
                sources={[videoSource]}
                activeIndex={0}
                disablePlayButton={isConnected || autoPlayState}
                showLoopOption={false}
                videoSyncKey={videoSyncKey}
                showControls={!isConnected}
                getPlayerState={(state: boolean) => {
                  getVideoPlayerState(state)
                  setPlayerState(state)
                }}
                {...(isConnected && {
                  updateStateOnPlay: handleOnPlay,
                  updateStateOnBuffer: handleOnBuffer,
                  updateStateOnSeek: handleOnSeek,
                  updateStateOnSyncRequest: handleOnSync,
                  updateStateOnEnd: handleOnEnd,
                })}
                isAppIdle={isAppIdle}
              />
            </div>
          </div>
        ) : null}
      </div>

      {showExternalLinks && (
        <div
          className={`fixed left-0 z-30 w-screen bg-secondaryColour ${
            isConnected || fullScreenToggle
              ? 'top-0 h-screen'
              : 'top-9 h-page-container overflow-hidden rounded-t-3xl'
          }`}
        >
          {!isConnected && (
            <button
              type="button"
              className="absolute left-5 top-5 z-3 rounded bg-white p-1 drop-shadow-40"
              onClick={() => setShowExternalLinks(false)}
            >
              <ArrowSvg className="h-8 w-8" strokeColor="#000" />
            </button>
          )}

          <div className="absolute left-1/2 top-1/2 z-1 -translate-x-1/2 -translate-y-1/2">
            <div className="h-16 w-16 animate-spin rounded-full border-4 border-solid border-mainColour border-t-transparent" />
          </div>

          <iframe
            key={externalLinksPayload?.externalLinks?.[0]?.linkUrl}
            className="relative z-2 h-full w-full overflow-hidden border-0"
            title={externalLinksPayload?.externalLinks?.[0]?.label}
            src={externalLinksPayload?.externalLinks?.[0]?.linkUrl}
            height="100%"
            width="100%"
          />
        </div>
      )}
    </DataHandler>
  )
}

export default connect(
  ({
    projectIdentity,
    firestore: { session },
    panoramic,
    projectConfig: {
      propertyGalleryTab,
      propertyGalleryControlStyle,
      onlyShowPropertyGalleries,
      gallerySettings: { type, intervalInSeconds },
    },
    unitGallery,
  }: RootStateTypeExtra) => ({
    projectIdentity,
    session,
    panoramic,
    propertyGalleryControlStyle,
    onlyShowPropertyGalleries,
    unitGallery,
    appIdleControls: propertyGalleryTab,
    effectType: type,
    autoPlayIntervalInSeconds: intervalInSeconds,
  })
)(UnitView)
