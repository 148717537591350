import React from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { setByFlag } from '@store/actionSlices/houseAndLand'
import { RootState } from '@store/store'

import { HouseAndLandActiveKeyType } from '@api/types/house-and-land-type'

import { ChevronSvg } from '@svg/react'

interface ComponentProps {
  stage: { id: string; label: string }
  active: boolean
  levelStagePrefix: string
}

function StageHead({ stage, active, levelStagePrefix }: ComponentProps) {
  const dispatch = useDispatch()
  const activeLotId = useSelector(
    (state: RootState) => state.houseAndLand.activeLotId
  )

  const handleClick = React.useCallback(() => {
    if (!active) {
      dispatch(
        setByFlag({
          flag: HouseAndLandActiveKeyType.ActiveStageId,
          value: stage.label,
        })
      )
    }
    if (activeLotId) {
      dispatch(
        setByFlag({ flag: HouseAndLandActiveKeyType.ActiveLotId, value: '' })
      )
    }
  }, [dispatch, stage, active, activeLotId])

  return (
    <button
      id={`id-${stage.id}`}
      className="sticky top-0 z-10 flex w-full items-center justify-between bg-[#EEEEEE] py-4"
      type="button"
      onClick={handleClick}
    >
      <span
        className={`${
          active ? 'active-stage underline decoration-2 underline-offset-4' : ''
        } text-lg font-bold tracking-widest`}
      >
        {levelStagePrefix || 'Stage'} {stage.label}
      </span>
      <ChevronSvg className="h-6 w-6" rotate="right" stroke={2} />
    </button>
  )
}

export default StageHead
