import React from 'react'

import { TeamsInfo } from '@api/teams'

import AssetHandler from '@adUtilities/asset-handler/asset-handler'
import useImageURLValidator from '@adUtilities/hooks/image-url-validator'

import TeamsSkeleton from './teams-skeleton'

const fallbackImageURL = process.env.REACT_APP_PLACEHOLDER_IMAGE_URL

interface SlideContentProps {
  isActiveSlide: boolean
  slide: TeamsInfo & { backgroundSrc: string }
  onSlideClick: () => void
}

function SlideContent({
  isActiveSlide,
  slide,
  onSlideClick,
}: SlideContentProps) {
  const { validatedUrl, isLoading } = useImageURLValidator(slide.imageSource)
  const validatedImageURL = React.useMemo(() => {
    if (isLoading || !validatedUrl) {
      return ''
    }

    return AssetHandler({
      url: validatedUrl,
      type: 'legacy',
      noSpliceUrl: false,
    })
  }, [validatedUrl, isLoading])

  const {
    validatedUrl: validatedBackgroundUrl,
    isLoading: isBackgroundLoading,
  } = useImageURLValidator(slide.backgroundSrc)
  const validatedBackgroundImageUrl = React.useMemo(() => {
    if (isBackgroundLoading || !validatedBackgroundUrl) {
      return ''
    }

    return AssetHandler({
      url: validatedBackgroundUrl,
      type: 'legacy',
      noSpliceUrl: false,
    })
  }, [validatedBackgroundUrl, isBackgroundLoading])

  if (isLoading || isBackgroundLoading) {
    return <TeamsSkeleton />
  }

  return (
    <div
      className={`absolute flex h-screen w-full bg-tertiaryColour transition-opacity duration-300 ease-in-out ${
        isActiveSlide ? 'opacity-100' : 'opacity-0'
      }`}
      key={slide.name}
      role="none"
      onClick={() => onSlideClick()}
    >
      <div className="custom-scrollbar flex max-h-[90vh] w-1/2 flex-col justify-center self-center overflow-y-auto bg-transparent px-14 pb-20 text-white ipad-pro-land:w-2/5 ipad-pro-land:px-28">
        <p className="mb-5 text-[45.5px]/none font-medium">
          {slide.label || ''}
        </p>
        <p className="mb-9 font-semibold uppercase tracking-wider">
          {slide.caption || ''}
        </p>
        <p className="mb-[51px] whitespace-pre-line text-base">
          {slide.description}
        </p>
        <div>
          <img
            src={validatedImageURL || fallbackImageURL}
            alt={slide.label || 'No Image'}
            className="max-w-[200px]"
          />
        </div>
      </div>

      <div
        style={{
          backgroundImage: `url(${
            validatedBackgroundImageUrl || fallbackImageURL
          })`,
        }}
        className="background-cover w-1/2 ipad-pro-land:w-3/5"
      ></div>
    </div>
  )
}

export default SlideContent
