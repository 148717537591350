import React from 'react'
import { connect, useDispatch } from 'react-redux'

import {
  BuildingInterface,
  setBuilding,
  setByFlag,
} from '@store/actionSlices/building'
import { resetFilter, setFilter } from '@store/actionSlices/unitFilter'
import { RootStateType } from '@store/store'
import {
  DropDownOption,
  ExtendedUnitFilterOptions,
  PriceRange,
  PropertyConfigurationInterface,
} from '@store/types'

import DropDown from '@components/dropdown'
import IdleTimeHandler from '@components/idle-time-handler'
import ToggleSwitch from '@components/toggle-switch'

import { Level } from '@api/building'
import { MappingBlockCollection } from '@api/interactive-plan'
import { FilterWhiteLabel } from '@api/white-label'

import { CloseSvg } from '@svg/react'

import {
  Unit,
  UnitFilterOptionsConfigurations,
} from '@adUtilities/types/apartment'
import { MinMaxInterface } from '@adUtilities/types/common'

import RangeOptions from './range-options'

export interface ConfigPopupProps {
  isOpen: boolean
  hideLevelOption?: boolean
  toggle: (arg: boolean) => void
  unitFilter: ExtendedUnitFilterOptions
  prices: PriceRange
  showPrice: boolean
  building: BuildingInterface
  blocks: MappingBlockCollection
  aspects: Array<string>
  unitTypes: Array<string>
  propertyConfig: PropertyConfigurationInterface
  onChangeBlock?: (arg: string) => void
  filterWhiteLabel: FilterWhiteLabel
}
function BuildingFilterPopup({
  isOpen,
  toggle,
  unitFilter,
  prices,
  showPrice = true,
  building,
  propertyConfig,
  aspects,
  unitTypes,
  hideLevelOption,
  onChangeBlock,
  blocks,
  filterWhiteLabel,
}: ConfigPopupProps) {
  const filterRef = React.useRef<HTMLDivElement>(null)

  const AVAILABLE_STATUS = 'available'
  const dispatch = useDispatch()
  const [showAvailable, setShowAvailable] = React.useState(
    unitFilter.showAvailable
  )
  const [checkAnyLevel, setAnyLevel] = React.useState(unitFilter.anyLevel)
  const [configurations, setConfigurations] =
    React.useState<UnitFilterOptionsConfigurations>(unitFilter.configurations)
  const [range, setRange] = React.useState<MinMaxInterface>(unitFilter.range)
  const [aspect, setAspect] = React.useState<Array<string>>(unitFilter.aspect)
  const [filterUnitType, setFilterUnitType] = React.useState<string>(
    unitFilter.unitType
  )
  const [activeBlock, setActiveBlock] = React.useState<string>(
    building.activeBlock
  )
  const [activeLevel, setActiveLevel] = React.useState<string>(
    building.activeLevel
  )

  const blockLength = React.useMemo(
    () => building.blockOrders?.length || Object.keys(blocks).length,
    [blocks, building.blockOrders]
  )

  const buildingOptions = React.useMemo(() => {
    if (building.blockOrders.length > 0) {
      return building.blockOrders.map((item) => ({
        label: item,
        value: item,
      }))
    }

    return Object.keys(blocks)?.map((item) => ({
      label: item,
      value: item,
    }))
  }, [blocks, building.blockOrders])

  const unitTypeOptions = React.useMemo(
    () =>
      unitTypes?.map((item) => ({
        label: item,
        value: item,
      })),
    [unitTypes]
  )

  const getLevelOptions = (value?: string) => {
    const block = value || activeBlock
    const levelList: Level[] = [...building.levels]
    if (unitFilter.showAvailable) {
      levelList.filter((lvl) =>
        lvl.data.filter((unt: Unit) => unt.metas.status === AVAILABLE_STATUS)
      )
    }

    const levels: Array<DropDownOption> = []
    levelList.forEach((res) => {
      if (activeBlock && blockLength > 1) {
        if (res.data.find((lvl) => lvl.blockId === block)) {
          levels.push({
            label: res.level,
            value: res.level,
          })
        }
      } else {
        levels.push({
          label: res.level,
          value: res.level,
        })
      }
    })

    return levels
  }

  const handleRangeChange = (rangeValue: { max: string; min: string }) => {
    dispatch(setFilter({ ...unitFilter, range: rangeValue }))
  }

  const handleAspectOnchange = (value: string) => {
    if (aspect.includes(value)) {
      dispatch(
        setFilter({
          ...unitFilter,
          aspect: aspect.filter((item) => item !== value),
        })
      )
    } else {
      dispatch(setFilter({ ...unitFilter, aspect: [...aspect, value] }))
    }
  }

  const handleUnitTypeOnchange = (value: string) => {
    dispatch(
      setFilter({
        ...unitFilter,
        unitType: value,
      })
    )
  }

  const handleConfigurationOnchange = (
    configs: UnitFilterOptionsConfigurations
  ) => {
    dispatch(setFilter({ ...unitFilter, configurations: configs }))
  }

  const handleShowAvailable = (checked: boolean) =>
    dispatch(setFilter({ ...unitFilter, showAvailable: checked }))

  const handleLevelOnChange = (value: string) => {
    dispatch(
      setBuilding({
        ...building,
        activeLevel: value,
        activeUnit: '',
      })
    )
  }

  const handleAnyLevel = (checked: boolean) => {
    dispatch(setFilter({ ...unitFilter, anyLevel: checked }))
  }

  const apply = () => {
    toggle(false)
    dispatch(setFilter({ ...unitFilter, apply: true }))
    if (blockLength > 1) {
      dispatch(setByFlag({ flag: 'activeUnit', value: '' }))
      dispatch(setByFlag({ flag: 'activeBlock', value: activeBlock }))
      dispatch(setByFlag({ flag: 'activeLevel', value: activeLevel }))
      if (typeof onChangeBlock === 'function') {
        onChangeBlock(activeBlock)
      }
    } else {
      handleLevelOnChange(activeLevel)
    }
  }

  const reset = () => {
    dispatch(resetFilter())
  }

  const handleOutsideClick = (event: MouseEvent) => {
    if (
      filterRef.current &&
      !filterRef.current.contains(event.target as Node)
    ) {
      if (isOpen) {
        toggle(false)
      }
    }
  }

  React.useEffect(() => {
    const timeoutId = setTimeout(() => {
      document.addEventListener('click', handleOutsideClick, false)
    }, 0)
    return () => {
      clearTimeout(timeoutId)
      document.removeEventListener('click', handleOutsideClick)
    }
  }, [isOpen])

  React.useEffect(() => {
    setConfigurations(unitFilter.configurations)
    setAspect(unitFilter.aspect)
    setFilterUnitType(unitFilter.unitType)
    setRange(unitFilter.range)
    setShowAvailable(unitFilter.showAvailable)
    setAnyLevel(unitFilter.anyLevel)
  }, [unitFilter])

  React.useEffect(() => {
    if (isOpen && unitFilter.apply) {
      dispatch(setFilter({ ...unitFilter, apply: false }))
    }
  }, [unitFilter, activeLevel, activeBlock])

  React.useEffect(() => {
    setActiveBlock(building.activeBlock)
  }, [building.activeBlock])

  React.useEffect(() => {
    setActiveLevel(building.activeLevel)
  }, [building.activeLevel])

  return (
    <IdleTimeHandler>
      <div
        ref={filterRef}
        className={`fixed top-[36px] z-30 h-page-container w-[297px] rounded-tl-2xl bg-white px-4 pb-10 text-black shadow-2xl ${
          isOpen ? '' : '-translate-x-full'
        }  no-scrollbar overflow-y-scroll transition-transform duration-500`}
      >
        <div className="mt-6 flex items-center justify-between">
          <div>
            <button
              className="flex items-center"
              type="button"
              onClick={() => toggle(!isOpen)}
            >
              <CloseSvg
                className="h-3.5 w-3.5 cursor-pointer"
                strokeColor="black"
              />
            </button>
          </div>
          <div>
            <h4 className="text-xl font-semibold ">Filter</h4>
          </div>
          <div>
            <button
              type="button"
              onClick={() => reset()}
              className="text-base font-medium text-mainColour underline"
            >
              Reset
            </button>
          </div>
        </div>
        <div className="mt-6 flex flex-col gap-[1.20rem] text-base font-medium">
          {buildingOptions.length > 1 && (
            <div className="flex flex-col gap-1">
              <span>{filterWhiteLabel?.blockDropdownLabel || 'Building'}</span>
              <DropDown
                onSelect={(value) => {
                  const [firstLevel] = getLevelOptions(value)
                  setActiveBlock(value)
                  setActiveLevel(firstLevel.value)
                }}
                items={buildingOptions}
                value={activeBlock}
                className="py-2"
                buttonRadius="rounded-[7px]"
                buttonRadiusClasses="rounded-tl-[7px] rounded-tr-[7px]"
                optionRadiusClasses="rounded-bl-[7px] rounded-br-[7px]"
              />
            </div>
          )}

          {getLevelOptions().length && !hideLevelOption ? (
            <div className="flex flex-col gap-1">
              <span className={` ${checkAnyLevel ? 'text-gray-300' : ''}`}>
                {filterWhiteLabel?.levelDropdownLabel || 'Level'}
              </span>
              <DropDown
                onSelect={(value) => {
                  setActiveLevel(value)
                }}
                items={getLevelOptions()}
                disabled={checkAnyLevel}
                value={activeLevel}
                className="py-2"
                buttonRadius="rounded-[7px]"
                buttonRadiusClasses="rounded-tl-[7px] rounded-tr-[7px]"
                optionRadiusClasses="rounded-bl-[7px] rounded-br-[7px]"
              />

              <div className="mt-2 inline-flex items-center">
                <ToggleSwitch
                  key="anyLevel"
                  onChangeCallback={(e) => {
                    handleAnyLevel(e.target.checked)
                  }}
                  initialState={checkAnyLevel}
                  onColor="bg-mainColour"
                  offColor="bg-[#BDC3C7]"
                />
                <div className="ml-3 text-base font-medium">Any Level</div>
              </div>
            </div>
          ) : (
            ''
          )}

          {showPrice && (
            <RangeOptions
              field="price"
              options={prices}
              range={range}
              handleChange={handleRangeChange}
            />
          )}

          {Object.entries(propertyConfig)?.map(
            ([field, value]) =>
              value?.length > 0 && (
                <RangeOptions
                  field={field}
                  key={field}
                  options={value as unknown as Array<string | number>}
                  range={
                    configurations[
                      field as keyof UnitFilterOptionsConfigurations
                    ]
                  }
                  handleChange={(configValue: MinMaxInterface) => {
                    handleConfigurationOnchange({
                      ...configurations,
                      [field as keyof UnitFilterOptionsConfigurations]:
                        configValue,
                    })
                  }}
                />
              )
          )}

          {unitTypeOptions.length > 0 && (
            <div className="flex flex-col gap-1">
              <span>Unit Types</span>
              <DropDown
                onSelect={handleUnitTypeOnchange}
                items={[{ value: 'Any', label: 'Any' }, ...unitTypeOptions]}
                value={filterUnitType}
                className="py-2"
                buttonRadius="rounded-[7px]"
                buttonRadiusClasses="rounded-tl-[7px] rounded-tr-[7px]"
                optionRadiusClasses="rounded-bl-[7px] rounded-br-[7px]"
              />
            </div>
          )}

          {aspects && aspects.length > 0 && (
            <div className="flex flex-col gap-1">
              <span>Aspect</span>
              <div className="grid-cols-auto grid gap-2 md:grid-cols-[repeat(auto-fit,minmax(80px,1fr))]">
                {aspects?.map((aspectValue: string) => (
                  <button
                    type="button"
                    key={aspectValue}
                    onClick={() => {
                      handleAspectOnchange(aspectValue.toLowerCase())
                    }}
                    className={`truncate rounded px-2 py-2 text-center text-base font-medium ${
                      aspect.includes(aspectValue.toLowerCase())
                        ? 'bg-mainColour text-white'
                        : 'bg-[#E5E5E5] hover:bg-[#BDC3C7]'
                    }`}
                  >
                    {aspectValue}
                  </button>
                ))}
              </div>
            </div>
          )}

          <div className="mt-8 inline-flex items-center whitespace-nowrap">
            <ToggleSwitch
              key="checkbox"
              onChangeCallback={(e) => {
                handleShowAvailable(e.target.checked)
              }}
              initialState={showAvailable}
              onColor="bg-mainColour"
              offColor="bg-[#BDC3C7]"
            />
            <div className="ml-3 text-base font-medium">
              {filterWhiteLabel?.toggleText || 'Only show available residences'}
            </div>
          </div>
          <div className="flex flex-col gap-1">
            <button
              type="button"
              onClick={apply}
              disabled={unitFilter.apply}
              className={`rounded-lg px-4 py-4 text-xl font-semibold text-white duration-300  ${
                unitFilter.apply ? 'bg-gray-300' : 'bg-mainColour'
              }`}
            >
              Apply Filters
            </button>
          </div>
        </div>
      </div>
    </IdleTimeHandler>
  )
}

export default connect(
  ({
    projectIdentity: { projectName },
    unitFilter,
    projectConfig: {
      prices,
      longhand,
      showPrice,
      propertyConfig,
      aspects,
      unitTypes,
    },
    interactivePlan: { blocks },
    building,
    whiteLabel: { filter: filterWhiteLabel },
  }: RootStateType) => ({
    projectName,
    unitFilter,
    prices,
    longhand,
    showPrice,
    building,
    propertyConfig,
    aspects,
    unitTypes,
    blocks,
    filterWhiteLabel,
  })
)(BuildingFilterPopup)
