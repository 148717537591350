import React from 'react'
import type { Swiper as SwiperType } from 'swiper'
import 'swiper/css'
import 'swiper/css/effect-fade'
import { Autoplay, EffectFade } from 'swiper/modules'
import { Swiper, SwiperSlide } from 'swiper/react'

import {
  CarouselControlType,
  CarouselEffectType,
  SlideInterface,
} from '@adUtilities/constants/carousel'

import CarouselControl from './carousel-control'
import Slide from './slide'

interface GallerySettings {
  effectType: string
  autoPlayIntervalInSeconds: number
}

interface ComponentProps {
  gallerySettings: GallerySettings
  slides: Array<SlideInterface>
  activeSlideIndex: number
  showControl: boolean
  mousewheel?: boolean
  slideImageClass?: string
  controlType?: CarouselControlType
  controlPosition?: 'left' | 'right'
  showShadows?: boolean
  autoPlay?: boolean
  showBlurredBackground?: boolean
  isAppIdle?: boolean
  onContainerClick?: () => void
  getCurrentSlideIndex?: (activeSlideIndex: number) => void
  getSlideLoadingState?: (slideIndex: number) => void
}

function CarouselProvider({
  gallerySettings,
  slides,
  activeSlideIndex,
  showControl,
  mousewheel = true,
  controlType,
  slideImageClass,
  controlPosition = 'left',
  showShadows = false,
  showBlurredBackground = true,
  autoPlay = false,
  isAppIdle = false,
  onContainerClick,
  getCurrentSlideIndex,
  getSlideLoadingState,
}: ComponentProps) {
  const swiperRef = React.useRef<SwiperType | null>(null)

  const [currentSlideIndex, setCurrentSlideIndex] = React.useState(0)
  const [toggleControls, setToggleControls] = React.useState(true)
  const [lastAutoPlayState, setLastAutoPlayState] = React.useState(autoPlay)

  const effect = React.useMemo(
    () =>
      gallerySettings.effectType === 'fadeIn'
        ? CarouselEffectType.Fade
        : CarouselEffectType.Slide,
    [gallerySettings.effectType]
  )

  const goToSlide = React.useCallback((index: number) => {
    setCurrentSlideIndex(index)
    if (swiperRef.current) {
      swiperRef.current.slideTo(index)
    }
  }, [])

  const handleMouseWheel = React.useCallback(
    (event: React.WheelEvent) => {
      if (!mousewheel) {
        return
      }

      if (event.deltaY < 0) {
        if (currentSlideIndex === 0) {
          return
        }
        goToSlide(currentSlideIndex - 1)
      } else {
        if (currentSlideIndex + 1 === slides.length) {
          return
        }
        goToSlide(currentSlideIndex + 1)
      }
    },
    [currentSlideIndex, mousewheel, slides.length]
  )

  const handleContainerClick = React.useCallback(
    (event: React.MouseEvent<HTMLDivElement>) => {
      event.stopPropagation()
      if (onContainerClick) {
        onContainerClick()
      }
      setToggleControls((prev) => !prev)
    },
    []
  )

  React.useEffect(() => {
    if (activeSlideIndex !== currentSlideIndex) {
      if (autoPlay !== lastAutoPlayState) {
        setTimeout(() => goToSlide(activeSlideIndex), 0)
        return
      }
      goToSlide(activeSlideIndex)
    }
  }, [activeSlideIndex])

  React.useEffect(() => {
    setLastAutoPlayState(autoPlay)
  }, [autoPlay])

  React.useEffect(() => {
    if (swiperRef.current) {
      if (autoPlay) {
        swiperRef.current.autoplay.start()
      } else {
        swiperRef.current.autoplay.stop()
      }
    }
  }, [autoPlay])

  return (
    <div
      onWheel={handleMouseWheel}
      onClick={handleContainerClick}
      className="h-full w-full"
      role="none"
    >
      <Swiper
        className="h-full w-full"
        loop={false}
        speed={600}
        effect={effect}
        fadeEffect={{ crossFade: true }}
        autoplay={
          autoPlay
            ? {
                delay: gallerySettings.autoPlayIntervalInSeconds * 1000,
              }
            : false
        }
        slidesPerView={1}
        onSwiper={(swiper: SwiperType) => {
          swiperRef.current = swiper
        }}
        onActiveIndexChange={(swiper: SwiperType) => {
          const { realIndex } = swiper
          setCurrentSlideIndex(realIndex)
          if (getCurrentSlideIndex) {
            getCurrentSlideIndex(realIndex)
          }
        }}
        modules={[Autoplay, EffectFade]}
      >
        {slides.map((slide: SlideInterface, index: number) => (
          <SwiperSlide
            key={slide.id}
            className="relative h-full w-full overflow-hidden"
          >
            <Slide
              index={index}
              getSlideLoadingState={getSlideLoadingState}
              slide={slide}
              showBlurredBackground={showBlurredBackground}
              imageClass={slideImageClass}
            />
          </SwiperSlide>
        ))}
      </Swiper>

      {showControl && controlType && slides.length > 1 && !autoPlay && (
        <div
          className={`absolute ${
            controlPosition === 'left' ? 'left-6' : 'right-6'
          } bottom-5 z-1 transition-opacity ${
            isAppIdle || !toggleControls ? 'opacity-0' : 'opacity-100'
          }`}
        >
          {showShadows && (
            <div className="pointer-events-none fixed bottom-0 h-1/4 w-screen bg-shadow-horizontal" />
          )}
          <CarouselControl
            type={controlType}
            items={slides}
            currentSlideIndex={currentSlideIndex}
            toggleControls={toggleControls}
            handleItemClick={goToSlide}
          />
        </div>
      )}
    </div>
  )
}

export default CarouselProvider
