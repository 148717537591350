import React from 'react'
import { connect, useDispatch } from 'react-redux'
import { CSSTransition, TransitionGroup } from 'react-transition-group'

import { SessionMap } from '@src/firebase-util/types'

import { DesignInterface, setDesign } from '@store/actionSlices/design'
import { BucketSource, type RootStateTypeExtra } from '@store/types'

import Container from '@components/container'
import DataHandler from '@components/data-handler'
import FadeContainer from '@components/fade-container'
import IdleTimeHandler, {
  useIdleTimeHandler,
} from '@components/idle-time-handler'

import { GalleryData, selectFromResult, useGetDesignQuery } from '@api/design'

import {
  CarouselProvider,
  Tab,
} from '@adUtilities/components/carousel-provider'
import { CarouselControlType } from '@adUtilities/constants/carousel'
import { FileType } from '@adUtilities/constants/common'

import GallerySkeleton from './gallery-skeleton'

export interface DeisgnProps {
  session: SessionMap | undefined
  projectName: string
  design: Array<DesignInterface>
  appIdleControls: {
    duration: number
    active: boolean
  }
  effectType: string
  autoPlayIntervalInSeconds: number
}

function Design({
  session,
  projectName,
  design,
  appIdleControls,
  effectType,
  autoPlayIntervalInSeconds,
}: DeisgnProps) {
  const dispatch = useDispatch()

  const [toggleControls, setToggleControls] = React.useState(true)
  const [isConnected, setIsConnected] = React.useState(false)
  const [activeTabIndex, setActiveTabIndex] = React.useState(0)
  const [activeSlideIndex, setActiveSlideIndex] = React.useState(0)
  const [autoPlayState, setAutoPlayState] = React.useState(false)

  const isAppIdle = useIdleTimeHandler({
    isConnected,
    timeoutInSeconds: appIdleControls.duration,
    isIdleActive: appIdleControls.active,
  })

  const designPayload = useGetDesignQuery({ projectName }, { selectFromResult })

  const slides = React.useMemo(
    () =>
      design.length > 0
        ? design[activeTabIndex].items.map((item: GalleryData) => ({
            id: item.id,
            label: item.title,
            src: item.imageSource,
            thumbnail: item.imageSource,
            bucketType: BucketSource.Legacy,
            fileType: FileType.Image,
            noSpliceUrl: false,
          }))
        : [],
    [design, activeTabIndex]
  )

  const handleCarouselProviderClick = () => setToggleControls((state) => !state)

  React.useEffect(() => {
    const { gallery } = designPayload

    if (design.length === 0 && gallery.length > 0) {
      dispatch(setDesign(gallery))
    }
  }, [designPayload])

  React.useEffect(() => {
    if (session) {
      const {
        connected,
        design: {
          galleryControlV2: {
            activeTabIndex: activeTabIndexFirebase,
            activeSlideIndex: activeSlideIndexFirebase,
            autoPlayState: autoPlayStateFirebase,
          },
        },
      } = session

      setIsConnected(connected)
      setActiveTabIndex(activeTabIndexFirebase)
      setActiveSlideIndex(activeSlideIndexFirebase)
      setAutoPlayState(autoPlayStateFirebase)
    }
  }, [session])

  return (
    <Container>
      <DataHandler
        payload={{
          ...designPayload,
          data: design,
          apiData: designPayload.gallery,
        }}
        skeletonFrame={<GallerySkeleton />}
      >
        {!isConnected && !autoPlayState && (
          <IdleTimeHandler className="absolute top-8 z-2">
            <FadeContainer show={toggleControls}>
              <Tab
                items={design.map((item) => ({ text: item.label }))}
                handleOnItemClick={setActiveTabIndex}
                activeItemIndex={activeTabIndex}
                toggleControls={!toggleControls}
              />
            </FadeContainer>
          </IdleTimeHandler>
        )}

        <TransitionGroup className="h-full">
          <CSSTransition
            key={activeTabIndex}
            classNames="transition-fade"
            timeout={500}
          >
            <CarouselProvider
              gallerySettings={{ effectType, autoPlayIntervalInSeconds }}
              slides={slides}
              activeSlideIndex={activeSlideIndex}
              onContainerClick={handleCarouselProviderClick}
              showControl={!isConnected}
              controlType={CarouselControlType.Thumbnail}
              autoPlay={autoPlayState}
              mousewheel={!isConnected}
              isAppIdle={isAppIdle}
            ></CarouselProvider>
          </CSSTransition>
        </TransitionGroup>
      </DataHandler>
    </Container>
  )
}

const mapStateToProps = ({
  firestore: { session },
  projectIdentity: { projectName },
  design,
  projectConfig: {
    propertyGalleryTab,
    gallerySettings: { type, intervalInSeconds },
  },
}: RootStateTypeExtra) => ({
  session,
  projectName,
  design,
  appIdleControls: propertyGalleryTab,
  effectType: type,
  autoPlayIntervalInSeconds: intervalInSeconds,
})

export default connect(mapStateToProps)(Design)
