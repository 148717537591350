import React from 'react'
import { connect } from 'react-redux'

import { BucketSource, RootStateTypeExtra, SessionMap } from '@store/types'

import FadeContainer from '@components/fade-container'
import IdleTimeHandler from '@components/idle-time-handler'

import { TeamsInfo } from '@api/teams'

import CarouselControl from '@adUtilities/components/carousel-provider/carousel-control'
import { CarouselControlType } from '@adUtilities/constants/carousel'
import { FileType } from '@adUtilities/constants/common'

import SlideContent from './slide-content'

export interface SlideWrapperProps {
  session: SessionMap | undefined
  slideContent: Array<TeamsInfo & { backgroundSrc: string }>
}

function CarouselContent({ session, slideContent }: SlideWrapperProps) {
  const [isConnected, setIsConnected] = React.useState(false)

  const [toggleControls, setToggleControls] = React.useState(true)
  const [currentSlide, setCurrentSlide] = React.useState(0)

  React.useEffect(() => {
    if (session) {
      const {
        connected,
        team: {
          galleryControl: { activeItemID: activeSlideIndexFirebase },
        },
      } = session

      const activeItemIdFirebase = slideContent.findIndex(
        (item) => item.id === activeSlideIndexFirebase
      )
      setIsConnected(connected)
      setCurrentSlide(activeItemIdFirebase !== -1 ? activeItemIdFirebase : 0)
    }
  }, [session, slideContent])

  return (
    <div className="relative h-full bg-neutralColour" data-testid="teams">
      {!isConnected && (
        <IdleTimeHandler>
          <FadeContainer className="relative z-10" show={toggleControls}>
            <div
              className="absolute left-0 flex h-screen w-1/2 cursor-default flex-col justify-end px-14 pb-14 ipad-pro-land:w-2/5 ipad-pro-land:px-28"
              onClick={() => setToggleControls((state) => !state)}
              role="none"
            >
              <div role="none" onClick={(e) => e.stopPropagation()}>
                <CarouselControl
                  type={CarouselControlType.Label}
                  items={slideContent.map((item) => ({
                    id: item.id,
                    src: item.imageSource,
                    label: item.label,
                    fileType: FileType.Image,
                    bucketType: BucketSource.Legacy,
                    noSpliceUrl: false,
                  }))}
                  currentSlideIndex={currentSlide}
                  toggleControls={toggleControls}
                  handleItemClick={setCurrentSlide}
                />
              </div>
            </div>
          </FadeContainer>
        </IdleTimeHandler>
      )}

      {slideContent.map((data, index) => (
        <SlideContent
          key={data.id}
          isActiveSlide={index === currentSlide}
          slide={data}
          onSlideClick={() => setToggleControls((state) => !state)}
        />
      ))}
    </div>
  )
}

export default connect(({ firestore: { session } }: RootStateTypeExtra) => ({
  session,
}))(CarouselContent)
