import React from 'react'

import { ArrowCircleSvg } from '@svg/react'

import {
  CarouselControlType,
  SlideInterface,
} from '@adUtilities/constants/carousel'

import LabelControl from './carousel-controls/label-control'
import Scrollbar from './carousel-controls/scrollbar'
import ThumbnailControl from './carousel-controls/thumbnail-control'
import { setScrollPosition } from './helper'

interface ComponentProps {
  type: CarouselControlType
  items: Array<SlideInterface>
  currentSlideIndex: number
  alignClass?: string
  toggleControls?: boolean
  className?: string
  disableInfiniteScroll?: boolean
  handleItemClick: (itemIndex: number) => void
}

function CarouselControl({
  type,
  items,
  currentSlideIndex,
  alignClass,
  toggleControls,
  className = '',
  disableInfiniteScroll = false,
  handleItemClick,
}: ComponentProps) {
  const dotContainerRef = React.useRef<HTMLDivElement>(null)

  const nextButtonClick = React.useCallback(
    (event: React.MouseEvent<HTMLButtonElement>) => {
      event.stopPropagation()
      if (
        (disableInfiniteScroll && currentSlideIndex === items.length - 1) ||
        !toggleControls
      ) {
        return
      }
      handleItemClick(
        currentSlideIndex + 1 === items.length ? 0 : currentSlideIndex + 1
      )
    },
    [currentSlideIndex, toggleControls, disableInfiniteScroll]
  )

  const previousButtonClick = React.useCallback(
    (event: React.MouseEvent<HTMLButtonElement>) => {
      event.stopPropagation()
      if (
        (disableInfiniteScroll && currentSlideIndex === 0) ||
        !toggleControls
      ) {
        return
      }
      handleItemClick(
        currentSlideIndex === 0 ? items.length - 1 : currentSlideIndex - 1
      )
    },
    [currentSlideIndex, toggleControls, disableInfiniteScroll]
  )

  const dotAlignStyle = () =>
    type === CarouselControlType.Thumbnail ? 'text-center' : 'text-left'

  React.useEffect(() => setScrollPosition(dotContainerRef), [currentSlideIndex])

  return (
    <div className={`flex gap-4 ${alignClass || dotAlignStyle()} ${className}`}>
      {items.length > 3 && (
        <div
          className={`${
            type === CarouselControlType.Thumbnail && 'flex h-[85px]'
          }`}
        >
          <button
            type="button"
            className={`rounded-full drop-shadow-md ${
              type === CarouselControlType.Label && '-mt-1'
            } ${
              disableInfiniteScroll && currentSlideIndex === 0
                ? 'cursor-not-allowed text-gray-300'
                : 'text-white'
            }`}
            onClick={previousButtonClick}
          >
            <ArrowCircleSvg size="m" rotate="left" />
          </button>
        </div>
      )}

      <div className="max-w-[250px] flex-1 ipad-mini-land:max-w-[350px] ipad-pro-land:max-w-[30vw]">
        <div
          ref={dotContainerRef}
          className="no-scrollbar relative flex items-center gap-3 overflow-x-auto pb-1"
        >
          {items.length > 0 &&
            type === CarouselControlType.Thumbnail &&
            items.map((item, index) => (
              <div key={`${index as number}`} className="shrink-0">
                <ThumbnailControl
                  item={item}
                  active={index === currentSlideIndex}
                  handleItemClick={() => handleItemClick(index)}
                  disabled={!toggleControls}
                />
              </div>
            ))}

          {items.length > 0 &&
            type === CarouselControlType.Label &&
            items.map((item, index) => (
              <div key={`${index as number}`} className="shrink-0">
                <LabelControl
                  item={item}
                  active={index === currentSlideIndex}
                  handleItemClick={() => handleItemClick(index)}
                  disabled={!toggleControls}
                />
              </div>
            ))}
        </div>

        {items.length > 3 && (
          <div className="rounded-track mt-2 w-full drop-shadow-md">
            <Scrollbar
              currentSlideIndex={currentSlideIndex}
              totalItem={items.length}
              handleItemClick={handleItemClick}
            />
          </div>
        )}
      </div>

      {items.length > 3 && (
        <div
          className={`${
            type === CarouselControlType.Thumbnail && 'flex h-[85px]'
          }`}
        >
          <button
            type="button"
            className={`rounded-full drop-shadow-md ${
              type === CarouselControlType.Label && '-mt-1'
            } ${
              disableInfiniteScroll && currentSlideIndex !== items.length - 1
                ? 'cursor-not-allowed text-gray-300'
                : 'text-white'
            }`}
            onClick={nextButtonClick}
          >
            <ArrowCircleSvg size="m" rotate="right" />
          </button>
        </div>
      )}
    </div>
  )
}

export default CarouselControl
