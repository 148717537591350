import React from 'react'

import { SortOrder } from '@store/constants'

import {
  selectShowcaseRoomConfigFromResult,
  selectShowcaseRoomsFromResult,
  useGetShowcaseRoomConfigQuery,
  useGetShowcaseRoomsQuery,
} from '@api/showcase-room'

interface ComponentProps {
  projectName: string
  room: string
}

const useGetShowcaseRoomsConfig = ({ projectName, room }: ComponentProps) => {
  const [currentRoomId, setCurrentRoomId] = React.useState('')

  const { isLoaded, showcaseRooms } = useGetShowcaseRoomsQuery(
    { projectName, sort_order: SortOrder.ASC },
    { selectFromResult: selectShowcaseRoomsFromResult }
  )

  const { isLoaded: isConfigLoaded, showcaseRoomConfig } =
    useGetShowcaseRoomConfigQuery(
      {
        projectName,
        showcaseRoomId: currentRoomId,
      },
      {
        selectFromResult: selectShowcaseRoomConfigFromResult,
        skip: !currentRoomId,
      }
    )

  React.useEffect(() => {
    if (showcaseRooms && showcaseRooms.length > 0) {
      const findRoom = showcaseRooms.find(
        ({ name }) => name.toLowerCase() === room.toLowerCase()
      )
      setCurrentRoomId(findRoom?.id || showcaseRooms[0].id)
    }
  }, [showcaseRooms, room])

  return {
    isLoaded: isLoaded && isConfigLoaded,
    navigationSettings: showcaseRoomConfig?.navigationSettings || [],
    theme: {
      font: showcaseRoomConfig?.theme?.font || '',
      mainColour: showcaseRoomConfig?.theme?.mainColor || '',
      secondaryColour: showcaseRoomConfig?.theme?.secondaryColor || '',
      tertiaryColour: showcaseRoomConfig?.theme?.tertiaryColor || '',
      neutralColour: showcaseRoomConfig?.theme?.neutralColor || '',
    },
    templates: showcaseRoomConfig?.templates || {},
  }
}

export default useGetShowcaseRoomsConfig
