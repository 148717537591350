import { UseQueryStateResult } from '@reduxjs/toolkit/dist/query/react/buildHooks.d'
import { createApi } from '@reduxjs/toolkit/query/react'

import { NavigationMenuKey, SortOrder } from '@store/constants'

import baseQueryInterceptor from '@api/base-query-interceptor'
import { ApiResponse } from '@api/types/api-response.type'

import { FileType } from '@adUtilities/constants/common'

interface Project {
  id: string
  canonicalName: string
  title: string
}

export interface ShowcaseRoomItem {
  id: string
  name: string
  project: Project
}

export interface BackgroundConfig {
  type: FileType | 'color'
  color: string
  url: string
}

export interface ThemeConfig {
  font: string
  mainColor: string
  secondaryColor: string
  tertiaryColor: string
  neutralColor: string
}

export interface NavigationItem {
  key: NavigationMenuKey
  label: string
  active: boolean
  order?: number
  sortable: boolean
}

export type Templates = Record<string, { template: string }>

export interface ShowcaseRoomConfig {
  theme: ThemeConfig
  navigationSettings: NavigationItem[]
  templates: Templates
}

export interface ShowcaseRoomData {
  id: string
  name: string
  isDefault: boolean
  project: Project
  showcaseConfig: ShowcaseRoomConfig
}

export interface ShowcaseRoomList {
  data: ShowcaseRoomItem[]
  total: number
  per_page: number
  current_page: number
  last_page: number
}

type ShowcaseRoomListParams = {
  projectName: string
  per_page?: number
  sort_order?: SortOrder.ASC | SortOrder.DESC
  search?: string
  pagination?: boolean
}

type ShowcaseRoomConfigParams = {
  projectName: string
  showcaseRoomId: string
}

export const showcaseRoomApi = createApi({
  reducerPath: 'showcaseRoomApi',
  baseQuery: baseQueryInterceptor,
  endpoints: (builder) => ({
    getShowcaseRooms: builder.query<
      ApiResponse<ShowcaseRoomList>,
      ShowcaseRoomListParams
    >({
      query: ({ projectName, ...params }: ShowcaseRoomListParams) => ({
        url: `/v3/${projectName}/showcase-rooms`,
        method: 'get',
        params,
      }),
    }),
    getShowcaseRoomConfig: builder.query<
      ApiResponse<ShowcaseRoomData>,
      ShowcaseRoomConfigParams
    >({
      query: ({ projectName, showcaseRoomId }: ShowcaseRoomConfigParams) => ({
        url: `/v3/${projectName}/showcase-rooms/${showcaseRoomId}`,
        method: 'get',
      }),
    }),
  }),
})

export const selectShowcaseRoomsFromResult = ({
  data,
  isLoading,
  isError,
  status,
}: UseQueryStateResult<any, any>): {
  showcaseRooms: ShowcaseRoomItem[]
  isLoaded: boolean
  isError: boolean
  status: string
} => ({
  showcaseRooms: data?.data?.data || [],
  isLoaded: !isLoading,
  isError,
  status,
})

export const selectShowcaseRoomConfigFromResult = ({
  data,
  isLoading,
  isError,
  status,
}: UseQueryStateResult<any, any>): {
  showcaseRoomConfig: ShowcaseRoomConfig
  isLoaded: boolean
  isError: boolean
  status: string
} => ({
  showcaseRoomConfig: data?.data?.showcaseConfig || {},
  isLoaded: !isLoading,
  isError,
  status,
})

export const { useGetShowcaseRoomsQuery, useGetShowcaseRoomConfigQuery } =
  showcaseRoomApi
