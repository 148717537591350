import React from 'react'
import { connect, useDispatch } from 'react-redux'

import { setTeams } from '@store/actionSlices/teams'
import { RootStateTypeExtra } from '@store/types'

import Container from '@components/container'
import DataHandler from '@components/data-handler'

import { TeamsInfo, selectFromResult, useGetTeamsQuery } from '@api/teams'

import CarouselContent from './carousel-content'
import TeamsSkeleton from './teams-skeleton'

export interface TeamsProps {
  projectName: string
  teams: Array<TeamsInfo>
}

function Teams({ projectName, teams }: TeamsProps) {
  const dispatch = useDispatch()

  const teamsPayload = useGetTeamsQuery({ projectName }, { selectFromResult })

  React.useEffect(() => {
    const { teamsInfo } = teamsPayload
    if (teams.length === 0 && teamsInfo.length > 0) {
      dispatch(setTeams(teamsInfo))
    }
  }, [teamsPayload, teams])

  const slideData = React.useMemo(() => {
    const logoData = teams.filter((team) => team.type === 'logo')
    const backgroundData = teams.filter((team) => team.type === 'background')

    return logoData.map((data, index) => ({
      ...data,
      backgroundSrc: backgroundData[index]?.imageSource ?? '',
    }))
  }, [teams])

  return (
    <Container>
      <DataHandler
        payload={{
          ...teamsPayload,
          data: teams,
        }}
        skeletonFrame={<TeamsSkeleton />}
      >
        <CarouselContent slideContent={slideData} />
      </DataHandler>
    </Container>
  )
}

export default connect(
  ({ projectIdentity: { projectName }, teams }: RootStateTypeExtra) => ({
    projectName,
    teams,
  })
)(Teams)
