/* eslint-disable react/no-unknown-property */
import { useGLTF } from '@react-three/drei'
import { ThreeEvent } from '@react-three/fiber'
import React from 'react'
import * as THREE from 'three'
import { Euler, Vector3 } from 'three'

type GLTFResult = {
  nodes: {
    [key: string]: THREE.Mesh
  }
  materials: {
    [key: string]: THREE.Material
  }
}

export interface Group {
  mesh: Array<{
    node: string
    material: string
  }>
  hoverActive: boolean
  position?: number[]
  rotation?: number[]
  scale?: number
  hover?: string
  onClick?: (arg: any) => void
  id?: string
}

export interface Mesh {
  id?: string
  node: string
  material: string
  hover?: string
  hoverActive: boolean
  position?: number[]
  rotation?: number[]
  scale?: number
  onClick?: (arg: any) => void
}

export interface MeshEventsInterface {
  [key: string]: {
    hover: boolean
  }
}

export interface BuildingProps {
  gltfSrc: string
  buildingMesh: Array<Group | Mesh>
}

function Building({ gltfSrc, buildingMesh }: BuildingProps) {
  const RESETHOVER = '#FFFFFF'

  const { nodes, materials } = useGLTF(gltfSrc) as unknown as GLTFResult

  React.useEffect(() => {
    useGLTF.preload(gltfSrc)
  })

  const handleOnPointerOver = React.useCallback(
    (e: ThreeEvent<PointerEvent>, hover?: string) => {
      e.stopPropagation()
      const obj = e.object as THREE.Mesh
      const objMaterial = obj.material as THREE.MeshStandardMaterial
      if (hover) {
        objMaterial.color.set(hover)
      }
    },
    []
  )

  const handleOnPointerLeave = React.useCallback(
    (e: ThreeEvent<PointerEvent>, hoverActive: boolean) => {
      e.stopPropagation()
      if (!hoverActive) {
        const obj = e.object as THREE.Mesh
        const objMaterial = obj.material as THREE.MeshStandardMaterial
        objMaterial.color.set(RESETHOVER)
      }
    },
    []
  )

  const handleOnDoubleClick = React.useCallback(
    (
      e: ThreeEvent<MouseEvent>,
      groupId: string | undefined,
      fn: ((arg: any) => void) | undefined
    ) => {
      e.stopPropagation()
      if (fn && groupId) {
        fn(groupId)
      }
    },
    []
  )

  const getGroup = React.useCallback(
    (group: Group, index: number) => (
      <group
        rotation={new Euler(...(group.rotation || []))}
        position={new Vector3(...(group.position || []))}
        scale={group?.scale || 1}
        key={index}
      >
        {group.mesh.map(({ material, node }, key) => {
          if (key === 0) {
            return (
              <mesh
                key={node + (key as number)}
                name={group.mesh[0].node}
                geometry={nodes[node].geometry}
                material={materials[material]}
                onDoubleClick={(e) =>
                  handleOnDoubleClick(e, group?.id, group?.onClick)
                }
                onPointerOver={(e) => handleOnPointerOver(e, group?.hover)}
                onPointerLeave={(e) =>
                  handleOnPointerLeave(e, group.hoverActive)
                }
                material-color={group.hoverActive ? group.hover : RESETHOVER}
              />
            )
          }
          return (
            <mesh
              key={node + (key as number)}
              geometry={nodes[node].geometry}
              material={materials[material]}
            />
          )
        })}
      </group>
    ),
    [materials, nodes]
  )

  const getMesh = React.useCallback(
    (mesh: Mesh, index: number) => (
      <mesh
        key={mesh.node + index}
        name={mesh.node}
        geometry={nodes[mesh.node].geometry}
        material={materials[mesh.material]}
        position={
          mesh?.position ? new Vector3(...(mesh?.position || [])) : undefined
        }
        rotation={
          mesh?.rotation ? new Euler(...(mesh?.rotation || [])) : undefined
        }
        onDoubleClick={(e) => handleOnDoubleClick(e, mesh?.id, mesh.onClick)}
        onPointerOver={(e) => handleOnPointerOver(e, mesh?.hover)}
        onPointerLeave={(e) => handleOnPointerLeave(e, mesh.hoverActive)}
        material-color={mesh.hoverActive ? mesh.hover : RESETHOVER}
      />
    ),
    [materials, nodes]
  )

  const buildModel = React.useMemo(
    () =>
      buildingMesh.map((item: Group | Mesh, index: number) => {
        if ((item as Group)?.mesh) {
          return getGroup(item as Group, index)
        }
        return getMesh(item as Mesh, index)
      }),
    [buildingMesh, getGroup, getMesh]
  )

  return <group>{buildModel}</group>
}
export default Building
