import React from 'react'
import { connect, useDispatch } from 'react-redux'

import { setByFlag } from '@store/actionSlices/building'
import {
  ShortlistState,
  addShortlist,
  filterShortlist,
} from '@store/actionSlices/shortlist'
import {
  RootStateTypeExtra,
  ShortlistPropertyType,
  UnitShortlistInterface,
} from '@store/types'

import { StatusColors, StatusLabels } from '@api/config'

import {
  BathSvg,
  BedSvg,
  CarSvg,
  PowderSvg,
  StarSvg,
  StudySvg,
} from '@svg/react'

import { slugify } from '@adUtilities/helper'
import { notifyError } from '@adUtilities/notifier'
import { Unit } from '@adUtilities/types/apartment'

export interface ComponentPropsInterface {
  unit: Unit
  active: boolean
  shortlists: ShortlistState['shortlists']
  disableSoldProperty: boolean
  statusLabels: StatusLabels
  statusColors: StatusColors
}
function UnitCard({
  unit,
  active = false,
  shortlists,
  disableSoldProperty,
  statusLabels,
  statusColors,
}: ComponentPropsInterface) {
  const MAXIMUM_PROPERTY_LENGTH_FOR_SHORTLIST =
    Number(process.env.REACT_APP_MAXIMUM_PROPERTY_LENGTH_FOR_SHORTLIST) || 4
  const dispatch = useDispatch()

  const isSoldUnit = React.useMemo(() => {
    if (disableSoldProperty && unit.metas.status !== statusLabels?.available) {
      return true
    }
    return false
  }, [unit, statusLabels])

  const checkIfShortListed = (myUnit: Unit) =>
    shortlists.filter((res) => myUnit.name === res.propertyName).length

  const shortlistUnit = (myUnit: Unit) => {
    if (unit.metas.status !== statusLabels?.available) {
      notifyError(
        'This unit is not available, you can only shortlist available unit.'
      )
      return
    }

    if (checkIfShortListed(myUnit)) {
      dispatch(
        filterShortlist({
          propertyId: myUnit.id,
          type: ShortlistPropertyType.Unit,
        })
      )
      return
    }

    if (shortlists.length < MAXIMUM_PROPERTY_LENGTH_FOR_SHORTLIST) {
      dispatch(
        addShortlist({
          propertyId: myUnit.id,
          propertyName: myUnit.name,
          references: {
            blockId: myUnit.blockId,
            levelId: myUnit.metas.level,
          },
          type: ShortlistPropertyType.Unit,
        } as UnitShortlistInterface)
      )
    } else {
      notifyError(
        `You can shortlist a maximum of ${MAXIMUM_PROPERTY_LENGTH_FOR_SHORTLIST} units.`
      )
    }
  }

  const handleUnitClick = (myUnit: Unit) => {
    dispatch(setByFlag({ flag: 'activeUnit', value: myUnit.name }))
    dispatch(setByFlag({ flag: 'activeLevel', value: myUnit.metas.level }))
  }

  const statusColor = React.useMemo(
    () => statusColors[slugify(unit.metas.status || '')] || '#EF4444',
    [statusColors]
  )

  return (
    <div
      role="none"
      className={`mb-2.5 cursor-pointer rounded-md ${
        active ? 'border-l-8 border-mainColour' : ''
      } bg-white ${isSoldUnit ? 'pointer-events-none !bg-[#c2c2c2]' : ''}`}
      onClick={() => handleUnitClick(unit)}
    >
      <div className="px-6 py-4">
        <div className="mb-2 flex justify-between gap-1">
          <div className="flex min-w-0 items-center">
            <div className="flex-1 truncate">
              <span className="font-bold" title={unit.name}>
                {unit.name}
              </span>
            </div>
            <span
              className="ml-4 h-2 w-2 rounded-full"
              style={{
                backgroundColor: statusColor,
              }}
            ></span>
            <span
              className="ml-1 text-xs font-semibold uppercase tracking-widest"
              style={{
                color: statusColor,
              }}
            >
              {unit.metas.status}
            </span>
          </div>
          <button
            type="button"
            title="Shortlist unit"
            onClick={(e) => {
              e.stopPropagation()
              shortlistUnit(unit)
            }}
          >
            <StarSvg
              className={`h-4 w-4 ${
                checkIfShortListed(unit)
                  ? 'stroke-amber-500 text-amber-500'
                  : 'stroke-zinc-400 text-zinc-400'
              }`}
            />
          </button>
        </div>
        <div className="flex gap-3 text-zinc-500">
          {Number(unit.metas?.configurations?.bed) > 0 && (
            <div className="inline-flex items-center gap-1 text-sm" title="Bed">
              <BedSvg className="h-4 w-4" />
              {unit.metas?.configurations?.bed}
            </div>
          )}
          {Number(unit.metas?.configurations?.study) > 0 && (
            <div
              className="inline-flex items-center gap-1 text-sm"
              title="Study"
            >
              <StudySvg className="h-4 w-4" />
              {unit.metas?.configurations?.study}
            </div>
          )}
          {Number(unit.metas?.configurations?.bath) > 0 && (
            <div
              className="inline-flex items-center gap-1 text-sm"
              title="Bath"
            >
              <BathSvg className="h-4 w-4" />
              {unit.metas?.configurations?.bath}
            </div>
          )}
          {Number(unit.metas?.configurations?.powderRoom) > 0 && (
            <div
              className="inline-flex items-center gap-1 text-sm"
              title="Powder"
            >
              <PowderSvg className="h-4 w-4" />
              {unit.metas?.configurations?.powderRoom}
            </div>
          )}
          {Number(unit.metas?.configurations?.car) > 0 && (
            <div className="inline-flex items-center gap-1 text-sm" title="Car">
              <CarSvg className="h-4 w-4" />
              {unit.metas?.configurations?.car}
            </div>
          )}
        </div>
      </div>
    </div>
  )
}
export default connect(
  ({
    shortlist: { shortlists },
    projectConfig: { disableSoldProperty, statusLabels, statusColors },
  }: RootStateTypeExtra) => ({
    shortlists,
    disableSoldProperty,
    statusLabels,
    statusColors,
  })
)(UnitCard)
