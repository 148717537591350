import React from 'react'

interface ComponentProps {
  currentSlideIndex: number
  totalItem: number
  handleItemClick: (itemIndex: number) => void
}

function Scrollbar({
  currentSlideIndex,
  totalItem,
  handleItemClick,
}: ComponentProps) {
  return (
    <div className="relative flex h-[5px] flex-nowrap rounded-md bg-white/[0.35]">
      {Array.from({ length: totalItem }).map((_, index) => (
        <div
          key={index as number}
          className="flex-1 cursor-pointer"
          onClick={(e) => {
            e.stopPropagation()
            handleItemClick(index)
          }}
          role="none"
        />
      ))}
      <div
        className="absolute left-0 top-0 z-1 h-full cursor-pointer rounded-md bg-white/80 transition-transform duration-300"
        style={{
          transform: `translateX(${currentSlideIndex * 100}%)`,
          width: `${100 / totalItem}%`,
        }}
      />
    </div>
  )
}

export default Scrollbar
