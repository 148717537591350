import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { Redirect, Route, RouteProps } from 'react-router'

import { TokenPayloadInterface } from '@store/actionSlices/token'
import { RootStateTypeExtra } from '@store/types'

import { hasToken } from '@utilities/helper'

export type ProtectedRouteProps = {
  authenticationPath: string
  token: TokenPayloadInterface
  fallbackPath?: string
  isRouteEnabled?: boolean
} & RouteProps

function ProtectedRoutes({
  authenticationPath,
  token,
  fallbackPath = '/vision',
  isRouteEnabled = true,
  ...routeProps
}: ProtectedRouteProps) {
  const [isAuthenticated, setIsAuthenticated] = useState(true)

  useEffect(() => {
    if (!hasToken(token)) {
      setIsAuthenticated(false)
    }
  }, [token])

  if (!isAuthenticated) {
    return <Redirect to={{ pathname: authenticationPath }} />
  }

  if (!isRouteEnabled) {
    return <Redirect to={{ pathname: fallbackPath }} />
  }

  return <Route {...routeProps} />
}

export default connect(({ token }: RootStateTypeExtra) => ({
  token,
}))(ProtectedRoutes)
