import React from 'react'
import { connect, useDispatch } from 'react-redux'
import { useHistory, useLocation } from 'react-router'

import {
  BuildingInterface,
  setBuilding,
  setByFlag,
} from '@store/actionSlices/building'
import { setInteractivePlan } from '@store/actionSlices/interactivePlan'
import { setPanoramicData } from '@store/actionSlices/panoramic'
import { ShortlistState } from '@store/actionSlices/shortlist'
import { setFilter } from '@store/actionSlices/unitFilter'
import {
  Channel,
  ExtendedUnitFilterOptions,
  ProjectIdentity,
  RootStateTypeExtra,
  SessionMap,
} from '@store/types'

import Container from '@components/container'
import DataHandler from '@components/data-handler'
import FilterPopup from '@components/filter-popup'
import { useIdleTimeHandler } from '@components/idle-time-handler/utils'
import { PannellumDataInterface } from '@components/showcase-pannellum/types'

import {
  selectFromResult as selectFromBuildingResult,
  useGetBuildingQuery,
} from '@api/building'
import {
  MappingBlockCollection,
  MappingCollection,
  selectFromResult as selectFromResultInteractive,
  useGetInteractivePlanQuery,
} from '@api/interactive-plan'
import {
  selectFromResult as selectFromPanoramicResult,
  useGetPanoramicQuery,
} from '@api/panoramic'

import getFilteredBuildingData from '@utilities/filter-building'
import { getQueryStringParams } from '@utilities/helper'
import LightMapHandler from '@utilities/lightmap-handler/lightmap-handler'

import { Unit } from '@adUtilities/types/apartment'

import FloorplateView from './floorplate-view'
import BuildingSkeleton from './skeleton/building-skeleton'
import UnitView from './unit-view'
import UnitsSidePanel from './units-side-panel'

interface BuildingProps {
  building: BuildingInterface
  floorplan: MappingCollection
  blocks: MappingBlockCollection
  shortlists: ShortlistState['shortlists']
  panoramic: Array<PannellumDataInterface>
  session: SessionMap | undefined
  projectIdentity: ProjectIdentity
  channels: Array<Channel>
  unitFilter: ExtendedUnitFilterOptions
  showPrice: boolean
  lightUpBuildingModelOnFilter: boolean
  availableStatusLabel: string
  onFullScreenToggle?: React.Dispatch<React.SetStateAction<boolean | undefined>>
  controls: {
    active: boolean
    duration: number
  }
}

function Building({
  building,
  floorplan,
  blocks,
  shortlists,
  panoramic,
  session,
  projectIdentity,
  channels,
  unitFilter,
  showPrice,
  lightUpBuildingModelOnFilter,
  availableStatusLabel,
  onFullScreenToggle,
  controls,
}: BuildingProps) {
  const dispatch = useDispatch()
  const history = useHistory()
  const location = useLocation()
  const urlParams = React.useRef(getQueryStringParams(location.search))
  const floorplanLength = React.useMemo(
    () => Object.keys(floorplan).length,
    [floorplan]
  )

  const [unit, setUnit] = React.useState<Unit>()
  const [fullScreenToggle, setFullScreenToggle] = React.useState(false)
  const [unitSidePanelState, setUnitSidePanelState] = React.useState(true)
  const [isFilterOpen, toggleFilter] = React.useState(false)
  const [isConnected, setIsConnected] = React.useState(false)

  const isAppIdle = useIdleTimeHandler({
    isConnected,
    timeoutInSeconds: controls.duration,
    isIdleActive: controls.active,
  })

  const interactivePayload = useGetInteractivePlanQuery(
    { projectName: projectIdentity.projectName },
    { selectFromResult: selectFromResultInteractive }
  )

  const buildingPayload = useGetBuildingQuery(
    { projectName: projectIdentity.projectName },
    { selectFromResult: selectFromBuildingResult }
  )

  const panoramicPayload = useGetPanoramicQuery(
    { projectName: projectIdentity.projectName },
    { selectFromResult: selectFromPanoramicResult }
  )

  const blockList: Array<string> = React.useMemo(
    () =>
      building?.blockOrders.length >= 1
        ? building?.blockOrders
        : Object.keys(blocks) || [],
    [building?.blockOrders, blocks]
  )

  const filteredItems = React.useMemo(() => {
    const output: Array<{ name: string; type: string }> = []
    const data = getFilteredBuildingData(
      building,
      unitFilter,
      showPrice,
      blockList.length,
      availableStatusLabel
    )
    if (data.length > 0) {
      data.forEach((myLevel) => {
        if (myLevel.data.length > 0) {
          myLevel.data.forEach((myUnit) => {
            output.push({
              name: myUnit.name,
              type: 'unit',
            })
          })
        }
      })
    }
    return output
  }, [unitFilter.apply])

  LightMapHandler({
    isActive: true,
    projectIdentity,
    channels: Object.values(channels),
    activeLevel: building.activeLevel,
    activeUnit: building.activeUnit,
    shortlists,
    lightUpBuildingModelOnFilter,
    filteredApplied: unitFilter.apply,
    filteredItems: unitFilter.apply ? filteredItems : [],
  })

  const handleActiveBlockControl = (blockCntrl: string) => {
    if (blockCntrl !== building.activeBlock) {
      dispatch(setByFlag({ flag: 'activeBlock', value: blockCntrl }))
    }
  }

  const handleActiveLevelControl = (activeLevel: string) => {
    if (activeLevel !== building.activeLevel) {
      dispatch(setByFlag({ flag: 'activeLevel', value: activeLevel }))
    }
  }

  const handleActiveUnitControl = (activeUnit: string) => {
    setTimeout(
      () => {
        dispatch(setByFlag({ flag: 'activeUnit', value: activeUnit }))
      },
      activeUnit ? 1000 : 0
    )
  }

  const handleFullScreenToggle = (arg: boolean) => {
    setFullScreenToggle(arg)
    setUnitSidePanelState(!arg)
    if (!isConnected) onFullScreenToggle?.(arg)
  }

  const findUnit = () => {
    building.levels.forEach((lvl) => {
      const foundUnit = lvl.data.find(
        (unt) =>
          unt.name.replace(/\s/g, '') === building.activeUnit.replace(/\s/g, '')
      )
      if (foundUnit) {
        setUnit(foundUnit)
      }
    })
  }

  const resetUnit = () => {
    setUnit(undefined)
  }

  React.useEffect(() => {
    if (building.levels.length && building.activeUnit) {
      findUnit()
    }
    if (!building.activeUnit) {
      resetUnit()
    }
  }, [building])

  React.useEffect(() => {
    const { maps } = interactivePayload
    if (!floorplanLength && maps.areaView) {
      dispatch(setInteractivePlan(maps))
    }
  }, [interactivePayload, floorplan])

  React.useEffect(() => {
    const { building: buildingData, blockOrders: blockOrdersData } =
      buildingPayload

    if (building.levels.length === 0 && buildingData.length > 0) {
      const blockKeys = Object.keys(blocks)
      let orderedBlockKeys = blockKeys.length > 1 ? blockKeys : []
      if (blockOrdersData.length > 1) {
        orderedBlockKeys = blockOrdersData
      }
      const activeBlock =
        building.activeBlock ||
        urlParams.current?.block ||
        orderedBlockKeys[0] ||
        buildingData[0]?.data[0].blockId ||
        ''

      dispatch(
        setBuilding({
          ...building,
          levels: buildingData,
          blockOrders: blockOrdersData,
          activeBlock,
          activeLevel:
            building.activeLevel ||
            urlParams.current?.level ||
            buildingData[0]?.level,
        })
      )
    }
  }, [interactivePayload, buildingPayload, floorplanLength, building, blocks])

  React.useEffect(() => {
    const { panoramic: panoramicData, isLoaded } = panoramicPayload
    if (panoramic.length === 0 && isLoaded && panoramicData.length > 0) {
      dispatch(setPanoramicData(panoramicData))
    }
  }, [panoramicPayload])

  React.useEffect(() => {
    if (session) {
      const {
        connected,
        building: {
          activeBlock,
          activeLevel,
          activeUnit,
          filterPopup,
          unitFilter: unitFilterFirestore,
        },
      } = session
      if (connected) {
        dispatch(setFilter(unitFilterFirestore))
        toggleFilter(filterPopup)
        handleActiveBlockControl(activeBlock)
        handleActiveUnitControl(activeUnit)
        handleActiveLevelControl(activeLevel)
        setIsConnected(connected)
      }
    }
  }, [session])

  React.useEffect(
    () => () => onFullScreenToggle?.(undefined),
    [onFullScreenToggle]
  )

  React.useEffect(() => {
    if (
      !(
        urlParams.current?.block ||
        urlParams.current?.level ||
        urlParams.current?.unit
      )
    ) {
      return
    }
    history.replace(location.pathname)
  }, [location.pathname])

  return (
    <Container fullScreenMode={fullScreenToggle || isAppIdle} className="flex">
      <DataHandler
        payload={{
          ...buildingPayload,
          data: building.levels,
          apiData: buildingPayload.building,
        }}
        skeletonFrame={<BuildingSkeleton />}
        className="flex"
      >
        <>
          {!isConnected && (
            <div
              className={`h-full ${
                isAppIdle || !unitSidePanelState
                  ? '!w-0 opacity-0'
                  : 'opacity-100'
              } w-[297px] flex-grow-0 transition-all duration-300 ease-in-out`}
            >
              <UnitsSidePanel
                filterPopupToggle={isFilterOpen}
                setFilterPopupToggle={(arg: boolean) => toggleFilter(arg)}
              />

              <FilterPopup isOpen={isFilterOpen} toggle={toggleFilter} />
            </div>
          )}

          <div
            className={`z-10 h-full flex-1 ${
              building.activeUnit
                ? 'transition-all duration-300 ease-in-out'
                : ''
            }`}
          >
            {building.activeUnit && unit ? (
              <UnitView
                key={unit.id}
                unit={unit}
                fullScreenToggle={fullScreenToggle}
                setFullScreenToggle={handleFullScreenToggle}
                getVideoPlayerState={handleFullScreenToggle}
              />
            ) : (
              <DataHandler
                payload={{
                  ...interactivePayload,
                  data: floorplanLength,
                  apiData: interactivePayload.maps?.areaView,
                }}
                skeletonFrame={<BuildingSkeleton />}
                className="relative"
              >
                <FloorplateView
                  getVideoPlayerState={handleFullScreenToggle}
                  fullScreenToggle={fullScreenToggle}
                  setFullScreenToggle={handleFullScreenToggle}
                />
              </DataHandler>
            )}
          </div>
        </>
      </DataHandler>
    </Container>
  )
}

export default connect(
  ({
    interactivePlan: { blocks, floorplan },
    projectIdentity,
    shortlist: { shortlists },
    panoramic,
    firestore: { session, lightMap },
    building,
    unitFilter,
    projectConfig: {
      showPrice,
      lightUpBuildingModelOnFilter,
      statusLabels,
      propertyGalleryTab,
    },
  }: RootStateTypeExtra) => ({
    floorplan,
    blocks,
    projectIdentity,
    building,
    shortlists,
    panoramic,
    unitFilter,
    showPrice,
    lightUpBuildingModelOnFilter,
    session,
    channels: lightMap?.channels || [],
    availableStatusLabel: statusLabels?.available,
    controls: propertyGalleryTab,
  })
)(Building)
