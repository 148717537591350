import React from 'react'
import { connect, useDispatch } from 'react-redux'

import { setVideoGallery } from '@store/actionSlices/videoGallery'
import {
  BucketSource,
  ProjectIdentity,
  RootStateTypeExtra,
  SessionMap,
} from '@store/types'

import Container from '@components/container'
import DataHandler from '@components/data-handler'
import FadeContainer from '@components/fade-container'
import IdleTimeHandler, {
  useIdleTimeHandler,
} from '@components/idle-time-handler'

import {
  VideoGalleryData,
  selectFromResult,
  useGetVideoGalleryQuery,
} from '@api/video-gallery'

import VideoPlayerFirebaseService from '@utilities/video-player-firebase-service'

import AssetHandler from '@adUtilities/asset-handler/asset-handler'
import {
  CarouselProvider,
  Tab,
} from '@adUtilities/components/carousel-provider'
import VideoPlayer from '@adUtilities/components/video-player'
import { VideoPlayerRef } from '@adUtilities/components/video-player/video-player'
import { FileType } from '@adUtilities/constants/common'
import {
  BufferState,
  EndState,
  PlayState,
  SeekState,
  SyncState,
} from '@adUtilities/constants/player'

import VideoSkeleton from './video-skeleton'

interface VideoGalleryProps {
  projectIdentity: ProjectIdentity
  videoGallery: Array<VideoGalleryData>
  session: SessionMap | undefined
  appIdleControls: {
    duration: number
    active: boolean
  }
  effectType: string
  autoPlayIntervalInSeconds: number
}

function VideoGallery({
  projectIdentity,
  videoGallery,
  session,
  appIdleControls,
  effectType,
  autoPlayIntervalInSeconds,
}: VideoGalleryProps) {
  const videoPlayerRef = React.useRef<VideoPlayerRef>(null)

  const dispatch = useDispatch()

  const connectedRef = React.useRef(false)
  const [isConnected, setIsConnected] = React.useState(false)
  const [playerState, setPlayerState] = React.useState(false)
  const [toggleControls, setToggleControls] = React.useState(true)
  const [activeSlideIndex, setActiveSlideIndex] = React.useState(0)
  const [loadedSlides, setLoadedSlide] = React.useState<Array<number>>([])
  const [videoSyncKey, setVideoSyncKey] = React.useState<number | null>(null)

  const isAppIdle = useIdleTimeHandler({
    isConnected,
    timeoutInSeconds: appIdleControls.duration,
    isIdleActive: appIdleControls.active,
  })

  const videoPlayerFirebaseService = VideoPlayerFirebaseService({
    galleryName: 'videoGallery',
    projectIdentity,
  })

  const videoGalleryPayload = useGetVideoGalleryQuery(
    { projectName: projectIdentity.projectName },
    { selectFromResult }
  )

  const slides = React.useMemo(
    () =>
      videoGallery.map((item: VideoGalleryData) => ({
        id: item.id,
        label: item.title,
        src: item.previewImageSrc,
        thumbnail: item.previewImageSrc,
        title: item.title,
        videoSource: item.src,
        bucketType: BucketSource.Legacy,
        fileType: FileType.Video,
        noSpliceUrl: false,
      })),
    [videoGallery]
  )

  const handleCarouselProviderClick = () => setToggleControls((state) => !state)

  React.useEffect(() => {
    const { videoGalleryData } = videoGalleryPayload

    if (videoGallery.length === 0 && videoGalleryData.length > 0) {
      dispatch(setVideoGallery(videoGalleryData))
    }
  }, [videoGalleryPayload, videoGallery])

  React.useEffect(() => {
    if (!session) {
      return
    }

    const {
      connected,
      videoGallery: {
        galleryControlV2: { activeSlideIndex: activeSlideIndexFirebase },
        playerControlV2: {
          playerState: playerStateFirebase,
          loop: loopFirebase,
          muted: mutedFirebase,
          volume: volumeFirebase,
          seekToValueInSeconds: seekToValueInSecondsFirebase,
          videoSyncKey: videoSyncKeyFirebase,
        },
      },
    } = session
    connectedRef.current = connected
    setIsConnected(connected)
    setActiveSlideIndex(activeSlideIndexFirebase)
    videoPlayerRef.current?.updatePlayerState(playerStateFirebase)
    videoPlayerRef.current?.toggleLoop(loopFirebase)
    videoPlayerRef.current?.toggleMute(mutedFirebase)
    videoPlayerRef.current?.updateVolume(volumeFirebase / 10)
    if (seekToValueInSecondsFirebase !== null) {
      videoPlayerRef.current?.seekTo(seekToValueInSecondsFirebase)
    }
    if (videoSyncKeyFirebase !== null) {
      setVideoSyncKey(videoSyncKeyFirebase)
    }
  }, [session?.connected, session?.videoGallery])

  const handleOnPlay = React.useCallback(
    async (args: PlayState) => {
      if (connectedRef.current) {
        await videoPlayerFirebaseService.updateStateOnPlay(args)
      }
    },
    [videoPlayerFirebaseService]
  )

  const handleOnBuffer = React.useCallback(
    async (args: BufferState) => {
      if (connectedRef.current) {
        await videoPlayerFirebaseService.updateStateOnBuffer(args)
      }
    },
    [videoPlayerFirebaseService]
  )

  const handleOnSeek = React.useCallback(
    async (args: SeekState) => {
      if (connectedRef.current) {
        await videoPlayerFirebaseService.updateStateOnSeek(args)
      }
    },
    [videoPlayerFirebaseService]
  )

  const handleOnSync = React.useCallback(
    async (args: SyncState) => {
      if (connectedRef.current) {
        await videoPlayerFirebaseService.updateStateOnSyncRequest(args)
      }
    },
    [videoPlayerFirebaseService]
  )

  const handleOnEnd = React.useCallback(
    async (args: EndState) => {
      if (connectedRef.current) {
        await videoPlayerFirebaseService.updateStateOnEnd(args)
      }
    },
    [videoPlayerFirebaseService]
  )

  return (
    <Container
      {...(!isConnected && {
        fullScreenMode: playerState,
        className: playerState ? 'z-30' : '',
      })}
    >
      <DataHandler
        payload={{
          ...videoGalleryPayload,
          data: videoGallery,
          apiData: videoGalleryPayload.videoGalleryData,
        }}
        skeletonFrame={<VideoSkeleton />}
      >
        {!isConnected && !playerState && (
          <IdleTimeHandler className="absolute top-8 z-2">
            <FadeContainer show={toggleControls}>
              <Tab
                items={slides.map((item) => ({ text: item.title }))}
                handleOnItemClick={setActiveSlideIndex}
                activeItemIndex={activeSlideIndex}
                toggleControls={!toggleControls}
              />
            </FadeContainer>
          </IdleTimeHandler>
        )}

        <CarouselProvider
          gallerySettings={{
            effectType,
            autoPlayIntervalInSeconds,
          }}
          slides={slides}
          activeSlideIndex={activeSlideIndex}
          onContainerClick={handleCarouselProviderClick}
          showControl={false}
          mousewheel={!isConnected}
          getCurrentSlideIndex={setActiveSlideIndex}
          getSlideLoadingState={(index: number) =>
            setLoadedSlide((prev) => [...prev, index])
          }
        />

        {loadedSlides.includes(activeSlideIndex) && (
          <div className="absolute inset-0">
            <div className="flex h-full w-full items-center justify-center">
              <VideoPlayer
                ref={videoPlayerRef}
                sources={slides.map((item) =>
                  AssetHandler({
                    url: item.videoSource,
                    type: item.bucketType,
                  })
                )}
                activeIndex={activeSlideIndex}
                disablePlayButton={isConnected}
                videoSyncKey={videoSyncKey}
                showControls={!isConnected}
                getPlayerState={setPlayerState}
                updateStateOnPlay={handleOnPlay}
                updateStateOnBuffer={handleOnBuffer}
                updateStateOnSeek={handleOnSeek}
                updateStateOnSyncRequest={handleOnSync}
                updateStateOnEnd={handleOnEnd}
                isAppIdle={isAppIdle}
              />
            </div>
          </div>
        )}
      </DataHandler>
    </Container>
  )
}

const mapStateToProps = ({
  projectIdentity,
  videoGallery,
  firestore: { session },
  projectConfig: {
    propertyGalleryTab,
    gallerySettings: { type, intervalInSeconds },
  },
}: RootStateTypeExtra) => ({
  projectIdentity,
  videoGallery,
  session,
  appIdleControls: propertyGalleryTab,
  effectType: type,
  autoPlayIntervalInSeconds: intervalInSeconds,
})

export default connect(mapStateToProps)(VideoGallery)
