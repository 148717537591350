import React from 'react'

import AssetHandler from '@adUtilities/asset-handler/asset-handler'
import { SlideInterface } from '@adUtilities/constants/carousel'
import useImageURLValidator from '@adUtilities/hooks/image-url-validator'

import ThumbnailSkeleton from './thumbnail-skeleton'

const fallbackImageURL = process.env.REACT_APP_PLACEHOLDER_IMAGE_URL

interface ComponentProps {
  item: SlideInterface
  active: boolean
  handleItemClick: () => void
  disabled?: boolean
}

function ThumbnailControl({
  item,
  active,
  handleItemClick,
  disabled,
}: ComponentProps) {
  const assetURL = React.useMemo(
    () =>
      AssetHandler({
        url: item.thumbnail || item.src,
        type: item.bucketType,
        noSpliceUrl: item.noSpliceUrl,
      }),
    [item.src, item.bucketType, item.noSpliceUrl]
  )

  const { validatedUrl, isLoading } = useImageURLValidator(assetURL)

  const imageSource = React.useMemo(() => {
    if (isLoading || !validatedUrl) {
      return ''
    }

    return validatedUrl
  }, [validatedUrl, isLoading])

  return (
    <button
      className={`relative h-[85px] w-[106px] overflow-hidden rounded-lg drop-shadow-40 transition-size ${
        active && 'carousel-control-active-item'
      }`}
      onClick={(event: React.MouseEvent<HTMLButtonElement>) => {
        event.stopPropagation()
        handleItemClick()
      }}
      disabled={disabled}
      type="button"
    >
      {isLoading ? (
        <ThumbnailSkeleton />
      ) : (
        <img
          className="h-full w-full object-cover"
          src={imageSource || fallbackImageURL}
          alt={item.label || 'No Image'}
        />
      )}
      <div
        className={`absolute inset-0 h-full w-full bg-black ${
          active && !isLoading ? 'opacity-50' : 'opacity-0'
        }`}
      ></div>
    </button>
  )
}

export default ThumbnailControl
