import React from 'react'
import { useHistory, useLocation } from 'react-router-dom'

import { RouteKey } from '@store/constants'
import { MenuItemInterface } from '@store/types'

import MENU_ITEMS from '@components/navigation/menu-items'

import {
  selectFromResult as selectProjectConfigFromResult,
  useGetConfigQuery,
} from '@api/config'

interface UseIdlePageArgs {
  projectName: string
  connected: boolean
  activeRoute: string | undefined
  navigationSettings: MenuItemInterface[] | undefined
}

const IDLE_ROUTES: string[] = [
  RouteKey.AREA_VIEW,
  RouteKey.LEVEL_VIEW,
  RouteKey.AREA_VIEW_HOUSE_AND_LAND,
  RouteKey.PRECINCT,
]

const FALLBACK_PATH = RouteKey.VISION

const useIdlePage = ({
  projectName,
  connected,
  activeRoute,
  navigationSettings,
}: UseIdlePageArgs) => {
  const history = useHistory()
  const location = useLocation()

  const projectConfigResponse = useGetConfigQuery(
    {
      projectName,
    },
    {
      selectFromResult: selectProjectConfigFromResult,
    }
  )

  const idlePageConfig = projectConfigResponse?.configInfo?.idlePage

  const isRedirectableToIdlePage = React.useMemo<boolean>(() => {
    if (!connected || activeRoute === undefined || !idlePageConfig?.active) {
      return false
    }
    if (IDLE_ROUTES.includes(activeRoute)) {
      return true
    }
    return false
  }, [connected, idlePageConfig?.active, activeRoute])

  const isIdlePageActive = React.useMemo(() => {
    const matchedMenuItem = MENU_ITEMS.find(
      (item) => item.route === idlePageConfig?.page
    )
    const matchedNavigationSetting = navigationSettings?.find(
      (item) => item.key === matchedMenuItem?.key
    )
    return !!matchedNavigationSetting?.active
  }, [navigationSettings, idlePageConfig?.page])

  const redirectToIdlePage = React.useCallback(() => {
    const currentPath = location.pathname.split('/')?.[2]
    if (
      idlePageConfig?.page === undefined ||
      currentPath === idlePageConfig?.page
    ) {
      return
    }
    if (!isIdlePageActive) {
      history.push({
        pathname: FALLBACK_PATH,
        state: { from: location.pathname },
      })
      return
    }
    history.push({
      pathname: idlePageConfig.page,
      state: { from: location.pathname },
    })
  }, [location.pathname, idlePageConfig?.page, isIdlePageActive])

  return {
    isRedirectableToIdlePage,
    idlePageRoute: idlePageConfig?.page,
    redirectToIdlePage,
  }
}

export default useIdlePage
