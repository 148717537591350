import React from 'react'
import { useSpring } from 'react-spring'

interface SpringAnimateProps {
  initTarget: number[]
  initPosition: number[]
}

const SpringAnimate = ({ initTarget, initPosition }: SpringAnimateProps) => {
  const [canAnimate, setCanAnimate] = React.useState<boolean>(false)
  const [target, setTarget] = React.useState(initTarget)
  const [position, setPosition] = React.useState(initPosition)

  const [springs, api] = useSpring(
    () => ({
      config: {
        tension: 280,
        friction: 260,
      },
      target: initTarget,
      position: initPosition,
    }),
    []
  )

  React.useEffect(() => {
    if (canAnimate) {
      api.start({
        target,
        position,
      })
    }
  }, [api, canAnimate, position, target])

  return {
    canAnimate,
    setTarget,
    setPosition,
    setCanAnimate,
    springs,
  }
}

export default SpringAnimate
