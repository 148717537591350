import React from 'react'
import { connect, useDispatch } from 'react-redux'
import { Link } from 'react-router-dom'

import { setFloorPlateGallery } from '@store/actionSlices/floorPlateGallery'
import {
  BucketSource,
  ProjectIdentity,
  RootStateTypeExtra,
  SessionMap,
} from '@store/types'

import IdleTimeHandler from '@components/idle-time-handler'
import { useIdleTimeHandler } from '@components/idle-time-handler/utils'
import { PannellumDataInterface } from '@components/showcase-pannellum/types'

import {
  FloorPlateGalleryData,
  selectFromResult as selectFromFloorPlateGalleryResult,
  useGetFloorPlateGalleryQuery,
} from '@api/floor-plate-gallery'
import {
  MappingBlockCollection,
  MappingCollection,
} from '@api/interactive-plan'

import { getTabKeyByIndexBy } from '@utilities/floor-tab'
import VideoPlayerFirebaseService from '@utilities/video-player-firebase-service'

import { CarouselProvider } from '@adUtilities/components/carousel-provider'
import VideoPlayer from '@adUtilities/components/video-player'
import { VideoPlayerRef } from '@adUtilities/components/video-player/video-player'
import { CarouselControlType } from '@adUtilities/constants/carousel'
import { FileType } from '@adUtilities/constants/common'
import {
  BufferState,
  PlayState,
  SeekState,
  SyncState,
} from '@adUtilities/constants/player'

import FloorGalleryTab from './floor-gallery-tab'
import FloorplanCanvas from './floorplan-canvas'

interface FloorPlateProps {
  getVideoPlayerState: (arg: boolean) => void
  projectIdentity: ProjectIdentity
  session: SessionMap | undefined
  floorPlateGallery: Array<FloorPlateGalleryData>
  activeLevel: string
  activeBlock: string
  floorPlan: MappingCollection
  blocks: MappingBlockCollection
  panoramic: Array<PannellumDataInterface>
  fullScreenToggle: boolean
  setFullScreenToggle: (arg: boolean) => void
  appIdleControls: {
    active: boolean
    duration: number
  }
  effectType: string
  autoPlayIntervalInSeconds: number
}

function FloorplateView({
  getVideoPlayerState,
  projectIdentity,
  session,
  floorPlateGallery,
  activeLevel,
  activeBlock,
  floorPlan,
  blocks,
  panoramic,
  fullScreenToggle,
  setFullScreenToggle,
  appIdleControls,
  effectType,
  autoPlayIntervalInSeconds,
}: FloorPlateProps) {
  const dispatch = useDispatch()

  const videoPlayerRef = React.useRef<VideoPlayerRef>(null)
  const connectedRef = React.useRef(false)

  const videoPlayerFirebaseService = VideoPlayerFirebaseService({
    galleryName: 'floorGallery',
    projectIdentity,
  })

  const blockKeys: Array<string> = Object.keys(blocks) || []

  const [skipFloorPlateGalleryApi, setSkipFloorPlateGalleryApi] =
    React.useState(true)

  const [isConnected, setIsConnected] = React.useState(false)
  const [activeTab, setActiveTab] = React.useState('floor')
  const [videoPlayerState, setVideoPlayerState] = React.useState(false)
  const [hasPanoramicData, setPanoramicDataState] = React.useState(false)
  const [currentSlideIndex, setCurrentSlideIndex] = React.useState(0)
  const [videoSyncKey, setVideoSyncKey] = React.useState<number | null>(null)

  const isAppIdle = useIdleTimeHandler({
    isConnected,
    timeoutInSeconds: appIdleControls.duration,
    isIdleActive: appIdleControls.active,
  })

  const floorPlateGalleryPayload = useGetFloorPlateGalleryQuery(
    { projectName: projectIdentity.projectName },
    {
      selectFromResult: selectFromFloorPlateGalleryResult,
      skip: skipFloorPlateGalleryApi,
    }
  )

  const slides = React.useMemo(() => {
    if (floorPlateGallery.length < 1) {
      return []
    }
    let foundData = null

    if (activeBlock && activeLevel) {
      foundData = floorPlateGallery.find(
        (value) =>
          value.block.toString() === activeBlock &&
          value.level.toString() === activeLevel
      )
    } else {
      foundData = floorPlateGallery.find(
        (value) => value.level.toString() === activeLevel
      )
    }

    if (foundData === null) {
      return []
    }

    const floorAssets = foundData?.assets || []

    if (floorAssets.length === 0) {
      return []
    }

    return floorAssets.map((item) => ({
      id: item.id,
      src: item.imageSrc || '',
      videoSource: item.videoSrc,
      thumbnail: item.imageSrc,
      fileType: item.type as FileType,
      bucketType: BucketSource.Legacy,
      noSpliceUrl: false,
    }))
  }, [activeLevel, activeBlock, floorPlateGallery])

  const hasVideoItems: boolean = React.useMemo(
    () => slides.filter((item) => item.fileType === FileType.Video).length > 0,
    [slides]
  )

  const videoSource: string = React.useMemo(() => {
    const item = slides.find(
      (slide, index) =>
        slide.fileType === FileType.Video && index === currentSlideIndex
    )

    return item ? item.videoSource || '' : ''
  }, [slides, currentSlideIndex])

  const hasInteractiveMap = (): boolean => {
    let mapName = activeLevel

    if (blockKeys.length > 1) {
      mapName = activeBlock ? `${activeBlock}-${activeLevel}` : activeLevel
    }

    if (mapName in floorPlan) {
      return floorPlan[mapName]?.image !== ''
    }
    return false
  }

  const handleOnPlay = React.useCallback(
    async (args: PlayState) => {
      if (connectedRef.current) {
        await videoPlayerFirebaseService.updateStateOnPlay(args)
      }
    },
    [videoPlayerFirebaseService]
  )

  const handleOnBuffer = React.useCallback(
    async (args: BufferState) => {
      if (connectedRef.current) {
        await videoPlayerFirebaseService.updateStateOnBuffer(args)
      }
    },
    [videoPlayerFirebaseService]
  )

  const handleOnSeek = React.useCallback(
    async (args: SeekState) => {
      if (connectedRef.current) {
        await videoPlayerFirebaseService.updateStateOnSeek(args)
      }
    },
    [videoPlayerFirebaseService]
  )

  const handleOnSync = React.useCallback(
    async (args: SyncState) => {
      if (connectedRef.current) {
        await videoPlayerFirebaseService.updateStateOnSyncRequest(args)
      }
    },
    [videoPlayerFirebaseService]
  )

  React.useEffect(() => setActiveTab('floor'), [activeBlock, activeLevel])

  React.useEffect(() => {
    if (floorPlateGallery.length === 0 && skipFloorPlateGalleryApi) {
      setSkipFloorPlateGalleryApi(false)
    }
  }, [floorPlateGallery])

  React.useEffect(() => {
    const { floorPlateGalleryData } = floorPlateGalleryPayload
    if (floorPlateGallery.length === 0 && floorPlateGalleryData.length > 0) {
      dispatch(setFloorPlateGallery(floorPlateGalleryData))
    }
  }, [floorPlateGalleryPayload])

  React.useEffect(() => {
    const foundItem = panoramic.find(
      (item: PannellumDataInterface) =>
        item.type === 'level' &&
        (item.panoramaGroup || '')?.toString() === activeLevel?.toString()
    )
    setPanoramicDataState(foundItem !== undefined)
  }, [panoramic, activeLevel])

  React.useEffect(() => {
    if (!session) {
      return
    }

    const {
      connected,
      floorGallery: {
        galleryControlV2: {
          activeSlideIndex: activeSlideIndexFirebase,
          activeTabIndex: activeTabIndexFirebase,
        },
        playerControlV2: {
          playerState: playerStateFirebase,
          loop: loopFirebase,
          muted: mutedFirebase,
          volume: volumeFirebase,
          seekToValueInSeconds: seekToValueInSecondsFirebase,
          videoSyncKey: videoSyncKeyFirebase,
        },
      },
    } = session
    connectedRef.current = connected
    setIsConnected(connected)
    setCurrentSlideIndex(activeSlideIndexFirebase)
    setActiveTab(getTabKeyByIndexBy(activeTabIndexFirebase || 0))
    videoPlayerRef.current?.updatePlayerState(playerStateFirebase)
    videoPlayerRef.current?.toggleLoop(loopFirebase)
    videoPlayerRef.current?.toggleMute(mutedFirebase)
    videoPlayerRef.current?.updateVolume(volumeFirebase / 10)
    if (seekToValueInSecondsFirebase !== null) {
      videoPlayerRef.current?.seekTo(seekToValueInSecondsFirebase)
    }
    if (videoSyncKeyFirebase !== null) {
      setVideoSyncKey(videoSyncKeyFirebase)
    }
  }, [session?.connected, session?.floorGallery])

  if (!hasInteractiveMap())
    return (
      <div className="flex h-full w-full items-center justify-center bg-white text-4xl">
        No interactive floor plate found for this level.
      </div>
    )

  return (
    <>
      {activeTab === 'gallery' && slides.length > 0 ? (
        <div className="absolute inset-0">
          <CarouselProvider
            gallerySettings={{
              effectType,
              autoPlayIntervalInSeconds,
            }}
            slides={slides}
            activeSlideIndex={currentSlideIndex}
            showControl={!isConnected}
            controlType={CarouselControlType.Thumbnail}
            getCurrentSlideIndex={setCurrentSlideIndex}
            mousewheel={!isConnected}
            isAppIdle={isAppIdle}
          />

          {hasVideoItems && (
            <div
              className={`absolute inset-0 ${
                videoSource ? 'visible' : 'invisible'
              }`}
            >
              <div className="flex h-full w-full items-center justify-center">
                <VideoPlayer
                  ref={videoPlayerRef}
                  sources={[videoSource]}
                  activeIndex={0}
                  getPlayerState={(state: boolean) => {
                    getVideoPlayerState(state)
                    setVideoPlayerState(state)
                  }}
                  showControls={!isConnected}
                  showLoopOption={false}
                  videoSyncKey={videoSyncKey}
                  updateStateOnPlay={handleOnPlay}
                  updateStateOnBuffer={handleOnBuffer}
                  updateStateOnSeek={handleOnSeek}
                  updateStateOnSyncRequest={handleOnSync}
                  isAppIdle={isAppIdle}
                />
              </div>
            </div>
          )}
        </div>
      ) : (
        <FloorplanCanvas
          session={session}
          isFullWidth={isAppIdle}
          fullScreenToggle={fullScreenToggle}
          setFullScreenToggle={setFullScreenToggle}
        />
      )}

      {(slides.length > 0 || hasPanoramicData) &&
        !videoPlayerState &&
        !isConnected && (
          <IdleTimeHandler>
            <div className="absolute bottom-5 right-5 z-3">
              <div className="flex items-center">
                {slides.length > 0 && (
                  <FloorGalleryTab
                    activeTab={activeTab}
                    handleClick={(arg: string) => setActiveTab(arg)}
                  />
                )}
                {hasPanoramicData && (
                  <Link
                    to="panoramic"
                    className="text-md ml-2 rounded-md border border-mainColour bg-white px-8 py-2 font-medium tracking-widest text-mainColour"
                  >
                    Panoramic
                  </Link>
                )}
              </div>
            </div>
          </IdleTimeHandler>
        )}
    </>
  )
}

const mapStateToProps = ({
  projectIdentity,
  projectConfig: {
    propertyGalleryTab,
    gallerySettings: { type, intervalInSeconds },
  },
  firestore: { session },
  floorPlateGallery,
  building: { activeLevel, activeBlock },
  interactivePlan: { floorplan: floorPlan, blocks },
  panoramic,
}: RootStateTypeExtra) => ({
  projectIdentity,
  session,
  floorPlateGallery,
  activeLevel,
  activeBlock,
  floorPlan,
  blocks,
  panoramic,
  appIdleControls: propertyGalleryTab,
  effectType: type,
  autoPlayIntervalInSeconds: intervalInSeconds,
})

export default connect(mapStateToProps)(FloorplateView)
