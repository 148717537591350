import {
  OrbitControls,
  OrbitControlsChangeEvent,
  OrbitControlsProps,
} from '@react-three/drei'
import { useFrame, useThree } from '@react-three/fiber'
import React, { forwardRef, useImperativeHandle } from 'react'
import { Vector3 } from 'three'

import SpringAnimate from './spring-animate'
import Throttle from './throttle'

export interface CameraControlsRefProps {
  moveCamera: () => void
  setPosition: (position: Vector3) => void
  setTarget: (position: Vector3) => void
}

export interface CameraControlsProps {
  getPosition?: (position: Vector3) => void
  getTarget?: (target: Vector3) => void
  initPosition: Vector3
  initTarget: Vector3
  isMoving?: (arg: boolean) => void
  isCameraLoaded?: (arg: boolean) => void
  maxDistance?: number
  minDistance?: number
  maxPolarAngle?: number
}

const CameraControls = forwardRef<
  CameraControlsRefProps | undefined,
  CameraControlsProps
>(
  (
    {
      getTarget,
      getPosition,
      initPosition,
      initTarget,
      isMoving,
      maxDistance = 1000,
      minDistance = 500,
      maxPolarAngle = 1.5,
      isCameraLoaded,
    },
    ref
  ) => {
    const orbitRef = React.useRef(null)

    const { camera } = useThree()

    const springAnimate = SpringAnimate({
      initTarget: [initTarget.x, initTarget.y, initTarget.z],
      initPosition: [initPosition.x, initPosition.y, initPosition.z],
    })

    const [cameraLoaded, setLoadedPostition] = React.useState<
      Vector3 | undefined
    >(undefined)

    const moveCamera = () => {
      springAnimate.setCanAnimate(true)
    }

    const setPosition = (pos: Vector3) => {
      springAnimate.setPosition([pos?.x || 0, pos?.y || 0, pos?.z || 0])
    }

    const setTarget = (pos: Vector3) => {
      springAnimate.setTarget([pos?.x || 0, pos?.y || 0, pos?.z || 0])
    }

    const setSpringAnimationTarget = (currentTarget: Vector3) => {
      if (currentTarget) {
        springAnimate.springs.target.set([
          currentTarget?.x || 0,
          currentTarget?.y || 0,
          currentTarget?.z || 0,
        ])
      }
    }

    const setSpringAnimationPosition = (currentPosition: Vector3) => {
      if (currentPosition) {
        springAnimate.springs.position.set([
          currentPosition?.x || 0,
          currentPosition?.y || 0,
          currentPosition?.z || 0,
        ])
      }
    }

    useImperativeHandle(ref, () => ({
      moveCamera,
      setPosition,
      setTarget,
    }))

    useFrame(() => {
      if (springAnimate.canAnimate) {
        springAnimate.springs.target.to((x: number, y: number, z: number) => {
          if (orbitRef.current) {
            const orbitControl = orbitRef?.current as OrbitControlsProps
            orbitControl.target = new Vector3(x, y, z)
          }
        })
      }
    })

    useFrame(() => {
      if (springAnimate.canAnimate) {
        springAnimate.springs.position.to((x: number, y: number, z: number) => {
          if (camera) {
            camera.position.set(x, y, z)
          }
        })
      }
    })

    const handleOnStart = React.useCallback(() => {
      if (isMoving) {
        isMoving(true)
      }
      if (springAnimate) {
        springAnimate.setCanAnimate(false)
      }
    }, [])

    const handleOnEnd = React.useCallback(() => {
      if (isMoving) {
        isMoving(false)
      }
      if (orbitRef?.current) {
        const position = (
          orbitRef?.current as OrbitControlsProps
        ).object?.getWorldPosition(new Vector3())
        const target = (orbitRef?.current as OrbitControlsProps)
          .target as Vector3
        if (getTarget) {
          getTarget(target)
        }
        if (getPosition && position) {
          getPosition(position)
        }
        if (target) {
          setSpringAnimationTarget(target)
        }
        if (position) {
          setSpringAnimationPosition(position)
        }
      }
    }, [])

    const handleOnChange = React.useCallback(
      Throttle((e: OrbitControlsChangeEvent | undefined) => {
        if (e) {
          const position = e.target?.object?.getWorldPosition(new Vector3())
          if (!cameraLoaded && e.target) {
            setLoadedPostition(position)
          }
          if (getPosition && position) {
            getPosition(position)
          }
        }
      }, 500),
      [getPosition, Throttle, cameraLoaded, setLoadedPostition]
    )

    React.useEffect(() => {
      if (cameraLoaded && camera) {
        setTimeout(() => {
          setSpringAnimationPosition(cameraLoaded)
          if (isCameraLoaded) {
            isCameraLoaded(true)
          }
        }, 2000)
      }
    }, [cameraLoaded, isCameraLoaded])

    return (
      <OrbitControls
        camera={camera}
        target={initTarget}
        enablePan={false}
        ref={orbitRef}
        onStart={() => handleOnStart()}
        onEnd={() => handleOnEnd()}
        onChange={handleOnChange}
        minDistance={minDistance}
        maxDistance={maxDistance}
        maxPolarAngle={maxPolarAngle}
        makeDefault
      />
    )
  }
)

export default CameraControls
