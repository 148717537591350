import React from 'react'

function ThumbnailSkeleton() {
  return (
    <div className="flex h-full w-full items-center justify-center overflow-hidden rounded-lg bg-secondaryColour">
      <div className="skeleton !h-[30%] !w-[90%]"></div>
    </div>
  )
}

export default ThumbnailSkeleton
