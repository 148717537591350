import React from 'react'
import { connect } from 'react-redux'

import { RootStateTypeExtra, UnitShortlistInterface } from '@store/types'

import ImageHandler from '@components/image-handler'

import { Level } from '@api/building'

import { formatPrice } from '@utilities/helper'

import {
  BathSvg,
  BedSvg,
  CarSvg,
  FloorplanSvg,
  PowderSvg,
  StarSvg,
  StudySvg,
} from '@svg/react'

import { formatAreaNumber } from '@adUtilities/helper'
import { Metas, Unit } from '@adUtilities/types/apartment'

interface UnitThumbnailProps {
  shortlistItem: UnitShortlistInterface
  projectId: string
  onRemove: () => void
  showPrice: boolean
  levels: Level[]
}

function UnitThumbnail({
  shortlistItem,
  projectId,
  onRemove,
  showPrice,
  levels,
}: UnitThumbnailProps) {
  const unit = React.useMemo<Unit | undefined>(() => {
    const { levelId } = shortlistItem.references
    const matchedLevel = levels.find((item) => item.level === levelId)
    return matchedLevel?.data.find(
      (item) => item.id === shortlistItem.propertyId
    )
  }, [shortlistItem, levels])

  const setUnitUrl = (metas: Metas) => {
    const { floorImage, viewLineImages } = metas

    if (floorImage) {
      return `${projectId}/floorplans/preview/${floorImage}`
    }
    if (viewLineImages && viewLineImages.length > 0) {
      const [viewLineImage] = viewLineImages
      return `${projectId}/views/${viewLineImage.src}`
    }
    return ''
  }

  const handleRemove = React.useCallback(
    (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
      e.stopPropagation()
      onRemove()
    },
    [onRemove]
  )

  const canWeShowPrice = showPrice && !unit?.metas?.hidePrice

  if (!unit) {
    return null
  }

  return (
    <div className="relative w-[350px]">
      <div className="absolute left-4.5 top-4.5 flex items-center rounded-[100px] bg-white px-2 py-1.5 shadow-md">
        <div className="mr-1.5 inline-block h-3 w-3 rounded-full bg-green-500" />
        <span className="font-bold leading-none">{unit.name}</span>
      </div>
      <ImageHandler
        className="h-[145px] w-full rounded-t-md object-cover"
        url={setUnitUrl(unit.metas)}
        type="legacy"
        alt={unit.name}
        noSpliceUrl
        isImage
      />
      <div className="flex flex-col gap-1.5 rounded-b-md bg-white px-4.5 py-5">
        {canWeShowPrice && (
          <div className="flex items-center justify-between">
            <p className="text-[17px] font-semibold leading-none tracking-[0.17px] text-gray-900">
              {formatPrice(unit.metas.price)}
            </p>

            <button type="button" title="Shortlist unit" onClick={handleRemove}>
              <StarSvg className="h-4 w-4 stroke-amber-500 text-amber-500" />
            </button>
          </div>
        )}

        <div className="flex items-center justify-between gap-4">
          <div className="flex flex-1 flex-wrap gap-x-[0.6rem] gap-y-1 text-zinc-500">
            {Number(unit.metas?.configurations?.bed) > 0 && (
              <div
                className="inline-flex items-center text-base font-medium"
                title="Bed"
              >
                <BedSvg className="mr-1.5 h-4 w-4" />
                {unit.metas?.configurations?.bed}
              </div>
            )}
            {Number(unit.metas?.configurations?.study) > 0 && (
              <div
                className="inline-flex items-center text-base font-medium"
                title="Study"
              >
                <StudySvg className="mr-1.5 h-4 w-4" />
                {unit.metas?.configurations?.study}
              </div>
            )}
            {Number(unit.metas?.configurations?.bath) > 0 && (
              <div
                className="inline-flex items-center text-base font-medium"
                title="Bath"
              >
                <BathSvg className="mr-1.5 h-4 w-4" />
                {unit.metas?.configurations?.bath}
              </div>
            )}
            {Number(unit.metas?.configurations?.powderRoom) > 0 && (
              <div
                className="inline-flex items-center text-base font-medium"
                title="Powder"
              >
                <PowderSvg className="mr-1.5 h-4 w-4" stroke={15} />
                {unit.metas?.configurations?.powderRoom}
              </div>
            )}
            {Number(unit.metas?.configurations?.car) > 0 && (
              <div
                className="inline-flex items-center text-base font-medium"
                title="Car"
              >
                <CarSvg className="mr-1.5 h-4 w-4" />
                {unit.metas?.configurations?.car}
              </div>
            )}
            <div
              className="inline-flex items-center text-base font-medium text-zinc-500"
              title="Total Area"
            >
              <FloorplanSvg className="mr-1.5 h-4 w-4" stroke={1.5} />
              <span>{`${formatAreaNumber(unit.metas.totalArea)} sqm`}</span>
            </div>
          </div>

          {!canWeShowPrice && (
            <button
              type="button"
              title="Shortlist unit"
              onClick={handleRemove}
              className="self-start pt-0.5"
            >
              <StarSvg className="h-4 w-4 stroke-amber-500 text-amber-500" />
            </button>
          )}
        </div>
      </div>
    </div>
  )
}

export default connect(
  ({ projectConfig: { showPrice } }: RootStateTypeExtra) => ({ showPrice })
)(UnitThumbnail)
