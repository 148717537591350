/* eslint-disable react/no-unknown-property */
import { Bounds, Bvh, Environment } from '@react-three/drei'
import { Canvas } from '@react-three/fiber'
import React, { Suspense, forwardRef, useImperativeHandle } from 'react'
import { Vector3 } from 'three'

import Building, { BuildingProps } from './building'
import CameraControls, {
  CameraControlsProps,
  CameraControlsRefProps,
} from './camera-controls'
import LoadingScreen, { BarInterface, LoaderBg } from './loader'

export type ModelRefProps = CameraControlsRefProps

export interface ModelProps extends CameraControlsProps {
  loader?: {
    icon?: string
    bg?: string
    bar?: BarInterface
  }
  hdr: string
  hdrbackground?: boolean
  color?: string
  models: BuildingProps[]
  maxDistance?: number
  minDistance?: number
  maxPolarAngle?: number
}

const Model = forwardRef<ModelRefProps | undefined, ModelProps>(
  (
    {
      getPosition,
      getTarget,
      initPosition,
      initTarget,
      color = 'skyblue',
      hdr,
      hdrbackground = false,
      models,
      loader = {},
      maxDistance,
      minDistance,
      maxPolarAngle,
    },
    ref
  ) => {
    const refControl = React.useRef<CameraControlsRefProps>(null)

    const [showModel, setShowModel] = React.useState(false)

    useImperativeHandle(ref, () => ({
      moveCamera: () => refControl?.current && refControl?.current.moveCamera(),
      setPosition: (arg: Vector3) =>
        refControl?.current && refControl?.current.setPosition(arg),
      setTarget: (arg: Vector3) =>
        refControl?.current && refControl?.current.setTarget(arg),
    }))

    return (
      <Suspense fallback={<LoadingScreen {...loader} />}>
        {loader.bg && (
          <LoaderBg
            bg={loader.bg}
            classOverride={`absolute transition-all left-0 
              duration-500 top-0 h-full w-full bg-cover bg-center bg-no-repeat blur-lg ${
                showModel ? 'opacity-0 z-[-1]' : 'z-10 opacity-100'
              }`}
          />
        )}
        <Canvas shadows camera={{ position: initPosition }}>
          <Bounds fit clip>
            <Bvh>
              <CameraControls
                ref={refControl}
                initPosition={initPosition}
                initTarget={initTarget}
                getPosition={getPosition}
                getTarget={getTarget}
                maxDistance={maxDistance}
                minDistance={minDistance}
                maxPolarAngle={maxPolarAngle}
                isCameraLoaded={setShowModel}
              />
              <color attach="background" args={[color]} />
              {models.map((model, index) => (
                <Building
                  key={model.gltfSrc + (index as number)}
                  gltfSrc={model.gltfSrc}
                  buildingMesh={model.buildingMesh}
                />
              ))}
              <Environment
                environmentIntensity={0.85}
                files={hdr}
                background={hdrbackground}
              />
            </Bvh>
          </Bounds>
        </Canvas>
      </Suspense>
    )
  }
)

export default Model
