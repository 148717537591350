import React from 'react'

import { SlideInterface } from '@adUtilities/constants/carousel'

export interface ComponentProps {
  item: SlideInterface
  active: boolean
  handleItemClick: () => void
  disabled?: boolean
}

function LabelControl({
  item,
  active,
  handleItemClick,
  disabled,
}: ComponentProps) {
  return (
    <button
      className={`w-auto rounded px-4 py-2 text-sm font-semibold uppercase tracking-widest text-white shadow-lg drop-shadow-md transition-all ${
        active ? 'carousel-control-active-item bg-white/10' : 'bg-white/30'
      }`}
      type="button"
      onClick={(event: React.MouseEvent<HTMLButtonElement>) => {
        event.stopPropagation()
        handleItemClick()
      }}
      disabled={disabled}
    >
      <span className="drop-shadow-70">{item.label || 'No Label'}</span>
    </button>
  )
}

export default LabelControl
