import { NavigationMenuKey, RouteKey } from '@store/constants'
import { MenuItemInterface } from '@store/types'

const MENU_ITEMS: Array<MenuItemInterface> = [
  {
    key: NavigationMenuKey.RESIDENCES,
    label: 'RESIDENCES',
    route: RouteKey.AREA_VIEW,
    relatedRoutes: [
      RouteKey.AREA_VIEW,
      RouteKey.LEVEL_VIEW,
      RouteKey.BUILDING,
      RouteKey.AREA_VIEW_HOUSE_AND_LAND,
      RouteKey.PRECINCT,
      RouteKey.STAGES,
      RouteKey.SNAPLOADER_VIEW,
      RouteKey.THREE_D_BUILDING,
      RouteKey.PANORAMIC_VIEW,
      RouteKey.PANORAMIC,
      RouteKey.AREA_VIEW_3D,
      RouteKey.ADTHREED,
    ],
    active: true,
  },
  {
    key: NavigationMenuKey.INTERACTIVE_MAP,
    label: 'MAP',
    route: RouteKey.INTERACTIVE_MAP,
    relatedRoutes: [RouteKey.INTERACTIVE_MAP],
    active: true,
  },
  {
    key: NavigationMenuKey.VIDEOS,
    label: 'VIDEOS',
    route: RouteKey.VIDEO_GALLERY,
    relatedRoutes: [RouteKey.VIDEO_GALLERY],
    active: true,
  },
  {
    key: NavigationMenuKey.GALLERY,
    label: 'GALLERY',
    route: RouteKey.GALLERY,
    relatedRoutes: [RouteKey.GALLERY],
    active: true,
  },
  {
    key: NavigationMenuKey.BROCHURE,
    label: 'BROCHURE',
    route: RouteKey.BROCHURE,
    relatedRoutes: [RouteKey.BROCHURE],
    active: true,
  },
  {
    key: NavigationMenuKey.TEAM,
    label: 'TEAM',
    route: RouteKey.TEAMS,
    relatedRoutes: [RouteKey.TEAMS],
    active: true,
  },
  {
    key: NavigationMenuKey.EXTERNAL_ROUTES,
    label: 'EXTERNAL LINKS',
    route: RouteKey.EXTERNAL_VIEWS,
    relatedRoutes: [RouteKey.EXTERNAL_VIEWS],
    active: true,
  },
  {
    key: NavigationMenuKey.SHORTLIST,
    label: 'SHORTLIST',
    route: RouteKey.SHORTLIST,
    relatedRoutes: [RouteKey.SHORTLIST],
    active: true,
  },
]

export default MENU_ITEMS
