/* eslint-disable react/prop-types */
import React from 'react'
import { Range, getTrackBackground } from 'react-range'

const STEP = 0.1

interface ScrubBarProps {
  videoLengthInSeconds: number
  playedSeconds: number
  seekTo: (arg: number) => void
  brandColour: string
}

function ScrubBar({
  videoLengthInSeconds,
  playedSeconds,
  seekTo,
  brandColour,
}: ScrubBarProps) {
  const scrubbingRef = React.useRef<boolean>(false)
  const [sliderRangeValue, setSliderRangeValue] = React.useState<number[]>([0])

  const handleOnChange = (values: number[]) => {
    scrubbingRef.current = true
    setSliderRangeValue(values)
  }

  const handleOnFinalChange = (values: number[]) => {
    seekTo(values[0])
    scrubbingRef.current = false
  }

  React.useEffect(() => {
    if (!scrubbingRef.current) {
      setSliderRangeValue([Math.round(playedSeconds / STEP) * STEP])
    }
  }, [playedSeconds])

  return (
    <div className="group relative">
      <Range
        min={0}
        max={videoLengthInSeconds}
        step={STEP}
        values={sliderRangeValue}
        onChange={(value) => handleOnChange(value)}
        onFinalChange={(value) => handleOnFinalChange(value)}
        renderTrack={({ props, children }) => (
          <div
            role="slider"
            tabIndex={0}
            aria-valuemin={0}
            aria-valuemax={videoLengthInSeconds}
            aria-valuenow={sliderRangeValue[0]}
            onMouseDown={props.onMouseDown}
            onTouchStart={props.onTouchStart}
            style={{
              ...props.style,
              height: '30px',
              display: 'flex',
              width: '100%',
            }}
          >
            <div
              ref={props.ref}
              className="h-2 w-full rounded"
              style={{
                background: getTrackBackground({
                  values: sliderRangeValue,
                  colors: [brandColour, '#71717a'],
                  min: 0,
                  max: videoLengthInSeconds,
                }),
                alignSelf: 'center',
              }}
            >
              {children}
            </div>
          </div>
        )}
        renderThumb={({ props }) => (
          <div
            /* eslint-disable-next-line react/jsx-props-no-spreading */
            {...props}
            key={props.key}
            className="h-4 w-4 rounded-full opacity-0 group-hover:opacity-100"
            style={{
              backgroundColor: brandColour,
            }}
          ></div>
        )}
      />
    </div>
  )
}

export default ScrubBar
