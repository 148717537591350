import { useEffect, useState } from 'react'

const useImageURLValidator = (url: string, fallbackUrl = '') => {
  const [validatedUrl, setValidatedUrl] = useState<string>('')
  const [isLoading, setIsLoading] = useState<boolean>(true)

  useEffect(() => {
    const validateImageURL = (
      imageUrl: string,
      fallback: string
    ): Promise<string> =>
      new Promise((resolve) => {
        const img = new Image()
        img.src = imageUrl || fallback

        img.onload = () => resolve(img.src)
        img.onerror = () => resolve(fallback)
      })

    setIsLoading(true)
    validateImageURL(url, fallbackUrl)
      .then((validated) => {
        setValidatedUrl(validated)
        setIsLoading(false)
      })
      .catch(() => {
        setValidatedUrl(fallbackUrl)
        setIsLoading(false)
      })
  }, [url, fallbackUrl])

  return { validatedUrl, isLoading }
}

export default useImageURLValidator
