import React, { useState } from 'react'
import { connect, useDispatch } from 'react-redux'
import { useLocation } from 'react-router'

import { setExternalViews } from '@store/actionSlices/externalViews'
import { RootStateTypeExtra, SessionMap } from '@store/types'

import Container from '@components/container'
import DataHandler from '@components/data-handler'

import {
  ExternalViewsData,
  selectFromResult,
  useGetExternalViewsQuery,
} from '@api/external-views'
import { Templates } from '@api/showcase-room'

import { InfoCircleSvg } from '@svg/react'

export interface ExternalViewsProps {
  projectName: string
  externalViews: Array<ExternalViewsData>
  session: SessionMap | undefined
  templates: Templates
}

function ExternalViews({
  projectName,
  externalViews,
  session,
  templates,
}: ExternalViewsProps) {
  const dispatch = useDispatch()
  const location = useLocation()

  const [selectedView, setSelectedView] = useState(0)

  const externalViewsPayload = useGetExternalViewsQuery(
    { projectName },
    { selectFromResult }
  )

  const remoteExternalViewV2Selected = React.useMemo(
    () =>
      (templates?.['external-views']?.template === 'v2' &&
        session?.connected) ||
      false,
    [templates, session?.connected]
  )

  const setExternalView = () => {
    if (session) {
      setSelectedView(session.externalView || 0)
      return
    }

    const { state } = location
    if (state !== undefined) {
      setSelectedView(state)
      return
    }

    setSelectedView(0)
  }

  React.useEffect(() => {
    const { externalViewsData } = externalViewsPayload
    if (externalViews.length === 0 && externalViewsData.length > 0) {
      dispatch(setExternalViews(externalViewsData))
    }
  }, [externalViewsPayload, externalViews])

  React.useEffect(() => {
    setExternalView()
  }, [location, session])

  return (
    <DataHandler
      payload={{
        ...externalViewsPayload,
        data: externalViews.length > 0,
        apiData: externalViewsPayload.externalViewsData,
      }}
    >
      {remoteExternalViewV2Selected ? (
        <div className="h-screen">
          <div className="flex h-full">
            <div className="flex w-1/2 items-center justify-center bg-mainColour">
              <InfoCircleSvg className="h-32 w-32 text-white" />
            </div>
            <div className="flex w-1/2 items-center bg-white">
              <div className="mx-8">
                <h3 className="text-4xl font-bold">External View selected!</h3>
                <p className="mt-5 text-xl">
                  An external site has been launched. Please use the Remote
                  device to continue navigating.
                </p>
              </div>
            </div>
          </div>
        </div>
      ) : (
        <Container>
          <iframe
            key={selectedView}
            className="z-0 h-full w-full overflow-hidden border-0"
            title={externalViews[selectedView]?.title}
            src={externalViews[selectedView]?.url}
            height="100%"
            width="100%"
          />
        </Container>
      )}
    </DataHandler>
  )
}

export default connect(
  ({
    projectIdentity: { projectName },
    externalViews,
    firestore: { session },
    projectConfig: { templates },
  }: RootStateTypeExtra) => ({
    projectName,
    externalViews,
    session,
    templates,
  })
)(ExternalViews)
