import { Configurations, MinMaxInterface } from './common'

export enum LotStatusType {
  Sold = 'sold',
  Available = 'available',
}

export interface Package {
  id: string
  name: string
  storey: number
  builder: string
  configuration: Configurations
  lots: Array<string>
}

export interface Lot {
  id: string
  name: string
  area: string
  frontage: number
  aspect: string
  price: number
  status: LotStatusType
}

export interface Stage {
  id: string
  label: string
  lots: Lot[]
}

export type Stages = Record<string, Stage>

export interface PackageSummary {
  packages: Package[]
  stages: Stages
}

export interface FilterOptionsConfigurations {
  bed?: MinMaxInterface
  study?: MinMaxInterface
  bath?: MinMaxInterface
  powderRoom?: MinMaxInterface
  car?: MinMaxInterface
}

export interface FilterOptionLotConfigurations {
  frontage?: MinMaxInterface
  area?: MinMaxInterface
  aspects?: Array<string>
  price?: MinMaxInterface
}

export interface FilterOptions {
  configurations?: FilterOptionsConfigurations
  builder?: Array<string>
  storey?: Array<number>
  lotConfiguration?: FilterOptionLotConfigurations
  showOnlyAvailable: boolean
}

export enum HouseAndLandPackageOptionType {
  FloorplanOption = 'floorplanOptions',
  Facade = 'facades',
  FacadeColor = 'facadeColors',
  InternalTheme = 'internalThemes',
  InternalColorScheme = 'internalColorSchemes',
}

export interface PackageConfiguration {
  floorplan?: string
  floorplanOption?: string
  facade?: string
  facadeColor?: string
  internalTheme?: string
  internalColorScheme?: string
}

export interface LotFilterConfiguration {
  frontage: MinMaxInterface
  area: MinMaxInterface
  aspects: string[]
  price: MinMaxInterface
}

export interface LotFilter {
  configurations: FilterOptionsConfigurations
  builder: Array<string>
  storey: Array<number>
  lotConfiguration: LotFilterConfiguration
  showOnlyAvailable: boolean
  anyStage: boolean
  apply: boolean
}
