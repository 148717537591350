import { ReactNode } from 'react'

import type { RootStateType } from '@store/store'

import {
  FilterOptionLotConfigurations,
  FilterOptionsConfigurations,
  HouseAndLandPackageOptionType,
} from '@utilities/adgroup-utilities/types/houseAndLand'

import { FileType } from '@adUtilities/constants/common'
import { UnitFilterOptions } from '@adUtilities/types/apartment'

export type RootStateTypeExtra = RootStateType

// TODO: This interface is kind of duplicate of FilterOptions (from utility submodule), this needs to be addressed (later)
// Not changing right now because the properties in the other interface are all options but required here, and need to be sorted
export interface LotFilterInterface {
  configurations: FilterOptionsConfigurations
  builder: Array<string>
  storey: Array<number>
  lotConfiguration: FilterOptionLotConfigurations
  showOnlyAvailable: boolean
  anyStage: boolean
  apply: boolean
}

export enum InteractivePlanType {
  AreaView = 'areaView',
  Blocks = 'blocks',
  Floorplan = 'floorplan',
  Precinct = 'precinct',
  Stages = 'stages',
}

export interface DropDownOption {
  label: ReactNode
  value: string
}

export interface ConfigOption {
  max: Array<DropDownOption>
  min: Array<DropDownOption>
}

export interface PriceRange {
  max: Array<number>
  min: Array<number>
}

export interface PropertyConfigurationInterface {
  bath?: Array<number>
  bed?: Array<number>
  car?: Array<number>
  study?: Array<number>
  powderRoom?: Array<number>
}

export interface HouseAndLandConfigurationInterface {
  lotFrontage?: Array<number>
  lotArea?: Array<number>
  builder?: Array<string>
  storey?: Array<{ label: string; value: number }>
}

export interface ExtendedUnitFilterOptions extends UnitFilterOptions {
  anyLevel: boolean
  apply: boolean
}

export interface Channel {
  name: string
  status: boolean
  channel: string | number
  type: string
}

export interface SessionOwner {
  email: string
  name: string
  lastUpdated: string
}

export interface SnapDataInterface {
  views: Array<string>
  levels: Array<{ key: any; value: any }>
}

interface SnaploaderInterface {
  isLoaded: false
  data: SnapDataInterface
  activeView: string
  activeBlock: string
  rotateCamera: boolean
}

export interface ProjectSwitch {
  projectLabel: string
  projectId: string
  projectName: string
}

export interface EnvisionVRInterface {
  isLoaded: boolean
  buildings: {
    data: Array<string>
  }
  levels: {
    data: Array<string>
  }
  rotateCamera: true
  rotateCameraBy: {
    direction: string
    triggerKey: string
  }
  zoomCamera: {
    action: string
    triggerKey: string
  }
  activeBuilding: string
  modelResetTriggerKey: string
}

export interface SessionMap {
  client: { showcase: boolean }
  connected: boolean
  activeRoute: string
  appIdleKey: string
  showcaseRefreshTriggerKey: string
  clearCacheTriggerKey: string
  sessionOwners: Array<SessionOwner>
  interactiveMap: Array<string>
  interactiveMapActiveCategory: {
    label: string
    baseImageOverride: string
  }
  projectSwitch: ProjectSwitch
  vision: {
    galleryControl: GalleryControlInterface
    galleryControlV2: GalleryControlInterfaceV2
    playerControl: PlayerControlInterface
    sessionModal: boolean
  }
  areaView: {
    triggerBuilding: boolean
  }
  design: {
    galleryControl: GalleryControlInterface
    galleryControlV2: GalleryControlInterfaceV2
  }
  brochure: {
    galleryControl: GalleryControlInterface
    galleryControlV2: GalleryControlInterfaceV2
  }
  location: {
    galleryControl: GalleryControlInterface
  }
  team: {
    galleryControl: GalleryControlInterface
  }
  floorGallery: {
    galleryControl: GalleryControlInterface
    playerControl: PlayerControlInterface
    galleryControlV2: GalleryControlInterfaceV2
    playerControlV2: PlayerControlInterfaceV2
  }
  propertyGallery: {
    galleryControlV2: GalleryControlInterfaceV2
    playerControlV2: PlayerControlInterfaceV2
  }
  building: {
    activeFacade: string
    activeLevel: string
    activeBlock: string
    activeUnit: string
    filterPopup: boolean
    unitInfoPopup: boolean
    externalLinkPopup: boolean
    sidePanelFolded: boolean
    unitFilter: ExtendedUnitFilterOptions
    levelsShowLabels: boolean
    unitsShowLabels: boolean
    aspect: string
    showAvailable: boolean
    apply: boolean
  }
  houseAndLand: {
    activeStageId: string
    activePrecinctId: string
    activeLotId: string
    activePackageId: string
    activeFloorplanOptionId: string
    activeFacadeId: string
    activeFacadeColorId: string
    activeInternalThemeId: string
    activeInternalColorSchemeId: string
    lastActiveOption: {
      type: HouseAndLandPackageOptionType
      id: string
    }
    filterPopup: boolean
    lotInfoPopup: boolean
    externalLinkPopup: boolean
    sidePanelFolded: boolean
    lotFilter: LotFilterInterface
    isDataLoaded: boolean
  }
  panoramic: {
    galleryControl: GalleryControlInterface
    pitch: number
    yaw: number
    zoom: number
    scene: string
    rotationSpeed: number
  }
  videoGallery: {
    galleryControl: GalleryControlInterface
    playerControl: PlayerControlInterface
    galleryControlV2: GalleryControlInterfaceV2
    playerControlV2: PlayerControlInterfaceV2
  }
  externalView: number
  snaploader: SnaploaderInterface
  envisionVR: EnvisionVRInterface
  shortlist: {
    properties: ShortlistItemInterface[]
    selectedDocumentIds: string[]
    selectedAgentId: string
    shortlistKey: string
  }
  threeDView: {
    position: number[]
  }
}

export interface User {
  _id: string
  name: string
  email: string
  email_verified_at: string
  updated_at: string
  created_at: string
}

export interface Token {
  token: string
  expires_at: string
}

export interface SessionKey {
  key: string
  project: string
}

export interface MasterSessionKey {
  projectId: string
  key: string
}

export interface MenuItemInterface {
  key: string
  label: string
  active: boolean
  route?: string
  relatedRoutes?: Array<string>
  order?: number
}

export interface ProjectIdentity {
  projectId: string
  projectName: string
  projectLabel: string
  sessionId: Array<SessionKey>
  lightMap: string
  masterKey: string
  assetPort: string
}

// TODO: Couldn't find reference of this `Level` Interface being used. Remove later
export interface Level {
  level: string
  aspect: string
  floorPlan: string
  units: Array<Unit>
}

export interface Unit {
  name: string
  configuration: string
  status: string
  image: string
  price?: number
  gallery?: Array<string>
  coordinates?: {
    x: number
    y: number
  }
  documents?: Array<{
    title: string
    url: string
  }>
}

export interface DotInterface {
  text: string
}

export interface TextInterface {
  text: string
  subText?: string
}
export interface SlideItemInterface {
  text: string
  subText?: string
}

export interface LabelInterface {
  text: string
}

export interface ThumbnailInterface {
  src: string
  type: 'legacy' | 'new'
  noSpliceUrl: boolean
  gradiant: number
}

export interface GalleryItemInterface {
  title: string
  imageSource: string
  videoSource?: string
}

export interface GalleryInterface {
  label?: string
  items: Array<GalleryItemInterface>
}

export interface TabInterface {
  index: number
  label: string
}

export interface GalleryControlInterface {
  activeTabIndex: number
  activeItemID: string
  transitionTime: number
  isPlaying: boolean
  transitionType: string
}

export interface GalleryControlInterfaceV2 {
  activeTabIndex: number
  activeSlideIndex: number
  autoPlayState: boolean
  pinnedItemIndex: number | null
}

export interface PlayerControlInterface {
  isPlayerVisible: boolean
  isMuted: boolean
  volume: number
  playerState: string
  fullScreen: boolean
  scrubbedLength: number
  videoLengthInSeconds: number
  isBuffering: boolean
  scrubPositionInSeconds: number
  currentVideoPositionInSeconds: number
  videoSynchronizeTriggerKey: boolean
}

export interface PlayerControlInterfaceV2 {
  scrubbarTimerState: boolean
  playerState: PlayerState
  isBuffering: boolean
  loop: boolean
  seekToValueInSeconds: number | null
  muted: boolean
  volume: number
  videoLengthInSeconds: number
  currentVideoPositionInSeconds: number
  videoSyncKey: number | null
}

export interface UnitGallery {
  id: string
  src: string
  label: string
  type: string
  noSpliceUrl: boolean
  thumbnail?: string
  gradiant?: number
}

export interface UnitGalleryData {
  unit: string
  items: Array<UnitGallery>
}

export interface LotGallery {
  id: string
  src: string
  label: string
  type: FileType
  noSpliceUrl: boolean
  thumbnail?: string
  gradiant?: number
}

export interface LotGalleryData {
  lot: string
  items: Array<LotGallery>
}

export interface FloorTabInterface {
  key: string
  label: string
}

export interface PackageConfigurationInterface {
  floorplan?: string
  floorplanOption?: string
  facade?: string
  facadeColor?: string
  internalTheme?: string
  internalColorScheme?: string
}

export enum ShortlistPropertyType {
  Lot = 'lot',
  Unit = 'unit',
}

interface BaseShortlistInterface {
  propertyId: string
  propertyName: string
}

export interface UnitShortlistInterface extends BaseShortlistInterface {
  references: {
    blockId: string | null
    levelId: string
  }
  type: ShortlistPropertyType.Unit
}
export interface LotShortlistInterface extends BaseShortlistInterface {
  configuration?: PackageConfigurationInterface
  references: {
    precinctId: string
    stageId: string
  }
  type: ShortlistPropertyType.Lot
}

export type ShortlistItemInterface =
  | UnitShortlistInterface
  | LotShortlistInterface

export enum BucketSource {
  Legacy = 'legacy',
  New = 'new',
}

export enum PlayerState {
  Play = 'play',
  Pause = 'pause',
  Stop = 'stop',
}
