import React from 'react'

interface ComponentProps {
  items: Array<{ text: string; subText?: string }>
  handleOnItemClick: (index: number) => void
  activeItemIndex: number
  toggleControls?: boolean
  className?: string
}

function Tab({
  items,
  handleOnItemClick,
  activeItemIndex,
  toggleControls,
  className,
}: ComponentProps) {
  return (
    <div className={`flex gap-4 text-left ${className}`}>
      <div className="pointer-events-none absolute left-0 top-0 h-screen w-300px" />
      <div className="custom-secondary-scrollbar relative flex h-[50vh] flex-col gap-6 overflow-y-auto pl-[1.625rem] scroll-left">
        {items.map((item, index) => (
          <div key={`${index as number}`} className="shrink-0">
            <button
              className="w-auto text-white"
              onClick={(e) => {
                e.stopPropagation()
                handleOnItemClick(index)
              }}
              disabled={toggleControls}
              type="button"
            >
              <div
                className={`flex transform items-center font-semibold uppercase tracking-widest transition duration-300 ease-in-out ${
                  activeItemIndex === index
                    ? 'underline decoration-2 underline-offset-[5px]'
                    : ''
                }`}
              >
                {item.subText && (
                  <span className="mr-4 text-base font-semibold drop-shadow">
                    {item.subText}
                  </span>
                )}
                <span className="text-base font-semibold drop-shadow-70">
                  {item.text}
                </span>
              </div>
            </button>
          </div>
        ))}
      </div>
    </div>
  )
}

export default Tab
