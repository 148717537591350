import React from 'react'
import { Switch, useHistory, useLocation } from 'react-router-dom'

import FirebaseControlQuery, {
  getCurrentSessionKey,
} from '@src/firebase-util/firebase-control-query'
import ThreeDViewer from '@src/pages/snaploader'

import { TokenPayloadInterface } from '@store/actionSlices/token'
import { NavigationMenuKey, RouteKey } from '@store/constants'
import type {
  MenuItemInterface,
  ProjectIdentity,
  SessionMap,
} from '@store/types'

import MENU_ITEMS from '@components//navigation/menu-items'
import AbsoluteContainer from '@components/container/absolute-container'
import Navigation from '@components/navigation'
import { ProtectedRoutes, PublicRoutes } from '@components/route-guards'
import WithPageTransition from '@components/transition'

import ADThreeDView from '@pages/3d'
import PageNotFound from '@pages/404'
import { AreaViewApartments, AreaViewHouseAndLand } from '@pages/area-view'
import Brochure from '@pages/brochure'
import Design from '@pages/design'
import EnvisionVR from '@pages/envision-vr'
import ExternalViews from '@pages/external-views'
import InteractiveMap from '@pages/interactive-map'
import Location from '@pages/location'
import Login from '@pages/login'
import PanoramicView from '@pages/panoramic-view'
import {
  BuildingViewApartments,
  StageViewHouseAndLand,
} from '@pages/properties-view'
import Settings from '@pages/settings'
import Shortlisted from '@pages/shortlist'
import SnaploaderView from '@pages/snaploader-view'
import Teams from '@pages/teams'
import VideoGallery from '@pages/video-gallery'
import Vision from '@pages/vision'
import {
  LevelViewApartments,
  PrecinctViewHouseAndLand,
} from '@pages/zones-view'

import {
  EnvisionVRConfigurationInterface,
  SnaploaderConfigurationInterface,
} from '@api/config'

import { hasToken } from '@utilities/helper'
import useIdlePage from '@utilities/idle-page-util'
import useCurrentPage from '@utilities/location-util'

export interface RoutesProps {
  session: SessionMap
  projectIdentity: ProjectIdentity
  token: TokenPayloadInterface
  navigationSettings: MenuItemInterface[]
  envisionVRConfiguration: EnvisionVRConfigurationInterface
  snaploaderConfiguration: SnaploaderConfigurationInterface
}

function Routes({
  session,
  projectIdentity,
  token,
  navigationSettings,
  envisionVRConfiguration,
  snaploaderConfiguration,
}: RoutesProps) {
  const firebaseControlQuery = FirebaseControlQuery({
    ...projectIdentity,
    sessionKeys: projectIdentity.sessionId,
  })
  const history = useHistory()
  const location = useLocation()
  const [currentPage] = useCurrentPage()
  const [forceFullScreenMode, setForceFullScreenMode] =
    React.useState<boolean>()

  const disableNavigation = React.useMemo(
    () => !hasToken(token) || currentPage === '',
    [currentPage, token]
  )

  const createSessionFireStore = ({
    sessionId,
    projectName,
  }: ProjectIdentity) => {
    const currentSession = getCurrentSessionKey({
      sessionKeys: sessionId,
      projectName,
    })
    if (currentSession && projectName) {
      firebaseControlQuery.createSession({
        sessionId: currentSession,
        projectName,
      })
    }
  }

  const getRouteActiveStatus = (key: NavigationMenuKey): boolean => {
    const nav_items =
      navigationSettings.length > 0 ? navigationSettings : MENU_ITEMS
    const route = nav_items.find((item) => item.key === key)
    return route?.active ?? false
  }

  const { idlePageRoute, isRedirectableToIdlePage, redirectToIdlePage } =
    useIdlePage({
      projectName: projectIdentity.projectName,
      connected: !!session?.connected,
      activeRoute: session?.activeRoute,
      navigationSettings,
    })

  React.useEffect(() => {
    if (isRedirectableToIdlePage) {
      redirectToIdlePage()
    }
  }, [idlePageRoute])

  React.useEffect(() => {
    if (isRedirectableToIdlePage) {
      redirectToIdlePage()
      return
    }
    if (session) {
      const { activeRoute, connected } = session
      if (connected && projectIdentity.projectName) {
        const paths = location.pathname.split('/')
        if (paths[2] !== activeRoute) {
          history.push({
            pathname: activeRoute,
            state: { from: location.pathname },
          })
        }
      }
    }
  }, [session, projectIdentity])

  React.useEffect(() => {
    if (!session) {
      const sessionKey = getCurrentSessionKey({
        sessionKeys: projectIdentity.sessionId,
        projectName: projectIdentity.projectName,
      })
      if (sessionKey) {
        createSessionFireStore(projectIdentity)
      }
    }
  }, [session])

  return (
    <div>
      {disableNavigation ? null : (
        <Navigation forceFullScreenMode={forceFullScreenMode} />
      )}

      <WithPageTransition>
        <Switch location={location}>
          <ProtectedRoutes
            exact
            path={`/${projectIdentity.projectName}/${RouteKey.PANORAMIC}`}
            authenticationPath="/"
          >
            <AbsoluteContainer>
              <PanoramicView />
            </AbsoluteContainer>
          </ProtectedRoutes>
          <ProtectedRoutes
            exact
            path={`/${projectIdentity.projectName}/${RouteKey.EXTERNAL_VIEWS}`}
            authenticationPath="/"
            isRouteEnabled={getRouteActiveStatus(
              NavigationMenuKey.EXTERNAL_ROUTES
            )}
          >
            <ExternalViews />
          </ProtectedRoutes>
          <ProtectedRoutes
            exact
            path={`/${projectIdentity.projectName}/${RouteKey.AREA_VIEW}`}
            authenticationPath="/"
            isRouteEnabled={getRouteActiveStatus(NavigationMenuKey.RESIDENCES)}
          >
            <AbsoluteContainer>
              <AreaViewApartments />
            </AbsoluteContainer>
          </ProtectedRoutes>
          <ProtectedRoutes
            exact
            path={`/${projectIdentity.projectName}/${RouteKey.AREA_VIEW_HOUSE_AND_LAND}`}
            authenticationPath="/"
            isRouteEnabled={getRouteActiveStatus(NavigationMenuKey.RESIDENCES)}
          >
            <AbsoluteContainer>
              <AreaViewHouseAndLand />
            </AbsoluteContainer>
          </ProtectedRoutes>
          <ProtectedRoutes
            exact
            path={`/${projectIdentity.projectName}/${RouteKey.LEVEL_VIEW}`}
            authenticationPath="/"
            isRouteEnabled={getRouteActiveStatus(NavigationMenuKey.RESIDENCES)}
          >
            <AbsoluteContainer>
              <LevelViewApartments />
            </AbsoluteContainer>
          </ProtectedRoutes>
          <ProtectedRoutes
            exact
            path={`/${projectIdentity.projectName}/${RouteKey.PRECINCT}`}
            authenticationPath="/"
            isRouteEnabled={getRouteActiveStatus(NavigationMenuKey.RESIDENCES)}
          >
            <AbsoluteContainer>
              <PrecinctViewHouseAndLand />
            </AbsoluteContainer>
          </ProtectedRoutes>
          <ProtectedRoutes
            exact
            path={`/${projectIdentity.projectName}/${RouteKey.BUILDING}`}
            authenticationPath="/"
            isRouteEnabled={getRouteActiveStatus(NavigationMenuKey.RESIDENCES)}
          >
            <AbsoluteContainer>
              <BuildingViewApartments
                onFullScreenToggle={setForceFullScreenMode}
              />
            </AbsoluteContainer>
          </ProtectedRoutes>
          <ProtectedRoutes
            exact
            path={`/${projectIdentity.projectName}/${RouteKey.SHORTLIST}`}
            authenticationPath="/"
            isRouteEnabled={getRouteActiveStatus(NavigationMenuKey.SHORTLIST)}
          >
            <AbsoluteContainer>
              <Shortlisted />
            </AbsoluteContainer>
          </ProtectedRoutes>
          <ProtectedRoutes
            exact
            path={`/${projectIdentity.projectName}/${RouteKey.GALLERY}`}
            authenticationPath="/"
            isRouteEnabled={getRouteActiveStatus(NavigationMenuKey.GALLERY)}
          >
            <AbsoluteContainer>
              <Design />
            </AbsoluteContainer>
          </ProtectedRoutes>
          <ProtectedRoutes
            exact
            path={`/${projectIdentity.projectName}/${RouteKey.AREA_VIEW_3D}`}
            authenticationPath="/"
          >
            <AbsoluteContainer>
              <ThreeDViewer />
            </AbsoluteContainer>
          </ProtectedRoutes>
          <ProtectedRoutes
            exact
            path={`/${projectIdentity.projectName}/${RouteKey.SNAPLOADER_VIEW}`}
            authenticationPath="/"
            isRouteEnabled={snaploaderConfiguration.active}
          >
            <SnaploaderView />
          </ProtectedRoutes>
          <ProtectedRoutes
            exact
            path={`/${projectIdentity.projectName}/3d`}
            authenticationPath="/"
          >
            <AbsoluteContainer>
              <ADThreeDView />
            </AbsoluteContainer>
          </ProtectedRoutes>
          <ProtectedRoutes
            exact
            path={`/${projectIdentity.projectName}/${RouteKey.LOCATION}`}
            authenticationPath="/"
          >
            <AbsoluteContainer>
              <Location />
            </AbsoluteContainer>
          </ProtectedRoutes>
          <ProtectedRoutes
            exact
            path={`/${projectIdentity.projectName}/${RouteKey.VISION}`}
            authenticationPath="/"
          >
            <AbsoluteContainer>
              <Vision />
            </AbsoluteContainer>
          </ProtectedRoutes>
          <ProtectedRoutes
            exact
            path={`/${projectIdentity.projectName}/${RouteKey.TEAMS}`}
            authenticationPath="/"
            isRouteEnabled={getRouteActiveStatus(NavigationMenuKey.TEAM)}
          >
            <AbsoluteContainer>
              <Teams />
            </AbsoluteContainer>
          </ProtectedRoutes>
          <ProtectedRoutes
            exact
            path={`/${projectIdentity.projectName}/${RouteKey.BROCHURE}`}
            authenticationPath="/"
            isRouteEnabled={getRouteActiveStatus(NavigationMenuKey.BROCHURE)}
          >
            <AbsoluteContainer>
              <Brochure />
            </AbsoluteContainer>
          </ProtectedRoutes>
          <ProtectedRoutes
            exact
            path={`/${projectIdentity.projectName}/${RouteKey.VIDEO_GALLERY}`}
            authenticationPath="/"
            isRouteEnabled={getRouteActiveStatus(NavigationMenuKey.VIDEOS)}
          >
            <AbsoluteContainer>
              <VideoGallery />
            </AbsoluteContainer>
          </ProtectedRoutes>
          <ProtectedRoutes
            exact
            path={`/${projectIdentity.projectName}/${RouteKey.INTERACTIVE_MAP}`}
            authenticationPath="/"
            isRouteEnabled={getRouteActiveStatus(
              NavigationMenuKey.INTERACTIVE_MAP
            )}
          >
            <AbsoluteContainer>
              <InteractiveMap />
            </AbsoluteContainer>
          </ProtectedRoutes>
          <ProtectedRoutes
            exact
            path={`/${projectIdentity.projectName}/${RouteKey.SETTINGS}`}
            authenticationPath="/"
          >
            <AbsoluteContainer>
              <Settings />
            </AbsoluteContainer>
          </ProtectedRoutes>
          <ProtectedRoutes
            exact
            path={`/${projectIdentity.projectName}/${RouteKey.THREE_D_BUILDING}`}
            authenticationPath="/"
            isRouteEnabled={envisionVRConfiguration.active}
          >
            <EnvisionVR />
          </ProtectedRoutes>
          <ProtectedRoutes
            exact
            path={`/${projectIdentity.projectName}/${RouteKey.STAGES}`}
            authenticationPath="/"
            isRouteEnabled={getRouteActiveStatus(NavigationMenuKey.RESIDENCES)}
          >
            <StageViewHouseAndLand
              onFullScreenToggle={setForceFullScreenMode}
            />
          </ProtectedRoutes>
          <PublicRoutes
            strict
            exact
            path={`/${projectIdentity.projectName}/`}
            authenticatedPath={`/${projectIdentity.projectName}/${RouteKey.VISION}`}
          >
            <Vision />
          </PublicRoutes>
          <ProtectedRoutes
            exact
            path={`/${projectIdentity.projectName}/*`}
            authenticationPath="/"
          >
            <PageNotFound />
          </ProtectedRoutes>
          <PublicRoutes
            exact
            path="/"
            authenticatedPath={`/${projectIdentity.projectName}/${RouteKey.VISION}`}
          >
            <Login />
          </PublicRoutes>
          <PublicRoutes
            exact
            path="/:projectid"
            authenticatedPath={`/${projectIdentity.projectName}/${RouteKey.VISION}`}
          >
            <Login />
          </PublicRoutes>
          <PublicRoutes
            exact
            path="/:projectid/*"
            authenticatedPath={`/${projectIdentity.projectName}/${RouteKey.VISION}`}
          >
            <Login />
          </PublicRoutes>
        </Switch>
      </WithPageTransition>
    </div>
  )
}

export default Routes
