function throttleFn<T extends (...args: any[]) => void>(
  fn: T,
  delay: number
): T {
  let lastCall = 0
  let timeout: NodeJS.Timeout | null = null

  return ((...args: Parameters<T>) => {
    const now = Date.now()
    const remainingTime = delay - (now - lastCall)

    if (remainingTime <= 0) {
      if (timeout) {
        clearTimeout(timeout)
        timeout = null
      }
      lastCall = now
      fn(...args)
    } else if (!timeout) {
      timeout = setTimeout(() => {
        lastCall = Date.now()
        timeout = null
        fn(...args)
      }, remainingTime)
    }
  }) as T
}

export default throttleFn
