import { useProgress } from '@react-three/drei'
import React from 'react'

export interface BarInterface {
  width?: string
  color?: string
}

export interface LoadingScreenProps {
  icon?: string
  bg?: string
  bar?: BarInterface
}

export interface LoaderBgProps {
  bg: string
  classOverride?: string
}

export function LoaderBg({ bg, classOverride }: LoaderBgProps) {
  return (
    <div
      className={
        classOverride ||
        'absolute left-0 top-0 h-full w-full bg-cover bg-center bg-no-repeat blur-lg'
      }
      style={{ backgroundImage: `url(${bg})` }}
    ></div>
  )
}

function LoadingScreen({ icon, bg, bar }: LoadingScreenProps) {
  const { progress } = useProgress()
  return (
    <div className="relative flex h-full w-full items-center justify-center text-2xl">
      {bg && <LoaderBg bg={bg} />}
      <div className="z-10 rounded-lg bg-white bg-opacity-50 p-2.5">
        <img src={icon} width={bar?.width || '300'} alt="Loading Icon" />
        <div
          className="static rounded-md p-0.5 text-center text-[7px]"
          style={{
            backgroundColor: bar?.color || '#1594fe',
            width: `${progress.toFixed(0)}%`,
          }}
        ></div>
      </div>
    </div>
  )
}

export default LoadingScreen
