import React from 'react'
import { connect, useDispatch } from 'react-redux'
import { useHistory, useLocation } from 'react-router'
import { CSSTransition, SwitchTransition } from 'react-transition-group'

import {
  setByFlag,
  setMatrixData,
  setPrecinctList,
} from '@store/actionSlices/houseAndLand'
import { setFilter } from '@store/actionSlices/lotFilter'
import { setPanoramicData } from '@store/actionSlices/panoramic'
import {
  LotFilterInterface,
  PackageConfigurationInterface,
  ProjectIdentity,
  RootStateTypeExtra,
  SessionMap,
} from '@store/types'

import Container from '@components/container'
import DataHandler from '@components/data-handler'
import useGetHouseAndLandFilterData from '@components/filter-popup//use-get-house-and-filter-data'
import FilterPopup from '@components/filter-popup/filter-popup-house-and-land'
import { useIdleTimeHandler } from '@components/idle-time-handler/utils'
import { PannellumDataInterface } from '@components/showcase-pannellum/types'

import {
  selectMatrixDataFromResult,
  selectPrecinctListFromResult,
  useGetMatrixDataByPrecinctQuery,
  useGetPrecinctListQuery,
} from '@api/houseAndLand'
import {
  selectFromResult as selectFromPanoramicResult,
  useGetPanoramicQuery,
} from '@api/panoramic'
import {
  ActivePackageConfigurationType,
  HouseAndLandActiveKeyType,
  LotInterface,
  MatrixDataCollectionInterface,
  MatrixDataInterface,
  PackageInterface,
  PrecinctListItemInterface,
  StageCollectionInterface,
  StageInterface,
} from '@api/types/house-and-land-type'

import filterPackageSummary from '@utilities/adgroup-utilities/filter-package-summary'
import { HouseAndLandPackageOptionType } from '@utilities/adgroup-utilities/types/houseAndLand'
import {
  getQueryStringParams,
  mappedPackageOptionType,
} from '@utilities/helper'

import LotView from './lot-view'
import PackageInfoModal from './lot-view/package-info-modal'
import LotsSidePanel from './lots-side-panel'
import StageSkeleton from './stage-skeleton'
import StageView from './stage-view'

interface StagePagePropsInterface {
  matrixData: MatrixDataCollectionInterface
  activePrecinctId: string
  activeStageId: string
  activeLotId: string
  activePackageId: string
  precinctList: Array<PrecinctListItemInterface>
  panoramic: Array<PannellumDataInterface>
  session: SessionMap | undefined
  projectIdentity: ProjectIdentity
  lotFilter: LotFilterInterface
  availableStatusLabel: string
  onFullScreenToggle?: React.Dispatch<React.SetStateAction<boolean | undefined>>
  controls: {
    duration: number
    active: boolean
  }
}

const initialLastActiveOption = {
  type: '',
  id: '',
}

const AREA_VIEW = 'area-view-house-and-land'

function Stage({
  matrixData,
  activePrecinctId,
  activeStageId,
  activeLotId,
  activePackageId,
  precinctList,
  panoramic,
  session,
  projectIdentity,
  lotFilter,
  availableStatusLabel,
  onFullScreenToggle,
  controls,
}: StagePagePropsInterface) {
  const dispatch = useDispatch()
  const history = useHistory()
  const location = useLocation()
  const urlParams = React.useRef(getQueryStringParams(location.search))

  const [isConnected, setIsConnected] = React.useState(false)

  const isAppIdle = useIdleTimeHandler({
    isConnected,
    timeoutInSeconds: controls.duration,
    isIdleActive: controls.active,
  })
  const [activePrecinct, setActivePrecinct] =
    React.useState<string>(activePrecinctId)
  const [fullScreenToggle, setFullScreenToggle] = React.useState(false)
  const [lotSidePanelState, setLotSidePanelState] = React.useState(true)
  const [isFilterOpen, toggleFilter] = React.useState(false)
  const [packageInfoModalToggle, setPackageInfoModalToggle] =
    React.useState(false)
  const [lastActiveOption, setLastActiveOption] = React.useState<{
    type: string
    id: string
  }>(initialLastActiveOption)
  const [activePackageConfiguration, setActivePackageConfiguration] =
    React.useState<ActivePackageConfigurationType>()

  const activeMatrixData: MatrixDataInterface | undefined = React.useMemo(
    () => matrixData[activePrecinctId],
    [matrixData, activePrecinctId]
  )

  const activeStages: StageCollectionInterface | undefined =
    React.useMemo(() => {
      if (!activeMatrixData || !activeStageId) {
        return undefined
      }

      return activeMatrixData.stages || undefined
    }, [activeMatrixData, activeStageId])

  const activeStage: StageInterface | undefined = React.useMemo(() => {
    if (!activeStages || !activeStageId) {
      return undefined
    }

    return activeStages[activeStageId] || undefined
  }, [activeStageId, activeStages])

  const activePackages: Array<PackageInterface> = React.useMemo(() => {
    if (!activeMatrixData) {
      return []
    }
    return activeMatrixData.packages
  }, [activeMatrixData])

  const activeLot: LotInterface | undefined = React.useMemo(() => {
    if (!activeMatrixData || !activeStages || !activeStageId || !activeLotId) {
      return undefined
    }

    return activeStages[activeStageId].lots.find(
      (lotItem: LotInterface) =>
        lotItem.id === activeLotId || lotItem.name === activeLotId
    )
  }, [activeMatrixData, activeStages, activeStageId, activeLotId])

  const activePackage: PackageInterface | undefined = React.useMemo(() => {
    if (activePackages.length === 0 || !activePackageId) {
      return undefined
    }

    return activePackages.find(
      (item: PackageInterface) =>
        item.id === activePackageId || item.name === activePackageId
    )
  }, [activePackages, activePackageId])

  const { isFetching: isFetchingStages } = useGetHouseAndLandFilterData({
    projectName: projectIdentity.projectName,
    precinctId: activePrecinct,
  })

  const shouldSkipMatrixDataApi = React.useMemo(() => {
    if (!activePrecinctId) {
      return true
    }

    return matrixData[activePrecinctId] !== undefined
  }, [activePrecinctId, matrixData])

  const matrixDataPayload = useGetMatrixDataByPrecinctQuery(
    {
      projectName: projectIdentity.projectName,
      precinctIdOrLabel: activePrecinctId,
    },
    {
      selectFromResult: selectMatrixDataFromResult,
      skip: shouldSkipMatrixDataApi,
    }
  )

  const precinctListPayload = useGetPrecinctListQuery(
    { projectName: projectIdentity.projectName },
    {
      selectFromResult: selectPrecinctListFromResult,
    }
  )

  const panoramicPayload = useGetPanoramicQuery(
    { projectName: projectIdentity.projectName },
    { selectFromResult: selectFromPanoramicResult }
  )

  const handleActivePrecinctControl = (argPrecinctId: string) => {
    if (argPrecinctId !== activePrecinctId) {
      dispatch(
        setByFlag({
          flag: HouseAndLandActiveKeyType.ActivePrecinctId,
          value: argPrecinctId,
        })
      )
    }
  }

  const handleActiveStageControl = (argActiveStageId: string) => {
    if (argActiveStageId !== activeStageId) {
      dispatch(
        setByFlag({
          flag: HouseAndLandActiveKeyType.ActiveStageId,
          value: argActiveStageId,
        })
      )
    }
  }

  const handleActiveLotControl = (argActiveLotId: string) => {
    setTimeout(
      () => {
        dispatch(
          setByFlag({
            flag: HouseAndLandActiveKeyType.ActiveLotId,
            value: argActiveLotId,
          })
        )
      },
      argActiveLotId ? 1000 : 0
    )
  }

  const handleActivePackageControl = React.useCallback(
    (argActivePackageId: string) => {
      if (argActivePackageId !== activePackageId) {
        dispatch(
          setByFlag({
            flag: HouseAndLandActiveKeyType.ActivePackageId,
            value: argActivePackageId,
          })
        )
      }
    },
    [activePackageId]
  )

  const handleFullScreenToggle = (arg: boolean) => {
    setFullScreenToggle(arg)
    setLotSidePanelState(!arg)
    if (!isConnected) onFullScreenToggle?.(arg)
  }

  const handleLotConfigurationValueChange = React.useCallback(
    (data: PackageConfigurationInterface) =>
      setActivePackageConfiguration({
        [HouseAndLandPackageOptionType.FloorplanOption]:
          data.floorplanOption || '',
        [HouseAndLandPackageOptionType.Facade]: data.facade || '',
        [HouseAndLandPackageOptionType.FacadeColor]: data.facadeColor || '',
        [HouseAndLandPackageOptionType.InternalTheme]: data.internalTheme || '',
        [HouseAndLandPackageOptionType.InternalColorScheme]:
          data.internalColorScheme || '',
      }),
    []
  )

  const handleLotConfigurationOptionSelect = React.useCallback(
    (data: {
      propertyType: keyof PackageConfigurationInterface
      value: string
    }) => {
      const { propertyType, value: id } = data
      const type = mappedPackageOptionType(propertyType)
      setLastActiveOption({ type, id })
    },
    []
  )

  const handleLotConfigurationPropertyExpand = React.useCallback<
    (data: {
      propertyType: keyof PackageConfigurationInterface
      value: string
      expanded: boolean
    }) => void
  >((data) => {
    const { propertyType, expanded, value: id } = data
    const type = mappedPackageOptionType(propertyType)
    if (expanded && id) {
      setLastActiveOption({ type, id })
    }
  }, [])

  const handlePackageConfiguratiOnFirebaseChange = React.useCallback(
    (
      argActiveFloorplanOptionId: string,
      argActiveFacadeId: string,
      argActiveFacadeColorId: string,
      argActiveInternalThemeId: string,
      argActiveInternalColorSchemeId: string
    ) => {
      setActivePackageConfiguration({
        [HouseAndLandPackageOptionType.FloorplanOption]:
          argActiveFloorplanOptionId,
        [HouseAndLandPackageOptionType.Facade]: argActiveFacadeId,
        [HouseAndLandPackageOptionType.FacadeColor]: argActiveFacadeColorId,
        [HouseAndLandPackageOptionType.InternalTheme]: argActiveInternalThemeId,
        [HouseAndLandPackageOptionType.InternalColorScheme]:
          argActiveInternalColorSchemeId,
      })
    },
    []
  )

  const getFilterPackageSummary = () =>
    filterPackageSummary(
      matrixData[activePrecinctId],
      {
        ...lotFilter,
        storey: lotFilter.storey.map((item) => Number(item)),
      },
      availableStatusLabel
    )

  const filteredStages = React.useCallback(() => {
    const { apply, anyStage } = lotFilter

    if (apply && matrixData[activePrecinctId]) {
      const response = getFilterPackageSummary()
      if (!anyStage) {
        const currentStage = response?.stages?.[activeStageId]
        return currentStage ? [currentStage] : []
      }
      return Object.values(response?.stages ?? {})
    }

    return Object.values(activeStages || {})
  }, [lotFilter, activeMatrixData, activeStages, activePrecinctId])

  const filteredPackages = React.useCallback(() => {
    const { apply } = lotFilter

    if (apply && matrixData[activePrecinctId]) {
      const response = getFilterPackageSummary()
      return response?.packages || []
    }

    return activePackages
  }, [lotFilter, activePackages, activeMatrixData, activePrecinctId])

  const filteredLots = React.useCallback(() => {
    const { apply } = lotFilter

    if (apply && matrixData[activePrecinctId]) {
      const response = getFilterPackageSummary()
      const myStages = response?.stages || {}
      if (Object.values(myStages).length === 0) {
        return []
      }
      return myStages[activeStageId]?.lots || []
    }

    return activeStage?.lots || []
  }, [
    lotFilter,
    activeStageId,
    activeStage,
    activeMatrixData,
    activePrecinctId,
  ])

  React.useEffect(() => {
    const { panoramic: panoramicData, isLoaded } = panoramicPayload
    if (panoramic.length === 0 && isLoaded && panoramicData.length > 0) {
      dispatch(setPanoramicData(panoramicData))
    }
  }, [panoramicPayload])

  React.useEffect(() => {
    const { precinctList: precinctListData } = precinctListPayload
    if (precinctListData.length > 0 && precinctList.length === 0) {
      dispatch(setPrecinctList(precinctListData))
    }
  }, [precinctListPayload, precinctList])

  React.useEffect(() => {
    const {
      matrixData: matrixDataFromApi,
      isLoaded,
      isError,
    } = matrixDataPayload
    if (
      activePrecinctId &&
      isLoaded &&
      !isError &&
      Object.entries(matrixDataFromApi).length > 0 &&
      Object.entries(activeMatrixData || {}).length === 0
    ) {
      dispatch(setMatrixData({ [activePrecinctId]: matrixDataFromApi }))
    }
  }, [matrixDataPayload, activePrecinctId])

  React.useEffect(() => {
    if (!activePrecinctId) {
      history.push(AREA_VIEW)
      return
    }

    if (!activeStageId && activeStages) {
      dispatch(
        setByFlag({
          flag: HouseAndLandActiveKeyType.ActiveStageId,
          value: Object.values(activeStages)[0].label,
        })
      )
    }
  }, [activePrecinctId, activeStages, activeStageId])

  React.useEffect(() => {
    setLastActiveOption(initialLastActiveOption)
  }, [activePrecinctId, activeLotId, activeStageId])

  React.useEffect(() => {
    if (session) {
      const {
        connected,
        houseAndLand: {
          activePrecinctId: activePrecinctIdFirebase,
          activeStageId: activeStageIdFirebase,
          activeLotId: activeLotIdFirebase,
          activePackageId: activePackageIdFirebase,
          activeFloorplanOptionId: activeFloorplanOptionIdFirebase,
          activeFacadeId: activeFacadeIdFirebase,
          activeFacadeColorId: activeFacadeColorIdFirebase,
          activeInternalThemeId: activeInternalThemeIdFirebase,
          activeInternalColorSchemeId: activeInternalColorSchemeIdFirebase,
          filterPopup: filterPopupFirebase,
          lastActiveOption: {
            type: lastActiveOptionTypeFirebase,
            id: lastActiveOptionIdFirebase,
          },
          lotFilter: lotFilterFirestore,
        },
      } = session
      if (connected) {
        dispatch(setFilter(lotFilterFirestore))
        toggleFilter(filterPopupFirebase)
        handleActivePrecinctControl(activePrecinctIdFirebase)
        handleActiveLotControl(activeLotIdFirebase)
        handleActiveStageControl(activeStageIdFirebase)
        handleActivePackageControl(activePackageIdFirebase)
        handlePackageConfiguratiOnFirebaseChange(
          activeFloorplanOptionIdFirebase,
          activeFacadeIdFirebase,
          activeFacadeColorIdFirebase,
          activeInternalThemeIdFirebase,
          activeInternalColorSchemeIdFirebase
        )
        if (lastActiveOptionTypeFirebase) {
          setLastActiveOption({
            type: lastActiveOptionTypeFirebase,
            id: lastActiveOptionIdFirebase,
          })
        }
        setIsConnected(connected)
      }
    }
  }, [session?.connected, session?.houseAndLand])

  React.useEffect(
    () => () => onFullScreenToggle?.(undefined),
    [onFullScreenToggle]
  )

  React.useEffect(() => {
    if (!(urlParams.current?.lot && activeStage?.id)) {
      return
    }

    const matchedLot = activeStage.lots.find(
      (lotItem: LotInterface) => lotItem.name === urlParams.current?.lot
    )

    if (matchedLot) {
      dispatch(
        setByFlag({
          flag: HouseAndLandActiveKeyType.ActiveLotId,
          value: matchedLot.id,
        })
      )
      urlParams.current = {
        ...urlParams.current,
        lot: undefined,
      }
    }
  }, [activeStage?.id])

  React.useEffect(() => {
    if (
      !(
        urlParams.current?.precinct ||
        urlParams.current?.stage ||
        urlParams.current?.lot
      )
    ) {
      return
    }
    history.replace(location.pathname)
  }, [location.pathname])

  return (
    <Container fullScreenMode={fullScreenToggle || isAppIdle} className="flex">
      {!isConnected && (
        <div
          className={`h-full ${
            isAppIdle || !lotSidePanelState
              ? '!w-0 opacity-0'
              : 'w-[297px] min-w-[297px] opacity-100'
          } flex-grow-0 transition-all duration-300 ease-in-out`}
        >
          <LotsSidePanel
            stages={filteredStages()}
            packages={filteredPackages()}
            isLoaded={matrixDataPayload.isLoaded}
            filterPopupToggle={isFilterOpen}
            setFilterPopupToggle={(arg: boolean) => toggleFilter(arg)}
            onLotConfigurationValueChange={handleLotConfigurationValueChange}
            onLotConfigurationOptionSelect={handleLotConfigurationOptionSelect}
            onLotConfigurationPropertyExpand={
              handleLotConfigurationPropertyExpand
            }
          />

          <FilterPopup
            isOpen={isFilterOpen}
            toggle={toggleFilter}
            isFetchingStages={isFetchingStages}
            activePrecinct={activePrecinct}
            setActivePrecinct={setActivePrecinct}
          />
        </div>
      )}
      <DataHandler
        payload={{
          ...matrixDataPayload,
          data: matrixData[activePrecinctId],
          apiData: matrixDataPayload.matrixData,
        }}
        skeletonFrame={<StageSkeleton />}
      >
        <div
          className={`z-10 h-full flex-1 ${
            activeLotId ? 'transition-all duration-300 ease-in-out' : ''
          }`}
        >
          <SwitchTransition>
            <CSSTransition
              timeout={200}
              classNames="transition-fade-out"
              key={activeLotId && activeLot ? activeLot.id : 'stage'}
            >
              {activeLotId && activeLot ? (
                <LotView
                  key={activeLot.id}
                  lot={activeLot}
                  activePackage={activePackage}
                  fullScreenToggle={fullScreenToggle}
                  setFullScreenToggle={handleFullScreenToggle}
                  lastActiveOption={lastActiveOption}
                  activePackageConfiguration={activePackageConfiguration}
                  handlePackageInfoModal={() => setPackageInfoModalToggle(true)}
                  activeMatrixData={activeMatrixData}
                  getVideoPlayerState={handleFullScreenToggle}
                />
              ) : (
                <div className="relative h-full w-full">
                  <StageView
                    originalLots={activeStage ? activeStage.lots : []}
                    filteredLots={filteredLots()}
                    getVideoPlayerState={handleFullScreenToggle}
                    fullScreenToggle={fullScreenToggle}
                    setFullScreenToggle={handleFullScreenToggle}
                  />
                </div>
              )}
            </CSSTransition>
          </SwitchTransition>
        </div>
        <div
          className={`absolute bottom-0 z-10 hidden h-full w-full transition duration-300 ease-in-out ${
            packageInfoModalToggle ? 'translate-y-0' : 'translate-y-full'
          }`}
        >
          <PackageInfoModal
            closeAction={() => setPackageInfoModalToggle(false)}
          />
        </div>
      </DataHandler>
    </Container>
  )
}

export default connect(
  ({
    interactivePlan: { stages },
    projectIdentity,
    panoramic,
    firestore: { session, lightMap },
    houseAndLand: {
      matrixData,
      activePrecinctId,
      activeStageId,
      activeLotId,
      activePackageId,
      precinctList,
    },
    projectConfig: { statusLabels, propertyGalleryTab },
    lotFilter,
  }: RootStateTypeExtra) => ({
    stagePlan: stages,
    projectIdentity,
    matrixData,
    activePrecinctId,
    activePackageId,
    activeStageId,
    activeLotId,
    panoramic,
    precinctList,
    session,
    channels: lightMap?.channels || [],
    lotFilter,
    availableStatusLabel: statusLabels?.available,
    controls: propertyGalleryTab,
  })
)(Stage)
