import React from 'react'
import { connect, useDispatch } from 'react-redux'
import { Link } from 'react-router-dom'
import { CSSTransition, TransitionGroup } from 'react-transition-group'

import { SessionMap } from '@src/firebase-util/types'

import { setVision } from '@store/actionSlices/vision'
import { BucketSource, RootStateTypeExtra } from '@store/types'

import Container from '@components/container'
import DataHandler from '@components/data-handler'
import FadeContainer from '@components/fade-container'

import {
  GalleryData,
  VisionData,
  selectFromResult,
  useGetVisionQuery,
} from '@api/vision'

import { SettingsSvg } from '@svg/react'

import { CarouselProvider } from '@adUtilities/components/carousel-provider'
import { FileType } from '@adUtilities/constants/common'

import VisionSkeleton from './vision-skeleton'

interface VisionProps {
  projectName: string
  vision: VisionData
  session: SessionMap | undefined
  effectType: string
  autoPlayIntervalInSeconds: number
}

function Vision({
  projectName,
  vision,
  session,
  effectType,
  autoPlayIntervalInSeconds,
}: VisionProps) {
  const dispatch = useDispatch()

  const [toggleControls, setToggleControls] = React.useState(true)
  const [isConnected, setIsConnected] = React.useState(false)
  const [activeSlideIndex, setActiveSlideIndex] = React.useState(0)

  const visionPayload = useGetVisionQuery({ projectName }, { selectFromResult })

  const slides = React.useMemo(
    () =>
      vision.gallery.length > 0
        ? vision.gallery.map((item: GalleryData) => ({
            id: item.id,
            src: item.imageSource,
            bucketType: BucketSource.Legacy,
            fileType: FileType.Image,
            noSpliceUrl: false,
          }))
        : [],
    [vision]
  )

  const handleCarouselProviderClick = () => setToggleControls((state) => !state)

  React.useEffect(() => {
    if (session) {
      const {
        connected,
        vision: {
          galleryControlV2: { activeSlideIndex: activeSlideIndexFirebase },
        },
      } = session

      setIsConnected(connected)
      setToggleControls(!connected)
      setActiveSlideIndex(activeSlideIndexFirebase)
    }
  }, [session?.connected, session?.vision.galleryControlV2])

  React.useEffect(() => {
    const { gallery, title } = visionPayload
    if (vision.gallery.length === 0 && gallery.length > 0) {
      dispatch(
        setVision({
          gallery,
          title,
        })
      )
    }
  }, [visionPayload])

  return (
    <Container>
      <DataHandler
        payload={{
          ...visionPayload,
          data: vision.gallery,
        }}
        skeletonFrame={<VisionSkeleton />}
      >
        <TransitionGroup className="h-full">
          <CSSTransition classNames="transition-fade" timeout={500}>
            <CarouselProvider
              gallerySettings={{ effectType, autoPlayIntervalInSeconds }}
              slides={slides}
              activeSlideIndex={activeSlideIndex}
              showControl={false}
              mousewheel={!isConnected}
              slideImageClass="object-cover w-full"
              onContainerClick={handleCarouselProviderClick}
              showBlurredBackground={false}
            ></CarouselProvider>
          </CSSTransition>
        </TransitionGroup>
      </DataHandler>
      <FadeContainer show={!isConnected || toggleControls}>
        <Link
          to="settings"
          className="absolute bottom-5 left-5 z-20 opacity-[0.65]"
        >
          <SettingsSvg className="text-white drop-shadow-[3px_3px_4px_rgb(0_0_0_/_0.5)]" />
        </Link>
      </FadeContainer>
    </Container>
  )
}

const mapStateToProps = ({
  firestore: { session },
  projectIdentity: { projectName },
  vision,
  projectConfig: {
    gallerySettings: { type, intervalInSeconds },
  },
}: RootStateTypeExtra) => ({
  session,
  projectName,
  vision,
  effectType: type,
  autoPlayIntervalInSeconds: intervalInSeconds,
})

export default connect(mapStateToProps)(Vision)
