import { BucketSource, FileType } from './common'

export enum CarouselEffectType {
  Slide = 'slide',
  Fade = 'fade',
}

export enum CarouselControlType {
  Thumbnail = 'thumbnail',
  Label = 'label',
}

export interface SlideInterface {
  id: string
  label?: string
  src: string
  videoSource?: string
  thumbnail?: string
  fileType: FileType
  bucketType: BucketSource
  noSpliceUrl: boolean
}
