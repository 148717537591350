import React from 'react'
import { connect, useDispatch } from 'react-redux'
import { CSSTransition, SwitchTransition } from 'react-transition-group'

import { setByFlag, setPackages } from '@store/actionSlices/houseAndLand'
import { setUnitGallery as setLotGallery } from '@store/actionSlices/unitGallery'
import {
  BucketSource,
  LotGallery,
  UnitGalleryData as LotGalleryData,
  ProjectIdentity,
  RootStateTypeExtra,
  SessionMap,
  UnitGallery,
} from '@store/types'

import DataHandler from '@components/data-handler'
import {
  FULFILLED_STATUS,
  PENDING_STATUS,
} from '@components/data-handler/data-handler'
import IdleTimeHandler, {
  useIdleTimeHandler,
} from '@components/idle-time-handler'
import {
  PannellumDataInterface,
  PanoramaType,
} from '@components/showcase-pannellum/types'
import Skeleton from '@components/skeleton'

import {
  selectFromResult as selectFromExternalLinkResult,
  useGetExternalLinksByPropertyQuery,
} from '@api/external-links'
import {
  selectPackagesFromResult,
  useGetPackagesByIdQuery,
} from '@api/houseAndLand'
import {
  ActivePackageConfigurationType,
  FileInterface,
  GalleryInterface,
  HouseAndLandActiveKeyType,
  LotInterface,
  MatrixDataInterface,
  PackageInterface,
  PackageOptionsInterface,
} from '@api/types/house-and-land-type'
import {
  selectFromResult as selectFromLotGalleryResult,
  useGetUnitGalleryQuery as useGetLotGalleryQuery,
} from '@api/unit-gallery'

import { FileType } from '@utilities/adgroup-utilities/constants/common'
import { HouseAndLandPackageOptionType } from '@utilities/adgroup-utilities/types/houseAndLand'
import VideoPlayerFirebaseService from '@utilities/video-player-firebase-service'

import { ArrowSvg, LinkSvg } from '@svg/react'

import AssetHandler from '@adUtilities/asset-handler/asset-handler'
import CarouselProvider from '@adUtilities/components/carousel-provider/carousel-provider'
import VideoPlayer from '@adUtilities/components/video-player'
import { VideoPlayerRef } from '@adUtilities/components/video-player/video-player'
import { CarouselControlType } from '@adUtilities/constants/carousel'
import {
  BufferState,
  EndState,
  PlayState,
  SeekState,
  SyncState,
} from '@adUtilities/constants/player'

import Controls from './controls'
import DeselectedView from './deselected-view'
import LotViewSkeleton from './lot-view-skeleton'
import PackageInfoCard from './package-info-card'

interface LastActiveOption {
  type: string
  id: string
}

export interface LotViewProps {
  lot: LotInterface
  activePackage: PackageInterface | undefined
  packages: Array<PackageOptionsInterface>
  projectIdentity: ProjectIdentity
  session: SessionMap | undefined
  panoramic: Array<PannellumDataInterface>
  lotGalleryControlStyle: string
  lastActiveOption: LastActiveOption
  activePackageConfiguration: ActivePackageConfigurationType | undefined
  fullScreenToggle: boolean
  lotGalleries: Array<LotGalleryData>
  setFullScreenToggle: (arg: boolean) => void
  handlePackageInfoModal: () => void
  onlyShowLotGalleries: boolean
  activeMatrixData: MatrixDataInterface | undefined
  getVideoPlayerState: (arg: boolean) => void
  appIdleControls: {
    active: boolean
    duration: number
  }
  effectType: string
  autoPlayIntervalInSeconds: number
}

const noImageObject = {
  id: 'no-image',
  src: '',
  label: 'No Image',
  type: FileType.Image,
  noSpliceUrl: false,
}

function LotView({
  lot,
  activePackage,
  packages,
  projectIdentity,
  session,
  panoramic,
  lotGalleryControlStyle,
  lastActiveOption,
  activePackageConfiguration,
  fullScreenToggle,
  setFullScreenToggle,
  lotGalleries,
  handlePackageInfoModal,
  onlyShowLotGalleries,
  activeMatrixData,
  getVideoPlayerState,
  appIdleControls,
  effectType,
  autoPlayIntervalInSeconds,
}: LotViewProps) {
  const { projectId, projectName } = projectIdentity
  const dispatch = useDispatch()

  const [isConnected, setIsConnected] = React.useState(false)
  const [packageInfoToggle, setPackageInfoToggle] = React.useState(false)
  const [shouldShowSlideshowBtn, setShouldShowSlideShowBtn] =
    React.useState(false)
  const [deselected, setDeselectState] = React.useState(false)
  const [showExternalLinks, setShowExternalLinks] = React.useState(false)
  const [iframeLoaded, setIframeLoaded] = React.useState(false)
  const [activeLabel, setActiveLabel] = React.useState('')

  const isAppIdle = useIdleTimeHandler({
    isConnected,
    timeoutInSeconds: appIdleControls.duration,
    isIdleActive: appIdleControls.active,
  })

  const activePackageData: PackageOptionsInterface | undefined =
    React.useMemo(() => {
      if (packages.length === 0 || !activePackage) {
        return undefined
      }

      return packages.find(
        (item: PackageOptionsInterface) =>
          item.id === activePackage.id || item.name === activePackage.id
      )
    }, [packages, activePackage])

  const packagePayload = useGetPackagesByIdQuery(
    { projectName, lotId: lot.id },
    {
      selectFromResult: selectPackagesFromResult,
    }
  )

  const lotGalleryPayload = useGetLotGalleryQuery(
    { projectName, unitId: lot.name },
    {
      selectFromResult: selectFromLotGalleryResult,
      skip: packagePayload.status !== FULFILLED_STATUS,
    }
  )

  const getPackageOptionDetailById = React.useCallback(
    (
      type: HouseAndLandPackageOptionType,
      id: string
    ): { images: FileInterface[]; price: number } => {
      if (!activePackageData) {
        return {
          images: [],
          price: 0,
        }
      }

      const {
        FloorplanOption,
        Facade,
        FacadeColor,
        InternalTheme,
        InternalColorScheme,
      } = HouseAndLandPackageOptionType

      if (type === FloorplanOption) {
        const optionData = activePackageData[type]?.find(
          (item) => item.id === id
        )
        return {
          images: optionData?.images || [],
          price: optionData?.price || 0,
        }
      }

      if (type === Facade) {
        const optionData = activePackageData[type]?.find(
          (item) => item.id === id
        )
        return {
          images: optionData?.images || [],
          price: optionData?.price || 0,
        }
      }

      if (type === FacadeColor) {
        let images: FileInterface[] = []
        let price = 0
        activePackageData[Facade]?.forEach((item) => {
          item.facadeColors.forEach((facadeColor) => {
            if (facadeColor.id === id) {
              images = images.concat(facadeColor.images)
              price = facadeColor.price || 0
            }
          })
        })

        return { images, price }
      }

      if (type === InternalTheme) {
        const optionData = activePackageData[type]?.find(
          (item) => item.id === id
        )
        return {
          images: optionData?.images || [],
          price: optionData?.price || 0,
        }
      }

      if (type === InternalColorScheme) {
        let images: FileInterface[] = []
        let price = 0
        activePackageData[InternalTheme]?.forEach((item) => {
          item.internalColorSchemes.forEach((facadeColor) => {
            if (facadeColor.id === id) {
              images = images.concat(facadeColor.images)
              price = facadeColor.price || 0
            }
          })
        })
        return { images, price }
      }

      return { images: [], price: 0 }
    },
    [activePackageData]
  )

  const transformDataForGallery = (
    argData: Array<
      (FileInterface | GalleryInterface) & { noSpliceUrl?: boolean }
    >
  ): LotGallery[] =>
    argData
      .sort((a, b) => a.order - b.order)
      .map((item) => ({
        id: item.id,
        src: item.url,
        label: item.title || item.name,
        type: 'type' in item ? (item.type as FileType) : FileType.Image,
        thumbnail: 'thumbnail' in item ? (item.thumbnail as string) : '',
        noSpliceUrl: Boolean(item.noSpliceUrl),
      }))

  const galleryData: LotGallery[] = React.useMemo(() => {
    setDeselectState(false)
    const floorImagePath = `${projectId}/floorplans/preview`
    const viewImagePath = `${projectId}/views`

    if (!activePackageData && !onlyShowLotGalleries) {
      const galleryHolder: LotGallery[] = []

      if (lot?.assets?.floorImage) {
        const currentFloorImage = lot?.assets?.floorImage || {}
        galleryHolder.push(
          ...transformDataForGallery([
            {
              id: currentFloorImage.id,
              name: currentFloorImage.name,
              title: currentFloorImage.title,
              url: `/${floorImagePath}/${currentFloorImage.url}`,
              type: currentFloorImage.type,
              order: currentFloorImage.order,
              noSpliceUrl: true,
            },
          ])
        )
      }

      const myLotGalleries: Array<UnitGallery> =
        lotGalleries.find((item) => item.unit === lot.name)?.items || []
      if (myLotGalleries.length > 0) {
        galleryHolder.push(
          ...transformDataForGallery(
            [...myLotGalleries].map((item: UnitGallery) => ({
              id: item.id,
              name: item.label || '',
              title: item.label || '',
              url: item.src,
              type: item.type,
              thumbnail: item.thumbnail,
              order: 0,
            }))
          )
        )
      }

      if ((lot?.assets?.viewLinesImage?.length || 0) > 0) {
        const currentViewLinesImage = lot?.assets?.viewLinesImage || []
        galleryHolder.push(
          ...transformDataForGallery(
            [...currentViewLinesImage].map((item) => ({
              id: item.id,
              name: item.name,
              title: item.title,
              url: `/${viewImagePath}/${item.url}`,
              type: item.type,
              order: item.order,
              noSpliceUrl: true,
            }))
          )
        )
      }

      return galleryHolder.length > 0 ? galleryHolder : [noImageObject]
    }

    if (!activePackageData && onlyShowLotGalleries) {
      const myLotGalleries: Array<UnitGallery> =
        lotGalleries.find((item) => item.unit === lot.name)?.items || []
      if (myLotGalleries.length > 0) {
        return transformDataForGallery(
          [...myLotGalleries].map((item: UnitGallery) => ({
            id: item.id,
            name: item.label || '',
            title: item.label || '',
            url: item.src,
            type: item.type,
            thumbnail: item.thumbnail,
            order: 0,
          }))
        )
      }

      if (lot?.assets?.floorImage) {
        const currentFloorImage = lot?.assets?.floorImage || {}
        return transformDataForGallery([
          {
            id: currentFloorImage.id,
            name: currentFloorImage.name,
            title: currentFloorImage.title,
            url: `${floorImagePath}/${currentFloorImage.url}`,
            type: currentFloorImage.type,
            order: currentFloorImage.order,
            noSpliceUrl: true,
          },
        ])
      }

      return [noImageObject]
    }

    if (
      Object.values(HouseAndLandPackageOptionType).includes(
        lastActiveOption.type as HouseAndLandPackageOptionType
      )
    ) {
      if (!lastActiveOption.id) {
        setDeselectState(true)
        setActiveLabel('')
        return []
      }

      const myImages: FileInterface[] = getPackageOptionDetailById(
        lastActiveOption.type as HouseAndLandPackageOptionType,
        lastActiveOption.id
      ).images

      if (myImages.length === 0) {
        return [noImageObject]
      }

      return transformDataForGallery([...myImages])
    }

    const packageGalleries = activePackageData?.galleries || []

    if (packageGalleries.length === 0) {
      return [noImageObject]
    }

    return transformDataForGallery([...packageGalleries])
  }, [
    activePackageData,
    lastActiveOption,
    lotGalleries,
    onlyShowLotGalleries,
    lot?.id,
    projectId,
  ])

  const totalPrice = React.useMemo((): number => {
    const { price: lotPrice } = lot

    let totalPrice = lotPrice

    if (!activePackageData) {
      return totalPrice
    }

    if (activePackageData.housePrice) {
      totalPrice += activePackageData.housePrice
    }

    if (!activePackageConfiguration) {
      return totalPrice
    }

    const {
      FloorplanOption,
      Facade,
      FacadeColor,
      InternalTheme,
      InternalColorScheme,
    } = HouseAndLandPackageOptionType

    if (activePackageConfiguration[FloorplanOption]) {
      totalPrice +=
        getPackageOptionDetailById(
          FloorplanOption,
          activePackageConfiguration[FloorplanOption]
        ).price || 0
    }

    if (activePackageConfiguration[Facade]) {
      totalPrice +=
        getPackageOptionDetailById(Facade, activePackageConfiguration[Facade])
          .price || 0
    }

    if (activePackageConfiguration[FacadeColor]) {
      totalPrice +=
        getPackageOptionDetailById(
          FacadeColor,
          activePackageConfiguration[FacadeColor]
        ).price || 0
    }

    if (activePackageConfiguration[InternalTheme]) {
      totalPrice +=
        getPackageOptionDetailById(
          InternalTheme,
          activePackageConfiguration[InternalTheme]
        ).price || 0
    }

    if (activePackageConfiguration[InternalColorScheme]) {
      totalPrice +=
        getPackageOptionDetailById(
          InternalColorScheme,
          activePackageConfiguration[InternalColorScheme]
        ).price || 0
    }

    return totalPrice
  }, [lot, activePackageData, activePackageConfiguration])

  const hasPanoramicData: boolean = React.useMemo(() => {
    const foundData = panoramic.find(
      (item: PannellumDataInterface) =>
        item.type === PanoramaType.Lot &&
        item.panoramaGroup?.toString() === lot.name?.toString()
    )
    return foundData !== undefined
  }, [panoramic, lot])

  const getFilteredPackages = React.useCallback(
    (
      apiPackages: Array<PackageOptionsInterface>
    ): Array<PackageOptionsInterface> =>
      apiPackages.filter(
        (pItem: PackageOptionsInterface) =>
          !packages.find(
            (item: PackageOptionsInterface) => item.id === pItem.id
          )
      ),
    [packages]
  )

  const externalLinksPayload = useGetExternalLinksByPropertyQuery(
    { projectName, propertyId: lot?.id || '' },
    {
      skip: !lot?.id,
      selectFromResult: selectFromExternalLinkResult,
    }
  )

  const videoPlayerRef = React.useRef<VideoPlayerRef>(null)

  const videoPlayerFirebaseService = VideoPlayerFirebaseService({
    galleryName: 'propertyGallery',
    projectIdentity,
  })

  const [videoSyncKey, setVideoSyncKey] = React.useState<number | null>(null)
  const [activeSlideIndex, setActiveSlideIndex] = React.useState(0)
  const [autoPlayState, setAutoPlayState] = React.useState(false)
  const [playerState, setPlayerState] = React.useState(false)
  const [pinnedItemIndex, setPinnedItemIndex] = React.useState<number | null>(
    null
  )

  const slides = React.useMemo(
    () =>
      (galleryData || []).map((item) => ({
        id: item.id,
        label: item.label,
        src:
          item.type === FileType.Image && item.src
            ? item.src
            : item.thumbnail || '',
        thumbnail: item.thumbnail,
        originalSrc: item.src,
        bucketType: BucketSource.Legacy,
        fileType: item.type as FileType,
        noSpliceUrl: item.noSpliceUrl,
      })),
    [galleryData]
  )
  const activeSlide = React.useMemo(
    () => slides[activeSlideIndex],
    [slides, activeSlideIndex]
  )

  const hasVideoItems = React.useMemo(
    () => slides.some((item) => item.fileType === FileType.Video),
    [slides]
  )

  const videoSource = React.useMemo(
    () =>
      (activeSlide?.fileType === FileType.Video &&
        AssetHandler({
          url: activeSlide.originalSrc,
          type: activeSlide.bucketType,
        })) ||
      '',
    [slides, activeSlide]
  )

  const setSlideShowToggle = (status: boolean) => {
    setAutoPlayState((prevVal) => !prevVal)
    if (!status) {
      setActiveSlideIndex(0)
    }
  }

  const handleIframLoad = () => {
    setIframeLoaded(true)
  }

  const syncPinnedItemIndex = (
    pinnedItemIndexFirebase: number | null,
    activeSlideIndexFirebase: number,
    autoPlayStateFirebase: boolean
  ) => {
    if (pinnedItemIndexFirebase !== null) {
      if (pinnedItemIndexFirebase !== pinnedItemIndex) {
        setActiveSlideIndex(activeSlideIndexFirebase)
      } else if (pinnedItemIndexFirebase !== activeSlideIndexFirebase) {
        setActiveSlideIndex(pinnedItemIndexFirebase)
      }
      setPinnedItemIndex(pinnedItemIndexFirebase)
    } else {
      setAutoPlayState(autoPlayStateFirebase)
      setActiveSlideIndex(activeSlideIndexFirebase)
      setPinnedItemIndex(pinnedItemIndexFirebase)
    }
  }

  React.useEffect(() => {
    const { packages: packageData } = packagePayload
    const filteredPackages: Array<PackageOptionsInterface> =
      getFilteredPackages(packageData)
    if (packageData.length > 0 && filteredPackages.length > 0) {
      dispatch(setPackages(filteredPackages))
    }
  }, [packagePayload])

  React.useEffect(() => {
    const {
      unitGalleryData: lotGalleryData,
      isLoaded,
      isError,
    } = lotGalleryPayload
    if (
      isLoaded &&
      lotGalleryData.length > 0 &&
      !lotGalleries.find((item) => item.unit === lot.name) &&
      !isError
    ) {
      dispatch(setLotGallery({ unit: lot.name, items: lotGalleryData }))
    }
  }, [lotGalleryPayload])

  React.useEffect(() => {
    if (lot.name) {
      dispatch(
        setByFlag({
          flag: HouseAndLandActiveKeyType.ActiveLotName,
          value: lot.name,
        })
      )
    }
  }, [lot])

  React.useEffect(() => {
    if (session) {
      const {
        connected,
        houseAndLand: { lotInfoPopup, externalLinkPopup },
      } = session
      setIsConnected(connected)
      setPackageInfoToggle(lotInfoPopup)
      setShowExternalLinks(externalLinkPopup)
    }
  }, [session?.connected, session?.houseAndLand, session?.propertyGallery])

  React.useEffect(() => {
    setShouldShowSlideShowBtn(galleryData.length > 1)
  }, [galleryData])

  React.useEffect(() => {
    if (!session) {
      return
    }
    const {
      connected,
      propertyGallery: {
        galleryControlV2: {
          activeSlideIndex: activeSlideIndexFirebase,
          autoPlayState: autoPlayStateFirebase,
          pinnedItemIndex: pinnedItemIndexFirebase,
        },
        playerControlV2: {
          playerState: playerStateFirebase,
          loop: loopFirebase,
          muted: mutedFirebase,
          volume: volumeFirebase,
          seekToValueInSeconds: seekToValueInSecondsFirebase,
          videoSyncKey: videoSyncKeyFirebase,
        },
      },
    } = session

    setIsConnected(connected)

    syncPinnedItemIndex(
      pinnedItemIndexFirebase,
      activeSlideIndexFirebase,
      autoPlayStateFirebase
    )

    videoPlayerRef.current?.updatePlayerState(playerStateFirebase)
    videoPlayerRef.current?.toggleLoop(loopFirebase)
    videoPlayerRef.current?.toggleMute(mutedFirebase)
    videoPlayerRef.current?.updateVolume(volumeFirebase / 10)
    if (seekToValueInSecondsFirebase !== null) {
      videoPlayerRef.current?.seekTo(seekToValueInSecondsFirebase)
    }
    if (videoSyncKeyFirebase !== null) {
      setVideoSyncKey(videoSyncKeyFirebase)
    }
  }, [session?.connected, session?.propertyGallery])
  const handleOnPlay = async (args: PlayState) => {
    await videoPlayerFirebaseService.updateStateOnPlay(args)
  }
  const handleOnBuffer = async (args: BufferState) => {
    await videoPlayerFirebaseService.updateStateOnBuffer(args)
  }
  const handleOnSeek = async (args: SeekState) => {
    await videoPlayerFirebaseService.updateStateOnSeek(args)
  }
  const handleOnSync = async (args: SyncState) => {
    await videoPlayerFirebaseService.updateStateOnSyncRequest(args)
  }
  const handleOnEnd = async (args: EndState) => {
    await videoPlayerFirebaseService.updateStateOnEnd(args)
  }

  const dataHandlerPayload = React.useMemo(() => {
    if (packagePayload.status === FULFILLED_STATUS) {
      const packageCountOfThisLot = activeMatrixData
        ? activeMatrixData?.packages?.filter((item: PackageInterface) =>
            item.lots?.includes(lot.id)
          )?.length
        : 0

      let data
      if (packageCountOfThisLot > 0) {
        data = packages
      } else if (lotGalleryPayload?.unitGalleryData?.length > 0) {
        data = lotGalleryPayload.unitGalleryData
      } else {
        data = lot?.id
      }

      return {
        ...lotGalleryPayload,
        status:
          lotGalleryPayload.unitGalleryData?.length > 0
            ? (lotGalleries.length && FULFILLED_STATUS) || PENDING_STATUS
            : lotGalleryPayload.status,
        data,
      }
    }

    return {
      ...packagePayload,
      data: packages,
    }
  }, [
    lotGalleryPayload,
    packagePayload,
    packages,
    activeMatrixData,
    lot?.id,
    lotGalleries?.length,
  ])

  return (
    <DataHandler
      payload={{
        ...dataHandlerPayload,
        status:
          isConnected && !session?.houseAndLand?.isDataLoaded
            ? PENDING_STATUS
            : dataHandlerPayload.status,
      }}
      skeletonFrame={<LotViewSkeleton />}
    >
      <div className="relative flex h-full w-full bg-gray-700/50">
        <IdleTimeHandler disable={isConnected}>
          {!isConnected && (
            <div className="absolute right-8 top-7 z-10">
              <Controls
                hasPanoramicData={hasPanoramicData}
                fullScreenToggle={fullScreenToggle}
                setFullScreenToggle={setFullScreenToggle}
                packageInfoToggle={packageInfoToggle}
                slideShowToggle={autoPlayState}
                shouldShowSlideshowBtn={shouldShowSlideshowBtn}
                setSlideShowToggle={(arg: boolean) => {
                  setSlideShowToggle(arg)
                  setFullScreenToggle(arg)
                }}
                setPackageInfoToggle={setPackageInfoToggle}
                isVideoPlaying={playerState}
              />

              {!autoPlayState &&
                externalLinksPayload?.externalLinks?.length > 0 && (
                  <div className="mt-4 flex justify-end">
                    <button
                      type="button"
                      className="lot-view-control-icon cursor-pointer"
                      onClick={() => setShowExternalLinks(!showExternalLinks)}
                    >
                      <LinkSvg className="h-5 w-5" />
                    </button>
                  </div>
                )}
            </div>
          )}
          <div
            className={`absolute bottom-0 z-10 h-auto w-full bg-shadow-horizontal transition duration-300 ease-in ${
              packageInfoToggle && !autoPlayState && !playerState
                ? 'translate-y-0'
                : 'translate-y-full'
            }`}
          >
            <div
              className={`flex h-full transform items-stretch justify-between duration-200 ease-in ${
                fullScreenToggle ? 'pb-2 pl-2' : 'pb-5 pl-5'
              } `}
            >
              <PackageInfoCard
                lot={lot}
                packageInfo={activePackage}
                totalPrice={totalPrice}
                handlePackageInfoModal={handlePackageInfoModal}
                connected={isConnected}
              />

              {isConnected && (
                <div className="self-end px-16 text-[22px] font-medium leading-5 text-white drop-shadow-40">
                  {activeLabel}
                </div>
              )}
            </div>
          </div>
        </IdleTimeHandler>
        <SwitchTransition>
          <CSSTransition
            timeout={200}
            classNames="transition-fade-out"
            key={deselected ? 'deselected' : galleryData?.[0]?.id}
          >
            {deselected ? (
              <DeselectedView type={lastActiveOption.type} />
            ) : (
              <div className="absolute inset-0">
                <CarouselProvider
                  gallerySettings={{ effectType, autoPlayIntervalInSeconds }}
                  slides={slides}
                  activeSlideIndex={activeSlideIndex}
                  showControl={!isConnected && !autoPlayState}
                  controlType={lotGalleryControlStyle as CarouselControlType}
                  mousewheel={!isConnected && !autoPlayState}
                  getCurrentSlideIndex={setActiveSlideIndex}
                  autoPlay={autoPlayState}
                  controlPosition="right"
                  isAppIdle={isAppIdle}
                />
              </div>
            )}
          </CSSTransition>
        </SwitchTransition>
        {hasVideoItems ? (
          <div
            className={`absolute inset-0 ${
              videoSource ? 'visible' : 'invisible'
            }`}
          >
            <div className="flex h-full w-full items-center justify-center">
              <VideoPlayer
                key={`lot-gallery-video-player-${isConnected.toString()}`}
                ref={videoPlayerRef}
                sources={[videoSource]}
                activeIndex={0}
                disablePlayButton={isConnected || autoPlayState}
                showLoopOption={false}
                videoSyncKey={videoSyncKey}
                showControls={!isConnected}
                getPlayerState={(state: boolean) => {
                  getVideoPlayerState(state)
                  setPlayerState(state)
                }}
                {...(isConnected && {
                  updateStateOnPlay: handleOnPlay,
                  updateStateOnBuffer: handleOnBuffer,
                  updateStateOnSeek: handleOnSeek,
                  updateStateOnSyncRequest: handleOnSync,
                  updateStateOnEnd: handleOnEnd,
                })}
                isAppIdle={isAppIdle}
              />
            </div>
          </div>
        ) : null}
      </div>

      {showExternalLinks && (
        <div
          className={`fixed left-0 z-30 w-screen bg-secondaryColour ${
            isConnected || fullScreenToggle
              ? 'top-0 h-screen'
              : 'top-9 h-page-container overflow-hidden rounded-t-3xl'
          }`}
        >
          {!isConnected && (
            <button
              type="button"
              className="absolute left-5 top-5 z-3 rounded bg-white p-1 drop-shadow-40"
              onClick={() => {
                setShowExternalLinks(false)
                setIframeLoaded(false)
              }}
            >
              <ArrowSvg className="h-8 w-8" strokeColor="#000" />
            </button>
          )}

          {!iframeLoaded && <Skeleton />}

          <iframe
            key={externalLinksPayload?.externalLinks?.[0]?.linkUrl}
            className={`relative z-2 h-full w-full overflow-hidden border-0 ${
              !iframeLoaded ? 'opacity-0' : ''
            }`}
            title={externalLinksPayload?.externalLinks?.[0]?.label}
            src={externalLinksPayload?.externalLinks?.[0]?.linkUrl}
            height="100%"
            width="100%"
            onLoad={handleIframLoad}
          />
        </div>
      )}
    </DataHandler>
  )
}

export default connect(
  ({
    projectIdentity,
    houseAndLand: { packages },
    firestore: { session },
    panoramic,
    projectConfig: {
      propertyGalleryControlStyle: lotGalleryControlStyle,
      onlyShowPropertyGalleries,
      propertyGalleryTab,
      gallerySettings: { type, intervalInSeconds },
    },
    unitGallery,
  }: RootStateTypeExtra) => ({
    projectIdentity,
    packages,
    session,
    panoramic,
    lotGalleryControlStyle,
    onlyShowLotGalleries: onlyShowPropertyGalleries,
    lotGalleries: unitGallery,
    appIdleControls: propertyGalleryTab,
    effectType: type,
    autoPlayIntervalInSeconds: intervalInSeconds,
  })
)(LotView)
