enum NavigationMenuKey {
  RESIDENCES = 'residences',
  INTERACTIVE_MAP = 'interactive-map',
  VIDEOS = 'videos',
  GALLERY = 'gallery',
  BROCHURE = 'brochure',
  TEAM = 'team',
  EXTERNAL_ROUTES = 'external-routes',
  SHORTLIST = 'shortlist',
  ADTHREED = '3d',
}

enum RouteKey {
  VISION = 'vision',
  AREA_VIEW = 'area-view',
  LEVEL_VIEW = 'level-view',
  BUILDING = 'building',
  AREA_VIEW_HOUSE_AND_LAND = 'area-view-house-and-land',
  PRECINCT = 'precinct',
  STAGES = 'stages',
  INTERACTIVE_MAP = 'interactive-map',
  VIDEO_GALLERY = 'video-gallery',
  GALLERY = 'gallery',
  TEAMS = 'teams',
  BROCHURE = 'brochure',
  EXTERNAL_VIEWS = 'external-views',
  SHORTLIST = 'shortlist',
  PANORAMIC = 'panoramic',
  PANORAMIC_VIEW = 'panoramic-view',
  AREA_VIEW_3D = 'areaview-3d',
  SNAPLOADER_VIEW = 'snaploader-view',
  THREE_D_BUILDING = '3d-building',
  SETTINGS = 'settings',
  LOCATION = 'location',
  ADTHREED = '3d',
}

enum SortOrder {
  ASC = 'asc',
  DESC = 'desc',
}

export enum ProjectStatus {
  Archived = 'archived',
  Sold = 'sold',
  Available = 'available',
}

export { NavigationMenuKey, RouteKey, SortOrder }
