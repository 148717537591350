import FirebaseControlQuery from '@src/firebase-util/firebase-control-query'

import type { ProjectIdentity } from '@store/types'

import {
  BufferState,
  EndState,
  PlayState,
  SeekState,
  SyncState,
} from '@adUtilities/constants/player'

interface ComponentProps {
  galleryName: 'floorGallery' | 'propertyGallery' | 'videoGallery'
  projectIdentity: ProjectIdentity
}

const VideoPlayerFirebaseService = ({
  galleryName,
  projectIdentity,
}: ComponentProps) => {
  const firebaseControlQuery = FirebaseControlQuery({
    projectName: projectIdentity.projectName,
    sessionKeys: projectIdentity.sessionId,
  })

  const updateStateOnPlay = async (args: PlayState) => {
    await firebaseControlQuery
      .update({
        [`${galleryName}.playerControlV2.scrubbarTimerState`]: true,
        [`${galleryName}.playerControlV2.videoLengthInSeconds`]:
          args.videoLengthInSeconds,
        [`${galleryName}.playerControlV2.currentVideoPositionInSeconds`]:
          args.currentVideoPositionInSeconds.toFixed(2),
      })
      .catch(() => console.error('Failed updateStateOnPlay'))
  }

  const updateStateOnBuffer = async (args: BufferState) => {
    if (args.isBuffering) {
      await firebaseControlQuery
        .update({
          [`${galleryName}.playerControlV2.scrubbarTimerState`]: false,
          [`${galleryName}.playerControlV2.isBuffering`]: true,
        })
        .catch(() => console.error('Failed updateStateOnBuffer'))
      return
    }

    await firebaseControlQuery
      .update({
        [`${galleryName}.playerControlV2.scrubbarTimerState`]: true,
        [`${galleryName}.playerControlV2.currentVideoPositionInSeconds`]: (
          args.currentVideoPositionInSeconds || 0
        ).toFixed(2),
        [`${galleryName}.playerControlV2.isBuffering`]: false,
      })
      .catch(() => console.error('Failed updateStateOnBuffer'))
  }
  const updateStateOnSeek = async (args: SeekState) => {
    await firebaseControlQuery
      .update({
        [`${galleryName}.playerControlV2.scrubbarTimerState`]: true,
        [`${galleryName}.playerControlV2.seekToValueInSeconds`]: null,
        [`${galleryName}.playerControlV2.currentVideoPositionInSeconds`]:
          args.currentVideoPositionInSeconds.toFixed(2),
      })
      .catch(() => console.error('Failed updateStateOnSeek'))
  }

  const updateStateOnSyncRequest = async (args: SyncState) => {
    await firebaseControlQuery
      .update({
        [`${galleryName}.playerControlV2.scrubbarTimerState`]: true,
        [`${galleryName}.playerControlV2.videoSyncKey`]: null,
        [`${galleryName}.playerControlV2.videoLengthInSeconds`]:
          args.videoLengthInSeconds,
        [`${galleryName}.playerControlV2.currentVideoPositionInSeconds`]:
          args.currentVideoPositionInSeconds.toFixed(2),
      })
      .catch(() => console.error('Failed updateStateOnSyncRequest'))
  }

  const updateStateOnEnd = async (args: EndState) => {
    await firebaseControlQuery
      .update({
        [`${galleryName}.galleryControlV2.activeSlideIndex`]:
          args.videoLoopIndex,
      })
      .catch(() => console.error('Failed updateStateOnEnd'))
  }

  return {
    updateStateOnPlay,
    updateStateOnBuffer,
    updateStateOnSeek,
    updateStateOnSyncRequest,
    updateStateOnEnd,
  }
}

export default VideoPlayerFirebaseService
