import React from 'react'
import { useDispatch } from 'react-redux'
import { useHistory } from 'react-router'

import { BuildingInterface, setByFlag } from '@store/actionSlices/building'

import {
  EnvisionVRConfigurationInterface,
  SnaploaderConfigurationInterface,
} from '@api/config'

import { hasEnvisionVR, hasSnaploader } from '@utilities/helper'

import { ArrowSvg } from '@svg/react'

interface ComponentProps {
  building: BuildingInterface
  projectName?: string
  snaploaderConfiguration: SnaploaderConfigurationInterface
  envisionVRConfiguration: EnvisionVRConfigurationInterface
  hasThreeDViewer?: boolean
}

function BackButton({
  building,
  snaploaderConfiguration,
  envisionVRConfiguration,
  hasThreeDViewer,
  projectName,
}: ComponentProps) {
  const dispatch = useDispatch()
  const history = useHistory()

  const AREA_VIEW_3D = 'areaview-3d'
  const LEVEL_VIEW = 'level-view'
  const SNAPLOADER_VIEW = 'snaploader-view'
  const ENVISION_VE_VIEW = '3d-building'

  const handleBackNav = () => {
    if (building.activeUnit) {
      dispatch(setByFlag({ flag: 'activeUnit', value: '' }))
      return
    }
    if (building.activeLevel) {
      dispatch(
        setByFlag({ flag: 'activeLevel', value: building.levels[0].level })
      )

      if (projectName === 'the-windermere') {
        history.push('3d')
        return
      }

      if (hasSnaploader(snaploaderConfiguration)) {
        history.push(SNAPLOADER_VIEW)
        return
      }
      if (hasEnvisionVR(envisionVRConfiguration)) {
        history.push(ENVISION_VE_VIEW)
        return
      }
      if (hasThreeDViewer) {
        history.push(AREA_VIEW_3D)
        return
      }
      history.push(LEVEL_VIEW)
    }
  }

  return (
    <button
      className="rounded-full border border-zinc-400 p-1"
      type="button"
      onClick={() => handleBackNav()}
    >
      <ArrowSvg className="h-6 w-6 stroke-zinc-400" rotate="left" />
    </button>
  )
}

export default BackButton
