import React from 'react'

import { SvgProps } from './types'

function InfoCircleSvg({
  className,
  size,
  styles,
  fill,
  width,
  height,
}: SvgProps) {
  const { innerWidth } = window
  const defaultWidth = innerWidth > 1024 ? 'xs' : 's'
  const sizeKey = typeof size === 'undefined' ? defaultWidth : size

  const scale = {
    xl: 3.0,
    lg: 2.5,
    m: 1.0,
    s: 0.8,
    xs: 0.6,
  }

  return (
    <svg
      className={className}
      style={{ ...styles, ...{ transform: `scale(${scale[sizeKey]})` } }}
      width={width || 50}
      height={height || 50}
      viewBox="0 0 50 50"
    >
      <path
        d="M 24 48 C 10.745454545454546 48 0 37.25454545454545 0 24 C 0 10.745454545454546 10.745454545454546 0 24 0 C 37.25454545454545 0 48 10.745454545454546 48 24 C 48 37.25454545454545 37.25454545454545 48 24 48 Z M 23.999999999999993 43.63636363636364 C 34.84486417849557 43.63636363636364 43.63636363636364 34.844864178495584 43.63636363636364 23.999999999999993 C 43.63636363636364 13.155135821504416 34.844864178495584 4.363636363636364 23.999999999999993 4.36363636363636 C 13.155135821504416 4.36363636363636 4.363636363636364 13.155135821504409 4.36363636363636 23.999999999999993 C 4.36363636363636 34.84486417849557 13.155135821504409 43.63636363636364 23.999999999999993 43.63636363636364 Z M 21.81818181818182 19.636363636363633 L 26.18181818181818 19.636363636363633 L 26.18181818181818 37.09090909090909 L 21.81818181818182 37.09090909090909 Z M 24 16.581818181818186 C 22.43351961866175 16.581818181818186 21.163636363636364 15.3119349267928 21.163636363636364 13.74545454545455 C 21.163636363636364 12.1789741641163 22.433519618661744 10.90909090909091 24 10.90909090909091 C 25.566480381338256 10.90909090909091 26.836363636363647 12.1789741641163 26.836363636363647 13.74545454545455 C 26.836363636363647 15.3119349267928 25.56648038133825 16.581818181818186 24 16.581818181818186 Z"
        fill={fill || 'hsl(0, 0%, 100%)'}
      ></path>
    </svg>
  )
}

export default InfoCircleSvg
