import { UseQueryStateResult } from '@reduxjs/toolkit/dist/query/react/buildHooks.d'
import { createApi } from '@reduxjs/toolkit/query/react'

import {
  HouseAndLandConfigurationInterface,
  PriceRange,
  PropertyConfigurationInterface,
} from '@store/types'

import baseQueryInterceptor from '@api/base-query-interceptor'
import { ApiResponse } from '@api/types/api-response.type'

import { ProjectType } from '@utilities/adgroup-utilities/constants/common'

export interface LogoInterface {
  visible: boolean
  url: string
}

export interface MarkerColourInterface {
  available: string
  sold: string
  area: string
  levels: string
}

export interface SnaploaderConfigurationInterface {
  active: boolean
  sceneSnapId: string
  sceneModelId: string
}

export interface EnvisionVRConfigurationInterface {
  active: boolean
  url: string
  zoom: number
  rotateCameraBy: number
}
export interface EnvisionVRLevelsByBlockInterface {
  [key: string]: Array<string>
}

export interface ThemeConfigInterface {
  font: string
  mainColour: string
  tertiaryColour: string
  neutralColour: string
  secondaryColour: string
}

export interface GallerySettingsInterface {
  type: string
  intervalInSeconds: number
}
export interface PropertyGalleryTabInterface {
  active: boolean
  duration: number
}

export type StatusLabels = {
  [key: string]: string
}

export type StatusColors = {
  [key: string]: string
}

export interface IdlePageInterface {
  active: boolean
  page: string
}

export interface VendorsInterface {
  envisionVr: EnvisionVRConfigurationInterface
  snaploader: SnaploaderConfigurationInterface
  threeDViewer: {
    active: string
    url: string
  }
}

export interface ConfigData {
  hideFilter: boolean
  hideInfoPanelAspect: boolean
  hidePropertyInfoName: boolean
  hideSoldPrice: boolean
  idlePage: IdlePageInterface
  logo: LogoInterface
  longhand: boolean
  onlyShowPropertyGalleries: boolean
  propertyGalleryControlStyle: string
  propertyGalleryTab: PropertyGalleryTabInterface
  propertyPrefix: string
  showPrice: boolean
  showcaseActive: boolean
  sidePanelTitle: string
}

export interface NavigationConfigInterface {
  active: boolean
  key: string
  label: string
  order: number
  sortable: boolean
}

export interface ConfigPayload {
  canonicalName: string
  id: string
  status: string
  title: string
  type: ProjectType
  uuid: string
  config: {
    disableSoldProperty: boolean
    lightUpBuildingModelOnFilter: boolean
    filter: {
      prices: PriceRange
      aspects: Array<string>
      unitTypes: Array<string>
      propertyConfigurationSettings: PropertyConfigurationInterface
      houseAndLandConfig: HouseAndLandConfigurationInterface
    }
    gallerySettings: GallerySettingsInterface
    generalConfig: ConfigData
    markerColourSettings: MarkerColourInterface
    statusColors: StatusColors
    statusLabels: StatusLabels
    vendors: VendorsInterface
  }
}

type ConfigType = {
  projectName: string
}

export const configApi = createApi({
  reducerPath: 'configApi',
  baseQuery: baseQueryInterceptor,
  endpoints: (builder) => ({
    getConfig: builder.query<ApiResponse<ConfigPayload>, ConfigType>({
      query: (params: ConfigType) => ({
        url: `/v3/${params.projectName}/configs`,
        method: 'get',
      }),
    }),
  }),
})

export const selectFromResult = ({
  data,
  isLoading,
  status,
  error,
}: UseQueryStateResult<any, any>): {
  configInfo: ConfigData
  markerColour: MarkerColourInterface
  threeDViewer: { url: string; active: boolean }
  snaploaderConfiguration: SnaploaderConfigurationInterface
  envisionVRConfiguration: EnvisionVRConfigurationInterface
  prices: PriceRange
  aspects: Array<string>
  unitTypes: Array<string>
  propertyConfig: PropertyConfigurationInterface
  houseAndLandConfig: HouseAndLandConfigurationInterface
  gallerySettings: GallerySettingsInterface
  disableSoldProperty: boolean
  type: ProjectType
  statusLabels: StatusLabels
  statusColors: StatusColors
  lightUpBuildingModelOnFilter: boolean

  isLoaded: boolean
  hasError: boolean
  status: string
} => ({
  configInfo: data?.data?.config?.generalConfig || {},
  markerColour: data?.data?.config?.markerColorSettings || {},
  threeDViewer: data?.data?.config?.vendors?.threeDViewer || {},
  snaploaderConfiguration: data?.data?.config?.vendors?.snaploader || {},
  envisionVRConfiguration: data?.data?.config?.vendors?.envisionVr || {},
  prices: data?.data?.config?.filter?.prices || {},
  aspects: data?.data?.config?.filter?.aspects || [],
  unitTypes: data?.data?.config?.filter?.unitTypes || [],
  propertyConfig:
    data?.data?.config?.filter?.propertyConfigurationSettings || {},
  houseAndLandConfig: data?.data?.config?.filter?.houseAndLandConfig || {},
  gallerySettings: data?.data?.config?.gallerySettings || {},
  disableSoldProperty: data?.data?.config?.disableSoldProperty || false,
  type: data?.data?.type || ProjectType.Apartment,
  statusLabels: data?.data?.config?.statusLabels || {},
  statusColors: data?.data?.config?.statusColors || {},
  lightUpBuildingModelOnFilter:
    data?.data?.config?.lightUpBuildingModelOnFilter || false,

  isLoaded: !isLoading,
  hasError: error !== undefined,
  status,
})

export const { useGetConfigQuery, useLazyGetConfigQuery } = configApi
