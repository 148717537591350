import React from 'react'

import AssetHandler from '@adUtilities/asset-handler/asset-handler'
import { SlideInterface } from '@adUtilities/constants/carousel'
import { FileType } from '@adUtilities/constants/common'
import useImageURLValidator from '@adUtilities/hooks/image-url-validator'

import DefaultSlideSkeleton from './default-slide-skeleton'

const fallbackImageURL = process.env.REACT_APP_PLACEHOLDER_IMAGE_URL

interface ComponentProps {
  index: number
  slide: SlideInterface
  showBlurredBackground: boolean
  imageClass?: string
  getSlideLoadingState?: (index: number) => void
}

function Slide({
  index,
  slide,
  showBlurredBackground,
  imageClass = 'object-contain w-full',
  getSlideLoadingState,
}: ComponentProps) {
  const assetURL = React.useMemo(
    () =>
      AssetHandler({
        url: slide.src,
        type: slide.bucketType,
        noSpliceUrl: slide.noSpliceUrl,
      }),
    [slide.src, slide.bucketType, slide.noSpliceUrl]
  )

  const { validatedUrl, isLoading } = useImageURLValidator(assetURL)

  const validatedImageURL = React.useMemo(() => {
    if (isLoading || !validatedUrl) {
      return ''
    }

    return validatedUrl
  }, [validatedUrl, isLoading])

  React.useEffect(() => {
    if (getSlideLoadingState && !isLoading) {
      getSlideLoadingState(index)
    }
  }, [isLoading])

  if (isLoading) {
    return <DefaultSlideSkeleton />
  }

  return (
    <>
      {showBlurredBackground && (
        <div
          className="absolute inset-0 scale-[4] bg-cover bg-center bg-no-repeat blur-3xl"
          style={{
            backgroundImage: `url(${validatedImageURL || fallbackImageURL})`,
          }}
        ></div>
      )}
      <div className="absolute inset-0 z-1 flex justify-center">
        <img
          src={validatedImageURL || fallbackImageURL}
          alt={slide.label || 'No Image'}
          className={imageClass}
        />
      </div>
      {slide.fileType === FileType.Video && (
        <div className="absolute inset-0 z-1 bg-black opacity-25"></div>
      )}
    </>
  )
}

export default Slide
