import React from 'react'

import { PlayerState } from '@store/types'

import styleUtil from '@utilities/style-util'

import {
  LoopOneSvg,
  LoopSvg,
  PauseSvg,
  PlayCircleSvg,
  PlayerCloseCircleSvg,
} from '@svg/react'

import ScrubBar from './scrub-bar'
import VolumeBar from './volume-bar'

interface ComponentProps {
  isPlaying: boolean
  isLooping: boolean
  volume: number
  videoLengthInSeconds: number
  playedSeconds: number
  onPlayerStateUpdate: (arg: PlayerState) => void
  onVolumeChange: (arg: number) => void
  onSeek: (arg: number) => void
  onToggleLoop: (arg: boolean) => void
  showLoopOption?: boolean
  isAppIdle?: boolean
}

function PlayerControls({
  isPlaying,
  isLooping,
  volume,
  videoLengthInSeconds,
  playedSeconds,
  onPlayerStateUpdate,
  onVolumeChange,
  onSeek,
  onToggleLoop,
  showLoopOption = true,
  isAppIdle = false,
}: ComponentProps) {
  const themeData = styleUtil()

  const brandColour = React.useMemo(() => {
    const themeObject = JSON.parse(localStorage.getItem('themeObject') || '{}')

    return themeObject.mainColour || ''
  }, [])

  const toTimeString = (milliseconds: number): string =>
    new Date(milliseconds).toISOString().slice(14, 19)

  return (
    <>
      <div
        className={`absolute top-32 flex w-full justify-center transition-all ease-in-out ${
          isPlaying && isAppIdle ? 'opacity-0' : 'opacity-100'
        }`}
      >
        <button
          type="button"
          onClick={() => onPlayerStateUpdate(PlayerState.Stop)}
        >
          <PlayerCloseCircleSvg
            size="m"
            className="cursor-pointer text-gray-500"
          />
        </button>
      </div>

      {videoLengthInSeconds > 0 && (
        <div
          className={`absolute bottom-0 h-20 w-full bg-gradient-to-t from-gray-800 text-white transition-all ease-in-out ${
            isPlaying && isAppIdle ? 'opacity-0' : 'opacity-100'
          }`}
        >
          <ScrubBar
            videoLengthInSeconds={videoLengthInSeconds}
            playedSeconds={playedSeconds}
            seekTo={onSeek}
            brandColour={brandColour || themeData.mainColour || ''}
          />
          <div className="flex w-full items-center justify-between px-6">
            <div className="flex items-center justify-between space-x-6">
              <button
                type="button"
                onClick={() =>
                  onPlayerStateUpdate(
                    isPlaying ? PlayerState.Pause : PlayerState.Play
                  )
                }
              >
                <div className="flex h-10 w-10 items-center justify-center rounded-full bg-gray-500">
                  {isPlaying ? (
                    <PauseSvg size="m" className="text-gray-300" />
                  ) : (
                    <PlayCircleSvg size="m" className="text-gray-500" />
                  )}
                </div>
              </button>

              {showLoopOption && (
                <button type="button" onClick={() => onToggleLoop(!isLooping)}>
                  <div className="flex h-10 w-10 items-center justify-center rounded-full bg-gray-500">
                    {isLooping ? (
                      <LoopOneSvg className="h-5 w-5" fill="#FFFFFF" />
                    ) : (
                      <LoopSvg className="h-5 w-5" fill="#FFFFFF" />
                    )}
                  </div>
                </button>
              )}
              <div className="w-28">
                <VolumeBar
                  volume={volume}
                  setVolume={onVolumeChange}
                  brandColour={brandColour || themeData.mainColour || ''}
                />
              </div>
            </div>

            <p className="text-sm text-white">
              {`${toTimeString(playedSeconds * 1000)} / ${toTimeString(
                videoLengthInSeconds * 1000
              )}`}
            </p>
          </div>
        </div>
      )}
    </>
  )
}

export default PlayerControls
