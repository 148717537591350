export const ANY = 'Any'

export enum Status {
  Available = 'available',
}

export enum FileType {
  Image = 'image',
  Video = 'video',
}

export enum ProjectType {
  HouseAndLand = 'house-lands',
  Apartment = 'apartments',
}

export enum PlayerState {
  Play = 'play',
  Pause = 'pause',
  Stop = 'stop',
}

export enum ShortlistPropertyType {
  Lot = 'lot',
  Unit = 'unit',
}

export enum BucketSource {
  Legacy = 'legacy',
  New = 'new',
}

export interface IdleTimeControl {
  active: boolean
  duration: number
}
